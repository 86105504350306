import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { clearErrorAvaliablePatients, fetchCaretakerAvaliablePatients } from "../features/patientsToAssign/patientsToAssignSlice";
import { AssignPersons } from "../components/AssignPersons";
import { Person } from "../model/person";
import { Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { assingPatientsToCaretaker, cancelAddPersonsToCaretaker } from "../features/caretaker/caretakerSlice";
import { Problem } from "../model/problem";

export const AssignPatientsToCaretaker = (
  props: {
    caretakerId: string;
  }
) => {

  const dispatch = useAppDispatch();
  const patients = useAppSelector((state) => state.patientsToAssign.patientsAvaliable);
  const status = useAppSelector((state) => state.patientsToAssign.status);
  const error = useAppSelector((state) => state.patientsToAssign.error);
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    //if (status == "idle") {
      dispatch(fetchCaretakerAvaliablePatients(props.caretakerId));
    //}
  },[]);

  const handleErrorOk = () => {
    setIsModalVisible(false);
    dispatch(clearErrorAvaliablePatients());
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleAuthOk = () => {
    setIsModalVisible(false)
    navigate("/")
  }


  const onPatientsAssign = (persons: Person[]) => {
    dispatch(assingPatientsToCaretaker({ caretakerId: props.caretakerId, patientIds: persons.map((person) => person.id) }));
  }

  const onCancel = () => {
    dispatch(cancelAddPersonsToCaretaker());
  }


  if (status === "idle" || status === "loading") {
    return <Spin />;
  }

  if (status === "auth-error") {
    return <Modal title="Auth error" visible={isModalVisible} onOk={handleAuthOk} onCancel={handleCancel}>
      <p>"Authentication failed, please login"</p>
    </Modal>
  }

  if (status === "error") {
    const apiError = error as Problem;
    const title = apiError.title ?? "Server Error";
    const detail = apiError.detail ?? error?.toString;
    return <Modal title={title} visible={isModalVisible} onOk={handleErrorOk} onCancel={handleCancel}>
      <p>{detail}</p>
    </Modal>
  }

  return <AssignPersons

    onPersonsAssign={onPatientsAssign}
    onCancel={onCancel}
    
    persons={patients.map(p => {
      return {
        id: p.id,
        firstName: p.firstName,
        lastName: p.lastName,
        phoneNumber: p.userAccount?.mobileNumber,
        email: p.userAccount?.emailAddress
      } as Person;
    })} />;
}

