import {Button, Col, Form, Input, Modal, Row, Select, Space, Spin} from "antd";
import {ColumnsType} from "antd/lib/table";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";
import {EditPerson} from "../components/EditPerson";
import {SelectPersons} from "../components/SelectPersons";
import {SendPhone} from "../components/SendPhone";
import {fetchDoctorAvaliableCaretakers} from "../features/caretakersToAssign/caretakersToAssignSlice";
import {
    addCaretakersToDoctor,
    addPatientsToDoctor,
    clearSaveDoctorError,
    createOrUpdateDoctor, deleteDoctor,
    fetchDoctor,
    fetchDoctorCaretakers,
    fetchDoctorPatients,
    initDoctorState,
    removeCaretakersFromDoctor,
    removePatientsFromDoctor, updateDoctorFields,
    updateDoctorPhoneNumber
} from "../features/doctor/doctorSlice";
import {detachDoctor, fetchDoctors} from "../features/doctor/doctorsSlice";
import {fetchDoctorAvaliablePatients} from "../features/patientsToAssign/patientsToAssignSlice";
import {Doctor} from "../model/doctor";
import {Person} from "../model/person";
import {Problem} from "../model/problem";
import {RouteNames} from "../routes";
import {AssignCaretakersToDoctor} from "./AssignCaretakersToDoctor";
import {AssignPatientsToDoctor} from "./AssignPatientsToDoctor";
import {DoctorRequestDto} from "../api/client-axios";
import {CheckOutlined, CloseOutlined, DeleteOutlined, ScissorOutlined} from "@ant-design/icons";

const {Option} = Select;

export const EditDoctor = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const doctorsStatus = useAppSelector((state: RootState) => state.doctors.status);
    const doctor = useAppSelector((state: RootState) => state.doctor.doctor);
    const status = useAppSelector((state: RootState) => state.doctor.status);
    const error = useAppSelector((state: RootState) => state.doctor.error);
    const doctorPatients = useAppSelector((state: RootState) => state.doctor.patients);
    const doctorCaretakers = useAppSelector((state: RootState) => state.doctor.caretakers);
    const countries = useAppSelector((state: RootState) => state.country.countries);

    const doctorId = params.doctorId;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isRemoveDoctorWarningVisible, setIsRemoveDoctorWarningVisible] = useState(false);
    const [isDeleteDoctorWarningVisible, setIsDeleteDoctorWarningVisible] = useState(false);

    const [tempDoctor, setTempDoctor] = useState<Doctor>();
    const [activeTab, setActiveTab] = useState("0")

    useEffect(() => {

        if (doctorsStatus === "idle") {
            dispatch(fetchDoctors())
        }
        dispatch(fetchDoctor(doctorId!))
        if (doctorId !== "new") {
            dispatch(fetchDoctorPatients(doctorId!));
            dispatch(fetchDoctorCaretakers(doctorId!));
        }


    }, []);

    useEffect(() => {
        if (status === "saved") {
            dispatch(fetchDoctors());
            dispatch(initDoctorState());
            navigate(`/${RouteNames.doctors}`);
        }
        if (status === "auth-error" || status === "error") {
            setIsModalVisible(true);
        }
    }, [status])


    const onCancel = () => {
        navigate(`/${RouteNames.doctors}`);
    }

    const onSave = () => {
        if (doctor == undefined) {
            return;
        }
        setTempDoctor(doctor as Doctor);
        dispatch(createOrUpdateDoctor(doctor as DoctorRequestDto));
    }


    const onDelete = () => {
        setIsDeleteDoctorWarningVisible(true);
    }

    const onRemove = () => {
        setIsRemoveDoctorWarningVisible(true);
    }

    const onDeleteDoctor = () => {
        if (doctor?.id != null) {
            dispatch(deleteDoctor(doctor.id));
        }
    }

    const onRemoveDoctor = () => {
        if (doctor?.id != null) {
            dispatch(detachDoctor(doctor.id));
        }
    }

    const onConfirm = (phoneNumber: string) => {
        dispatch(updateDoctorPhoneNumber(phoneNumber));
    }

    const handleAuthOk = () => {
        setIsModalVisible(false)
        navigate("/")
    }

    const handleErrorOk = () => {
        setIsModalVisible(false);
        dispatch(clearSaveDoctorError(tempDoctor!));
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const reloadDoctorPatients = () => {
        dispatch(fetchDoctorPatients(doctorId!));
    }

    const reloadDoctorCaretakers = () => {
        dispatch(fetchDoctorCaretakers(doctorId!));
    }

    const onPatientsRemove = (selectedPersons: Person[] | undefined) => {
        if (selectedPersons === undefined || selectedPersons.length === 0) return;
        dispatch(removePatientsFromDoctor({doctorId: doctorId!, patients: selectedPersons.map(p => p.id)}));
    }

    const onCaretakersRemove = (selectedPersons: Person[] | undefined) => {
        if (selectedPersons === undefined || selectedPersons.length === 0) return;
        dispatch(removeCaretakersFromDoctor({doctorId: doctorId!, caretakersIds: selectedPersons.map(p => p.id)}));
    }

    const onPatientsAssign = () => {
        dispatch(fetchDoctorAvaliablePatients(doctorId!));
        dispatch(addPatientsToDoctor());
    }

    const onCaretakersAssign = () => {
        dispatch(fetchDoctorAvaliableCaretakers(doctorId!));
        dispatch(addCaretakersToDoctor());
    }

    const changeDoctor = (doctor: DoctorRequestDto) => {
        dispatch(updateDoctorFields(doctor));
    }

    const columns: ColumnsType<Person> = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => a.firstName > b.firstName ? -1 : 1,
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.lastName > b.lastName ? -1 : 1
        },
        {
            width: '12.5%',
            title: 'Phone',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            sorter: (a, b) => a.phoneNumber > b.phoneNumber ? -1 : 1
        },
        {
            width: '12.5%',
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email > b.email ? -1 : 1
        },
    ];


    if (status === "idle" || status === "loading" || doctorsStatus === "idle") {
        return <Spin/>;
    }

    if (status === "auth-error") {
        return <Modal title="Auth error" visible={isModalVisible} onOk={handleAuthOk} onCancel={handleCancel}>
            <p>"Authentication failed, please login"</p>
        </Modal>
    }

    if (status === "error") {
        const apiError = error as Problem;
        const title = apiError.title ?? "Server Error";
        const detail = apiError.detail ?? error?.toString;
        return <Modal title={title} visible={isModalVisible} onOk={handleErrorOk} onCancel={handleCancel}>
            <p>{detail}</p>
        </Modal>
    }

    if (status === "saved") {
        return (<div></div>);
    }


    if (status == "addingPatients") {
        return <AssignPatientsToDoctor doctorId={doctorId!}/>
    }

    if (status == "addingCaretakers") {
        return <AssignCaretakersToDoctor doctorId={doctorId!}/>
    }

    // const editDoctor = <EditPerson title="Doctor" person={{
    //     id: doctor?.id,
    //     firstName: doctor?.firstName,
    //     lastName: doctor?.lastName,
    //     phoneNumber: doctor?.userAccount?.mobileNumber,
    //     email: doctor?.userAccount?.emailAddress
    // } as Person} onCancel={onCancel} onSave={onSaveDoctor} onDelete={onDeleteDoctor}>
    //
    // </EditPerson>


    const editDoctor = <Col span={24}>
        <Form className='person-form'
              name="basic"
              labelCol={{span: 6}}
              wrapperCol={{span: 24}}
              initialValues={{remember: true}}
            //onFinish={onFinish}
            //onFinishFailed={onFinishFailed}
              autoComplete="off"
        >

            <Row>
                {/* <Col className='person-title' offset={6} span={11}>Doctor</Col> */}
                <Col offset={6} span={18}>
                    <Form.Item>
                        <Row justify="end">
                            <Space className="mr-xxs">
                                <Button icon={<ScissorOutlined/>} onClick={onRemove} type='primary'
                                        style={{background: 'orange', borderColor: 'orange'}}
                                        disabled={doctor?.id === undefined}>Remove</Button>
                                <Button icon={<DeleteOutlined/>} onClick={onDelete} type='primary' danger
                                        disabled={doctor?.id === undefined}>Delete</Button>
                            </Space>

                            <Space>
                                <Button icon={<CloseOutlined/>} onClick={onCancel}>Cancel</Button>
                                <Button icon={<CheckOutlined/>} type='primary' onClick={onSave}>Save</Button>
                            </Space>
                        </Row>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Email:">
                        <Input placeholder='john.doe@email.com'
                               disabled={doctorId != undefined && doctorId != "new"}
                               defaultValue={doctor?.userAccount?.emailAddress || ""}
                               onChange={(e) => {
                                   changeDoctor({
                                       ...doctor,
                                       userAccount: {...doctor?.userAccount, emailAddress: e.target.value}
                                   } as DoctorRequestDto)
                               }}
                        />
                    </Form.Item>
                </Col>
                {doctorId === undefined || doctorId == "new"
                    ?
                    <Col span={24}>
                        <Form.Item label="Password:">
                            <Input.Password placeholder='password' onChange={(e) => {
                                changeDoctor({
                                    ...doctor,
                                    password: e.target.value
                                } as DoctorRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    : <span/>}
                <Col span={24}>
                    <Form.Item label="Phone number:" rules={[{required: true}]}>
                        <Input placeholder='1 (344) 333-3333' defaultValue={doctor?.userAccount?.mobileNumber || ""}
                               onChange={(e) => {
                                   changeDoctor({
                                       ...doctor,
                                       userAccount: {...doctor?.userAccount, mobileNumber: e.target.value}
                                   } as DoctorRequestDto)
                               }}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="First name:" rules={[{required: true, message: 'please enter First Name'}]}>
                        <Input placeholder='John' defaultValue={doctor?.firstName || ""} onChange={(e) => {
                            changeDoctor({...doctor, firstName: e.target.value} as DoctorRequestDto)
                        }}/>
                    </Form.Item>

                </Col>
                <Col span={24}>
                    <Form.Item label="Last name:">
                        <Input placeholder='Doe' defaultValue={doctor?.lastName || ""} onChange={(e) => {
                            changeDoctor({...doctor, lastName: e.target.value} as DoctorRequestDto)
                        }}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Country:" initialValue={doctor?.address?.country}>
                        <Select
                            onChange={(e) => changeDoctor({
                                ...doctor,
                                address: {...doctor?.address, alpha3Code: e}
                            } as DoctorRequestDto)}
                            defaultValue={doctor?.address?.alpha3Code}
                            showSearch
                            style={{width: 200}}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return (option!.children![2] as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }}
                            filterSort={(optionA, optionB) =>
                                (optionA!.children![2] as unknown as string)
                                    .toLowerCase()
                                    .localeCompare((optionB!.children![2] as unknown as string).toLowerCase())
                            }
                        >
                            {countries.map(country => <Option key={country.alpha3Code}
                                                              value={country.alpha3Code}><img src={country.flag!}
                                                                                              alt={country.alpha3Code!}
                                                                                              width="20"></img> {country.name}
                            </Option>)}

                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Address:">
                        <Input defaultValue={doctor?.address?.streetAddress1 as string | undefined}
                               placeholder='Road st 27' onChange={(e) => {
                            changeDoctor({
                                ...doctor,
                                address: {...doctor?.address, streetAddress1: e.target.value}
                            } as DoctorRequestDto)
                        }}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Address:">
                        <Input defaultValue={doctor?.address?.streetAddress2 as string | undefined}
                               placeholder='Left side' onChange={(e) => {
                            changeDoctor({
                                ...doctor,
                                address: {...doctor?.address, streetAddress2: e.target.value}
                            } as DoctorRequestDto)
                        }}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="City:">
                        <Input defaultValue={doctor?.address?.city as string | undefined} placeholder='New York'
                               onChange={(e) => {
                                   changeDoctor({
                                       ...doctor,
                                       address: {...doctor?.address, city: e.target.value}
                                   } as DoctorRequestDto)
                               }}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="State:">
                        <Input defaultValue={doctor?.address?.state as string | undefined} placeholder='NY'
                               onChange={(e) => {
                                   changeDoctor({
                                       ...doctor,
                                       address: {...doctor?.address, state: e.target.value}
                                   } as DoctorRequestDto)
                               }}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Postcode:">
                        <Input defaultValue={doctor?.address?.postCode as string | undefined} placeholder='NY'
                               onChange={(e) => {
                                   changeDoctor({
                                       ...doctor,
                                       address: {...doctor?.address, postCode: e.target.value}
                                   } as DoctorRequestDto)
                               }}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Col>


    const selectPersons = <SelectPersons columns={columns} showCaretakers={true}
                                         caretakers={doctorCaretakers?.map((e) => {
                                             return {
                                                 id: e.id,
                                                 firstName: e.firstName,
                                                 lastName: e.lastName,
                                                 phoneNumber: e.userAccount?.mobileNumber,
                                                 email: e.userAccount?.emailAddress
                                             } as Person;
                                         }) ?? []}

                                         onCaretakerReload={reloadDoctorCaretakers}

                                         onCaretakersRemove={onCaretakersRemove}

                                         onCaretakersAssign={onCaretakersAssign}


                                         patients={doctorPatients?.map((e) => {
                                             return {
                                                 id: e.id,
                                                 firstName: e.firstName,
                                                 lastName: e.lastName,
                                                 phoneNumber: e.userAccount?.mobileNumber,
                                                 email: e.userAccount?.emailAddress
                                             } as Person;
                                         }) ?? []}

                                         onPatientsReload={reloadDoctorPatients}

                                         onPatientsRemove={onPatientsRemove}

                                         onPatientsAssign={onPatientsAssign}

                                         personTitle="Doctor"
                                         person={editDoctor}

                                         activeTab={activeTab}
                                         onTabChange={(tab)=> setActiveTab(tab)}

    />

    return (
        <div style={{height: '100%'}}>
            {selectPersons}
            <Modal visible={isRemoveDoctorWarningVisible} okText="Yes" cancelText="No"
                   onCancel={() => setIsRemoveDoctorWarningVisible(false)} onOk={onRemoveDoctor}>Are you sure to remove
                doctor from clinic "{doctor?.firstName + " " + doctor?.lastName}"</Modal>
            <Modal visible={isDeleteDoctorWarningVisible} okText="Yes" cancelText="No"
                   onCancel={() => setIsDeleteDoctorWarningVisible(false)} onOk={onDeleteDoctor}>Are you sure to
                complitely
                delete doctor "{doctor?.firstName + " " + doctor?.lastName}"</Modal>
        </div>
    );
}