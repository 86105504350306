import {createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {
    CaretakerResponseDto, DoctorRequestDto,
    DoctorResponseDto, OrganizationCaretakersApi,
    OrganizationDoctorsApi,
    PatientResponseDto
} from "../../api/client-axios";
import {Configuration} from "../../api/client-axios";
import {ApiError} from "../../model/apiError";
import {Doctor} from "../../model/doctor";
import {Problem} from "../../model/problem";
import {initOtpState} from "../otp/otpSlice";
import {apiConfiguration} from "../server";

export interface DoctorState {
    doctor?: DoctorResponseDto,
    patients?: PatientResponseDto[],
    caretakers?: CaretakerResponseDto[],
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "saved" | "addingPatients" | "addingCaretakers",
    error?: string | Problem,
    code?: number;
}

const initialDoctorState: DoctorState = {
    status: "idle",
    error: ""
}


export const fetchDoctor = createAsyncThunk<DoctorResponseDto, string, {
    rejectValue: ApiError
}>('doctor/fetchDoctor', async (doctorId, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        if (doctorId === "new") {
            thunkApi.dispatch(initOtpState());
            return {} as DoctorResponseDto;
        }
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdGet(doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const deleteDoctor = createAsyncThunk<void, string, {
    rejectValue: ApiError
}>('doctor/deleteDoctor', async (doctorId, thunkApi) => {
    try {
        const api = new OrganizationCaretakersApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDelete(doctorId)
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const fetchDoctorCaretakers = createAsyncThunk<CaretakerResponseDto[], string, {
    rejectValue: ApiError
}>('doctor/fetchDoctorCaretakers', async (doctorId, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet(doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});

export const fetchDoctorPatients = createAsyncThunk<PatientResponseDto[], string, {
    rejectValue: ApiError
}>('doctor/fetchDoctorPatients', async (doctorId, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet(doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const createOrUpdateDoctor = createAsyncThunk<DoctorResponseDto, DoctorRequestDto, {
    rejectValue: ApiError
}>('doctor/createOrUpdateDoctor', async (doctor, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsPost(doctor)
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const assingCaretakerToDoctor = createAsyncThunk<CaretakerResponseDto[], {
    doctorId: string,
    caretakerId: string
}, { rejectValue: ApiError }>('doctor/assignCaretaker', async (args, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdAssignPost(args.doctorId, args.caretakerId);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet(args.doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});


export const assignCaretakersToDoctor = createAsyncThunk<CaretakerResponseDto[], {
    doctorId: string,
    caretakerIds: string[]
}, { rejectValue: ApiError }>('doctor/assignCaretakers', async (args, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAssignPost(args.doctorId, args.caretakerIds);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet(args.doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});

export const removeCaretakerFromDoctor = createAsyncThunk<CaretakerResponseDto[], {
    doctorId: string,
    caretakerId: string
}, { rejectValue: ApiError }>('doctor/removeCaretaker', async (args, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdRemovePost(args.doctorId, args.caretakerId);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet(args.doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});

export const removeCaretakersFromDoctor = createAsyncThunk<CaretakerResponseDto[], {
    doctorId: string,
    caretakersIds: string[]
}, { rejectValue: ApiError }>('doctor/removeCaretakers', async (args, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersRemovePost(args.doctorId, args.caretakersIds);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet(args.doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});


export const assingPatientToDoctor = createAsyncThunk<PatientResponseDto[], { doctorId: string, patientId: string }, {
    rejectValue: ApiError
}>('doctor/assignPatient', async (args, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdAssignPost(args.doctorId, args.patientId);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet(args.doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});


export const assingPatientsToDoctor = createAsyncThunk<PatientResponseDto[], {
    doctorId: string,
    patientIds: string[]
}, { rejectValue: ApiError }>('doctor/assignPatients', async (args, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAssignPost(args.doctorId, args.patientIds);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet(args.doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});

export const removePatientFromDoctor = createAsyncThunk<PatientResponseDto[], { doctorId: string, patientId: string }, {
    rejectValue: ApiError
}>('doctor/removePatient', async (args, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdRemovePost(args.doctorId, args.patientId);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet(args.doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});


export const removePatientsFromDoctor = createAsyncThunk<PatientResponseDto[], {
    doctorId: string,
    patients: string[]
}, { rejectValue: ApiError }>('doctor/removePatients', async (args, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsRemovePost(args.doctorId, args.patients);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet(args.doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});

export const updateDoctorFields = createAction("doctor/updateDoctorFields", (doctor: DoctorRequestDto) => {
    return {payload: doctor}
});

export const initDoctorState = createAction('doctor/init');

export const clearSaveDoctorError = createAction('doctor/clearError', (doctor: Doctor) => {
    return {payload: doctor};
});

export const updateDoctorPhoneNumber = createAction('doctor/updatePhoneNumber', (phoneNumber: string) => {
    return {payload: phoneNumber};
});

export const addPatientsToDoctor = createAction('doctor/addPatientsToDoctor');

export const addCaretakersToDoctor = createAction('doctor/addCaretakersToDoctor');

export const cancelAddPersonsToDoctor = createAction('doctor/AddPersonToDoctor');

const doctorSlice = createSlice({
    name: 'doctor',
    initialState: initialDoctorState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchDoctor.fulfilled, (state, action) => {
                state.doctor = action.payload;
                state.status = "success";
            })
            .addCase(fetchDoctor.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(fetchDoctorCaretakers.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchDoctorCaretakers.fulfilled, (state, action) => {
                state.caretakers = action.payload;
                state.status = "success";
            })
            .addCase(fetchDoctorCaretakers.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(fetchDoctorPatients.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchDoctorPatients.fulfilled, (state, action) => {
                state.patients = action.payload;
                state.status = "success";
            })
            .addCase(fetchDoctorPatients.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(createOrUpdateDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(createOrUpdateDoctor.fulfilled, (state, action) => {
                state.doctor = action.payload;
                state.status = "saved";
            })
            .addCase(createOrUpdateDoctor.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(assingCaretakerToDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(assingCaretakerToDoctor.fulfilled, (state, action) => {
                state.caretakers = action.payload;
                state.status = "success";
            })
            .addCase(assingCaretakerToDoctor.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(removeCaretakerFromDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(removeCaretakerFromDoctor.fulfilled, (state, action) => {
                state.caretakers = action.payload;
                state.status = "success";
            })
            .addCase(removeCaretakerFromDoctor.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(assingPatientToDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(assingPatientToDoctor.fulfilled, (state, action) => {
                state.patients = action.payload;
                state.status = "success";
            })
            .addCase(assingPatientToDoctor.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(assingPatientsToDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(assingPatientsToDoctor.fulfilled, (state, action) => {
                state.patients = action.payload;
                state.status = "success";
            })
            .addCase(assingPatientsToDoctor.rejected, (state, action) => {
                processError(action, state);
            })

            .addCase(removePatientFromDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(removePatientFromDoctor.fulfilled, (state, action) => {
                state.patients = action.payload;
                state.status = "success";
            })
            .addCase(removePatientsFromDoctor.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(removePatientsFromDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(removePatientsFromDoctor.fulfilled, (state, action) => {
                state.patients = action.payload;
                state.status = "success";
            })
            .addCase(removePatientFromDoctor.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(initDoctorState, (state, _) => {
                state.status = "idle";
                state.doctor = {};
                state.code = undefined;
                state.error = undefined;
            })
            .addCase(clearSaveDoctorError, (state, action) => {
                state.status = "success";
                state.doctor = {
                    ...state.doctor,
                    firstName: action.payload?.firstName ?? "",
                    lastName: action.payload?.lastName ?? "", userAccount: {
                        mobileNumber: action.payload?.phoneNumber ?? "",
                        emailAddress: action.payload?.email ?? ""
                    }
                }
            })
            .addCase(assignCaretakersToDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(assignCaretakersToDoctor.fulfilled, (state, action) => {
                state.caretakers = action.payload;
                state.status = "success";
            })
            .addCase(assignCaretakersToDoctor.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updateDoctorPhoneNumber, (state, action) => {
                state.doctor!.userAccount = {
                    mobileNumber: action.payload,
                    emailAddress: state.doctor?.userAccount?.emailAddress
                };
            })
            .addCase(addPatientsToDoctor, (state, _) => {
                state.status = "addingPatients";
            })
            .addCase(addCaretakersToDoctor, (state, _) => {
                state.status = "addingCaretakers";
            })

            .addCase(removeCaretakersFromDoctor.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(removeCaretakersFromDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(removeCaretakersFromDoctor.fulfilled, (state, action) => {
                state.caretakers = action.payload;
                state.status = "success";
            })
            .addCase(cancelAddPersonsToDoctor, (state) => {
                state.status = "success";
            })
            .addCase(deleteDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(deleteDoctor.fulfilled, (state, action) => {
                state.status = "saved";
            })
            .addCase(deleteDoctor.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updateDoctorFields,(state,action)=>{
                state.doctor ={
                    ...state.doctor,
                    ...action.payload as DoctorResponseDto
                };
            })
        ;
    }
});


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: any; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: DoctorState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;

            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
                if (state.code == 0) {
                    state.code = 500;
                    state.error = "Internal Server Error";
                }
            }

    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}


export default doctorSlice.reducer;
