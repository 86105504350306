import { SearchOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Space, Table, Tabs } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { fakeCaretakers, fakePatients } from "../mock/fake-entities";
import { Person } from "../model/person";

export const SelectPersons = (props: {
    columns: ColumnsType<Person>,
    //onSelectionChange: (selectedRowKeys: React.Key[], persons: Person[]) => void, 
    showCaretakers: boolean,
    caretakers?: Person[], 
    onCaretakerSearch?: (value: string) => void,
    onCaretakersAssign?: () => void,
    onCaretakersRemove?: (selectedPersons?: Person[]) => void,
    onCaretakerReload?: () => void,
    patients?: Person[],
    onPatientsSearch?: (value: string) => void,
    onPatientsAssign?: () => void,
    onPatientsRemove?: (selectedPersons?: Person[]) => void,
    onPatientsReload?: () => void,
    person?: JSX.Element,
    personTitle?: string,
    activeTab?: string,
    onTabChange?: (tab:string)=> void
}) => {

    const [ caretakerSearchTerm, setCaretakerSearchTerm ] = useState<string>("");
    const [ selectedCaretakers, setSelectedCaretakers ] = useState<Person[]>([]);
    const [ patientSearchTerm, setPatientSearchTerm ] = useState<string>("");
    const [ selectedPatients, setSelectedPatients ] = useState<Person[]>([]);
   // const [activeTab, setActiveTab] = useState<string>("0");

    const onCaretakerSearch = () => {
        if(props.onCaretakerSearch != undefined && caretakerSearchTerm != "") {
            props.onCaretakerSearch(caretakerSearchTerm);
        }
    }




    const onCaretakersRemove = () => {
         if(props.onCaretakersRemove != undefined && selectedCaretakers.length > 0) {
            props.onCaretakersRemove(selectedCaretakers);
        }
    }

    const onSelectionCaretakersChange = (selectedRowKeys: React.Key[], persons: Person[]) =>{
        setSelectedCaretakers(persons);
    }

    const onPatientSearch = () => {
        if(props.onPatientsSearch != undefined && patientSearchTerm != "") {
            props.onPatientsSearch(patientSearchTerm);
        }
    }


    const onPatientsRemove = () => {
         if(props.onPatientsRemove != undefined && selectedPatients.length > 0) {
            props.onPatientsRemove(selectedPatients);
        }
    }

    const onSelectionPatientsChange = (selectedRowKeys: React.Key[], persons: Person[]) =>{
        setSelectedPatients(persons);
    }

    

    const tabs = [];
    let tab
    if(props.person){
        tabs.push(
            <Tabs.TabPane tab={props.personTitle} key="3">
                {props.person}
            </Tabs.TabPane>
        )
    }

    tabs.push(
        <Tabs.TabPane tab="Patients" key="2" >
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>

                <Space>
                    <Input defaultValue={patientSearchTerm} onChange={(e) => { setPatientSearchTerm(e.target.value); }}    onPressEnter={onPatientSearch}></Input>
                    <Button type="primary" icon={<SearchOutlined />} onClick={onPatientSearch}>Search</Button>
                </Space>

                <Space >
                    <Button type='primary' danger icon={<DeleteOutlined />}  onClick={onPatientsRemove} disabled={selectedPatients === undefined || selectedPatients.length ==0}>Remove</Button>
                    <Button type='primary' icon={<PlusOutlined />} onClick={props.onPatientsAssign}>Add</Button>
                    <Button type='text' shape="circle" icon={<ReloadOutlined />} onClick={props.onPatientsReload} />
                </Space>

            </Row>
            <Col>
                <Table
                    rowKey={(person: Person) => person.id}
                    rowSelection={{ type: 'checkbox', onChange: onSelectionPatientsChange }}
                    style={{ marginTop: '20px' }}
                    columns={props.columns}
                    dataSource={props.patients}></Table>
            </Col>
        </Tabs.TabPane>
    );

    if (props.showCaretakers) {
        tabs.push(
            <Tabs.TabPane tab="Caretakers" key="1">
                <Row style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <Space>
                        <Input defaultValue={caretakerSearchTerm} onChange={(e) => { setCaretakerSearchTerm(e.target.value); }}    onPressEnter={onCaretakerSearch}></Input>
                        <Button type="primary" icon={<SearchOutlined />} onClick={onCaretakerSearch}>Search</Button>
                    </Space>

                    <Space >
                        <Button type='primary' danger icon={<DeleteOutlined />}  onClick={onCaretakersRemove} disabled={selectedCaretakers === undefined || selectedCaretakers.length ==0} >Remove</Button>
                        <Button type='primary' icon={<PlusOutlined />} onClick={props.onCaretakersAssign} >Add</Button>
                        <Button type='text' shape="circle" icon={<ReloadOutlined />}  onClick={props.onCaretakerReload}/>
                    </Space>

                </Row>
                <Col>
                    <Table
                        rowKey={(person: Person) => person.id}
                        rowSelection={{ type: 'checkbox', onChange: onSelectionCaretakersChange }}
                        style={{ marginTop: '20px' }}
                        columns={props.columns}
                        dataSource={props.caretakers}></Table>
                </Col>
            </Tabs.TabPane>
        );
    }

    

    return (
        <Row>
            <Col offset={1} span={23}>
                <Tabs defaultActiveKey={props.activeTab} type="line" onChange={props.onTabChange}>
                    {tabs}
                </Tabs>
            </Col>
        </Row>

    );
}