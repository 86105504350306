import './App.css';
import { UserOutlined } from '@ant-design/icons';
import { SideMenu } from './components/SideMenu';
import { CustomIcon } from './components/CustomIcon';
import { Layout, Spin } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import { RouteNames } from './routes';
import { useEffect } from 'react';
import { useAppDispatch } from './app/hooks';
import { fetchCountries } from './features/country/countrySlice';
import { } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import {useAuth, hasAuthParams} from "react-oidc-context";

function App() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAuth();

  useEffect(() => {
    if (!hasAuthParams() &&
        !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();}
  },[auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);


  useEffect(() => {
    if (auth.isAuthenticated) {
      console.log(auth.user);
      const token = auth.user?.access_token;
      console.log(token);
      localStorage.setItem('token', token ?? '');
      console.log(token);
    }
  }, [auth]);

  useEffect(() =>  {
    return auth.events.addAccessTokenExpiring(async () => {
      console.log('renew token');
      await auth.signinSilent();
      const token = auth.user?.access_token;
      console.log(token ?? '');
      localStorage.setItem('token', token ?? '');

    })
  }, [auth.events,auth.signinSilent]);


  useEffect(() => {
    dispatch(fetchCountries());
  }, [])

  const menuItems: ItemType[] = [
    {
      key: RouteNames.doctors,
      itemIcon: <CustomIcon icon='/images/doctor.svg' />,
      label: 'Doctors'
    },
    {
      key: RouteNames.caretakers,
      itemIcon: <CustomIcon icon='/images/caretaker.svg' />,
      label: 'Caretakers'
    },
    {
      key: RouteNames.patients,
      itemIcon: <CustomIcon icon='/images/patient.svg' />,
      label: 'Patients'
    },
    {
      key: 'account',
      itemIcon: <UserOutlined />,
      label: 'Account'
    }
  ];


  const onMenuClick = (menuInfo: {
    key: string;
    keyPath: string[];
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  }) => {

    const route = `/${menuInfo.key}`;
    console.log(`Clicked menu: ${route}`);
    navigate(route)
  }



  return (
    <div className="App">
      {auth.isAuthenticated ? <Layout>
        <div style={{ backgroundColor: 'white' }}>
          <div className='fogfree-logo' />
          <SideMenu menuItems={menuItems} onMenuClick={onMenuClick} />
        </div>

        <Layout.Content className='content'>
          <Outlet />
        </Layout.Content>
      </Layout> : <Spin className='wide-spin' />}
    </div>
  );
}

export default App;

