import {createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {
    CaretakerCreateRequestDto,
    CaretakerResponseDto,
    OrganizationCaretakersApi,
    PatientResponseDto
} from "../../api/client-axios";
import {ApiError} from "../../model/apiError";
import {Caretaker} from "../../model/caretaker";
import {Problem} from "../../model/problem";
import {initOtpState} from "../otp/otpSlice";
import {apiConfiguration} from "../server";

export interface CaretakerState {
    caretaker?: CaretakerResponseDto,
    patients?: PatientResponseDto[],
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "saved" | "addingPatients";
    error?: string | Problem;
    code?: number;
}


const initialCaretakerState: CaretakerState = {
    status: "idle",
    error: ""
}

export const fetchCaretaker = createAsyncThunk<CaretakerResponseDto, string, {
    rejectValue: ApiError
}>('caretaker/fetchCaretaker', async (caretakerId, thunkApi) => {
    try {
        const api = new OrganizationCaretakersApi(apiConfiguration());
        if (caretakerId === "new") {
            thunkApi.dispatch(initOtpState());
            return {} as CaretakerResponseDto;
        }
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdGet(caretakerId)
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});

export const deleteCaretaker = createAsyncThunk<void, string, {
    rejectValue: ApiError
}>('caretaker/deleteCaretaker', async (caretakerId, thunkApi) => {
    try {
        const api = new OrganizationCaretakersApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDelete(caretakerId)
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});

export const detachCaretaker = createAsyncThunk<CaretakerResponseDto, string, {
    rejectValue: ApiError
}>('caretaker/detachCaretaker', async (caretakerId, thunkApi) => {
    try {
        const api = new OrganizationCaretakersApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDetachPost(caretakerId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});


export const createOrUpdateCaretaker = createAsyncThunk<CaretakerResponseDto, CaretakerCreateRequestDto, {
    rejectValue: ApiError
}>('caretaker/addCaretaker', async (caretaker, thunkApi) => {
    try {
        const api = new OrganizationCaretakersApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersPost(caretaker);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});


export const fetchCaretakerPatients = createAsyncThunk<PatientResponseDto[], string, {
    rejectValue: ApiError
}>('caretaker/fetchCaretakerPatients', async (caretakerId, thunkApi) => {
    try {
        const api = new OrganizationCaretakersApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet(caretakerId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const assingPatientToCaretaker = createAsyncThunk<PatientResponseDto[], {
    caretakerId: string,
    patientId: string
}, { rejectValue: ApiError }>('caretaker/assignPatient', async (args, thunkApi) => {
    try {
        const api = new OrganizationCaretakersApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdAssignPost(args.caretakerId, args.patientId);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet(args.caretakerId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});


export const assingPatientsToCaretaker = createAsyncThunk<PatientResponseDto[], {
    caretakerId: string,
    patientIds: string[]
}, { rejectValue: ApiError }>('caretaker/assignPatients', async (args, thunkApi) => {
    try {
        const api = new OrganizationCaretakersApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAssignPost(args.caretakerId, args.patientIds);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet(args.caretakerId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});

export const removePatientFromCaretaker = createAsyncThunk<PatientResponseDto[], {
    caretakerId: string,
    patientId: string
}, { rejectValue: ApiError }>('caretaker/removePatient', async (args, thunkApi) => {
    try {
        const api = new OrganizationCaretakersApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdRemovePost(args.caretakerId, args.patientId);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet(args.caretakerId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});


export const removePatientsFromCaretaker = createAsyncThunk<PatientResponseDto[], {
    caretakerId: string,
    patients: string[]
}, { rejectValue: ApiError }>('caretaker/removePatients', async (args, thunkApi) => {
    try {
        const api = new OrganizationCaretakersApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsRemovePost(args.caretakerId, args.patients);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet(args.caretakerId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});

export const clearSaveCaretakerError = createAction('caretaker/clearError', (patient: Caretaker) => {
    return {payload: patient};
});

export const updateCaretakerPhoneNumber = createAction('caretaker/updatePhoneNumber', (phoneNumber: string) => {
    return {payload: phoneNumber};
});
export const initCaretakerState = createAction('caretaker/init');
export const addPatientsToCaretaker = createAction('caretaker/addPatientsToCaretaker');
export const cancelAddPersonsToCaretaker = createAction('caretaker/cancelAddPersonsToCaretaker');
export const updateCaretakerFields = createAction("caretaker/updateCaretakerFields", (caretaker: CaretakerCreateRequestDto) => {
    return {payload: caretaker}
});


const caretakerSlice = createSlice({
    name: 'caretaker',
    initialState: initialCaretakerState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchCaretaker.fulfilled, (state, action) => {
                state.caretaker = action.payload;
                state.status = "success";
            })
            .addCase(fetchCaretaker.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(createOrUpdateCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(createOrUpdateCaretaker.fulfilled, (state, action) => {
                state.caretaker = action.payload;
                state.status = "saved";
            })
            .addCase(createOrUpdateCaretaker.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(clearSaveCaretakerError, (state, action) => {
                state.status = "success";
                state.caretaker = {
                    ...state.caretaker,
                    firstName: action.payload?.firstName ?? "",
                    lastName: action.payload?.lastName ?? "", userAccount: {
                        mobileNumber: action.payload?.phoneNumber ?? "",
                        emailAddress: action.payload?.email ?? ""
                    }
                }
            })
            .addCase(updateCaretakerPhoneNumber, (state, action) => {
                state.caretaker!.userAccount = {
                    mobileNumber: action.payload,
                    emailAddress: state.caretaker?.userAccount?.emailAddress
                };
            })
            .addCase(initCaretakerState, (state, action) => {
                state.status = "idle";
                state.caretaker = {};
                state.code = undefined;
                state.error = undefined;
            })
            .addCase(fetchCaretakerPatients.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchCaretakerPatients.fulfilled, (state, action) => {
                state.patients = action.payload;
                state.status = "success";
            })
            .addCase(fetchCaretakerPatients.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(assingPatientToCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(assingPatientToCaretaker.fulfilled, (state, action) => {
                state.patients = action.payload;
                state.status = "success";
            })
            .addCase(assingPatientToCaretaker.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(assingPatientsToCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(assingPatientsToCaretaker.fulfilled, (state, action) => {
                state.patients = action.payload;
                state.status = "success";
            })
            .addCase(assingPatientsToCaretaker.rejected, (state, action) => {
                processError(action, state);
            })

            .addCase(removePatientFromCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(removePatientFromCaretaker.fulfilled, (state, action) => {
                state.patients = action.payload;
                state.status = "success";
            })
            .addCase(removePatientsFromCaretaker.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(removePatientsFromCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(removePatientsFromCaretaker.fulfilled, (state, action) => {
                state.patients = action.payload;
                state.status = "success";
            })
            .addCase(removePatientFromCaretaker.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(addPatientsToCaretaker, (state, _) => {
                state.status = "addingPatients";
            })
            .addCase(cancelAddPersonsToCaretaker, (state, _) => {
                state.status = "success";
            })
            .addCase(deleteCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(deleteCaretaker.fulfilled, (state, action) => {
                state.status = "saved";
            })
            .addCase(deleteCaretaker.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updateCaretakerFields, (state, action) => {
                state.caretaker = {
                    ...state.caretaker,
                    ...action.payload as CaretakerResponseDto
                };
            })
            .addCase(detachCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(detachCaretaker.fulfilled, (state, action) => {
                state.caretaker = action.payload;
                state.status = "saved";
            })
            .addCase(detachCaretaker.rejected, (state, action) => {
                processError(action, state);
            })
        ;
    }
});


export const processError = (
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: string | any; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: CaretakerState) => {
    if (action.payload) {
        state.error = action.payload.message;
        state.code = action.payload.statusCode;
        if (state.code == 401) {
            state.status = "auth-error";
        } else {
            state.status = "error";
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}

export default caretakerSlice.reducer;
