import { EditOutlined, PlusOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, Row, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { Person } from "../model/person"
import { getCountryFromPhoneNumber } from "../utils/formatPhone";
import './PersonList.css';

export const PersonList = (props: {
    persons: Person[],
    title: string,
    onEditPerson: (id?: string) => void,
    onReload?: ()=> void,
    onSearch?: (query: string)=>void
}) => {


    const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>(""); 

    const columns: ColumnsType<Person> = [
        {
            width: '25%',
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => a.firstName > b.firstName ? -1 : 1,
            //filteredValue:  null,
            //onFilter: (value: string | number| boolean, record) => record.firstName.includes(value as string),
            //ellipsis: true
        },
        {
            width: '25%',
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.lastName > b.lastName ? -1 : 1
        },
        {
            width: '12.5%',
            title: 'Phone',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            sorter: (a, b) => a.phoneNumber > b.phoneNumber ? -1 : 1
        },
        {
            width: '12.5%',
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email > b.email ? -1 : 1
        },
    ];


    const onRow = (person: Person, index: number | undefined) => {
        return {
            onClick: (event: any) => {
                setSelectedPersonId(person.id);
                console.log(index);
            }, // click row
            // onDoubleClick: event => {}, // double click row
            // onContextMenu: event => {}, // right button click row
            // onMouseEnter: event => {}, // mouse enter row
            // onMouseLeave: event => {}, // mouse leave row
        }
    }


    const getRowClassName = (person: Person, index: number) => {
        return selectedPersonId === person.id ? 'selected-row' : '';
    }

    const onEditPerson = () => {
        props.onEditPerson(selectedPersonId!);
    }

    const onAddPerson = () => {
        props.onEditPerson('new');
    }

    const onReload = () => {
        if(props.onReload !== undefined){
            props.onReload();
        }
       
    }

    const onSearch = ()=>{
        if(props.onSearch){
            props.onSearch(searchTerm);
        }
    }

  
    return (<div className="main-frame" >
        <Col style={{ width: '100%', height: '100%' }}>
            <h4 className="title">
                {props.title}
            </h4>
            <Divider />
            <Row >
                <Col span={24}>
                    <div className="container">
                        <Space>
                            <Input placeholder='John' defaultValue={searchTerm} onChange={(e) => { setSearchTerm(e.target.value); }} onPressEnter={onSearch}/>
                            <Button type="primary" icon={<SearchOutlined />}  onClick={onSearch}>Search</Button>
                        </Space>
                        <Space>
                            {selectedPersonId !== null ? <Button type='primary' icon={<EditOutlined />} onClick={onEditPerson}>Edit</Button> : <div></div>}
                            <Button type='primary' icon={<PlusOutlined />} onClick={onAddPerson}>Add</Button>
                            <Button type='text' shape="circle" icon={<ReloadOutlined />}  onClick={onReload} />
                        </Space>

                    </div>
                </Col>

            </Row>
            <Table className="persons-table"
                rowKey={(person: Person) => person.id}
                rowClassName={getRowClassName}
                onRow={onRow}
                dataSource={props.persons}
                columns={columns} />
        </Col>


    </div>);
}