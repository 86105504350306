import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import { CountryDto } from "../api/client-axios";

export const getCountryFromPhoneNumber = (phoneNumber: string, countries: CountryDto[]) : CountryDto | undefined =>{
    const normalizedPhoneNumber =normalizePhoneNumber(phoneNumber);
    const country = countries.find((c)=>{
        return c.callingCodes &&  c.callingCodes.find(cc=> normalizedPhoneNumber.startsWith(cc) ); 
    });
    return country;
}

export const normalizePhoneNumber = (phoneNumber: string) : string => phoneNumber.replaceAll(/[\D]/gm,'');

export const formatPhoneNumber = (phoneNumber: string, countryCode: string) : string => {
    if(phoneNumber.length < 4) return phoneNumber; 
    const util = new PhoneNumberUtil();
    const pn = util.parse(phoneNumber,countryCode);
    return util.format(pn, PhoneNumberFormat.INTERNATIONAL)
}

export const isValidPhoneNumber = (phoneNumber: string,countryCode: string | null | undefined) : boolean =>{
    if(countryCode === undefined || countryCode === null || phoneNumber.length < 4) return false;
    const util = new PhoneNumberUtil();
    const pn = util.parse(phoneNumber,countryCode);
    return util.isValidNumber(pn);
}

export const formatPhone = ( phoneNumber: string | null | undefined, countries: CountryDto[] ) : string =>{
    if(phoneNumber === undefined || phoneNumber === null || phoneNumber!.length < 3) return phoneNumber ?? "";
    const country = getCountryFromPhoneNumber(phoneNumber!, countries);
    if(country === undefined) return phoneNumber;
    return formatPhoneNumber(phoneNumber, country.alpha2Code!);
}


