import "./AddPatient.css";
import {Button, Col, Form, Input, Modal, Row, Space, Spin} from "antd";
import {useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
    checkPatientAvailability,
    clearAddPatientError,
    initAddPatientState,
    setPatientPhoneNumber,
    setPatientPhoneValidation
} from "../features/patient/addPatientSlice";
import {Problem} from "../model/problem";
import {formatPhoneNumber, getCountryFromPhoneNumber, isValidPhoneNumber} from "../utils/formatPhone";
import {SendPhone} from "./SendPhone";
import {PatientResponseDto} from "../api/client-axios";
import {getPatientByCode, initAddPatientByCodeState, setActivationCode} from "../features/patient/addPatientByCode";

export const AddPatientByCode = (props: { onConfirm: (patient: PatientResponseDto) => void }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const status = useAppSelector((state) => state.addPatientByCode.status);
    const error = useAppSelector((state) => state.addPatientByCode.error);
    const patient = useAppSelector((state) => state.addPatientByCode.patient);
    const code = useAppSelector((state) => state.addPatientByCode.activationCode);


    const onSend = () => {
        dispatch(getPatientByCode(code));
    }

    const onChangeCode = (value: string) => {
        dispatch(setActivationCode(value));
    }

    const isModalVisible = status == "error" || status == "auth-error";

    const handleAuthOk = () => {
        dispatch(clearAddPatientError())
        navigate("/")
    }

    const handleErrorOk = () => {
        dispatch(clearAddPatientError())
    }

    const handleCancel = () => {
        dispatch(clearAddPatientError())
    }


    if (status === "loading") {
        return <Spin/>
    }

    if (status === "auth-error") {
        return <Modal title="Auth error" visible={isModalVisible} onOk={handleAuthOk} onCancel={handleCancel}>
            <p>"Authentication failed, please login"</p>
        </Modal>
    }


    if (status === "error" /*&& confirmMode !== ConfirmMode.Send*/) {
        const apiError = error as Problem;
        const title = apiError?.title ?? "Server Error";
        const detail = apiError?.detail ?? "";
        return <Modal title={title} visible={isModalVisible} onOk={handleErrorOk} onCancel={handleCancel}>
            <p>{detail}</p>
        </Modal>
    }

    // if (status === "success" && patientAvailability?.isExists && patientAvailability.isMyOrganization) {
    //     return <Modal title="Error add patient" visible={isModalVisible} onOk={handleErrorOk} cancelButtonProps={{ hidden: true }}>
    //         <p>"Patient already exists in our clinic"</p>
    //     </Modal>
    // }
    //
    // if (status === "success" && patientAvailability?.isExists && !patientAvailability.isMyOrganization) {
    //     return <Modal title="Error add patient" visible={true} onOk={handleErrorOk}  cancelButtonProps={{ hidden: true }}  >
    //         <p>"Patient already exists in other clinic"</p>
    //     </Modal>
    // }

    if(patient?.userAccount?.id != null) {
        props.onConfirm(patient);
        dispatch(initAddPatientByCodeState());
    }

    const onCancel = () => {
        dispatch(initAddPatientState());
        navigate(-1);
    }

    // if (status === "success" && patientAvailability?.isExists === false) {
    //     return <SendPhone onConfirm={onConfirm} phoneNumber={phoneNumber} />;
    // }

    return (<div className="main-frame">
        <Form className='form'
              name="add-patient"
              labelCol={{span: 8}}
              wrapperCol={{span: 24}}
              initialValues={{remember: true}}
              autoComplete="off"
        >
            <Form.Item initialValue={code} help="Enter activation code from patient application" name="activationCode"
                       label="Code:" rules={[{required: true, message: 'please enter code'}]}>

                <Input type="tel" placeholder='XXXX-XXXX' value={code} onChange={(e) => onChangeCode(e.target.value)}/>
            </Form.Item>
            <Form.Item wrapperCol={{offset: 8, span: 24}}>
                <Row>
                    <Col span={24} style={{textAlign: 'right'}}>
                        <Space>
                            <Button onClick={onCancel}>Cancel</Button>
                            <Button type='primary' onClick={onSend}>Send</Button>;
                        </Space>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    </div>);
}


