import { Spin, Modal } from "antd";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { PersonList } from "../components/PersonList";
import { fetchDoctors } from "../features/doctor/doctorsSlice";
import { Person } from "../model/person";
import { Problem } from "../model/problem";
import { RouteNames } from "../routes";
import { formatPhone, formatPhoneNumber, getCountryFromPhoneNumber } from "../utils/formatPhone";

export const DoctorsPage = () => {
    const dispatch = useAppDispatch();
    const doctors = useAppSelector((state) => state.doctors.doctors);
    const status = useAppSelector((state) => state.doctors.status);
    const error = useAppSelector((state) => state.doctors.error);
    const countries = useAppSelector((state) => state.country.countries);

    const navigate = useNavigate();
    const params = useParams();

    const isEditPage = params.doctorId !== undefined;
    const onEditDoctor = (id?: String) => {

        const route = `/${RouteNames.doctors}/${id}`;
        console.log(route);
        navigate(route);
    }


    useEffect(() => {
        if (status === 'idle') {
            console.log(`from useEffect ${status}`);
            dispatch(fetchDoctors());
        }

    });

    const [isModalVisible, setIsModalVisible] = useState(false);

  

    const reloadDoctors = () => {
        dispatch(fetchDoctors());
    }
   
    const onSearch = (query: string)=>{
        dispatch(fetchDoctors({q: query}));
    }

    const handleOk = () => {
        setIsModalVisible(false)
        dispatch(fetchDoctors());
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    //todo implement server side paging and sorting
    switch (status) {
        case "loading":
            return <Spin />;
        case "error":
        case "auth-error":
            const apiError  = error as Problem;
            const title = apiError.title ?? "Server Error";
            const detail = apiError.detail ?? error?.toString;  
            return <Modal title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>{detail}</p>
            </Modal>
        case "success":
            return (
                <div style={{ height: '100%' }}>
                    {isEditPage ? <Outlet /> : <PersonList persons={
                        doctors?.map<Person>((patient, _, __) => {
                                                    return {
                                                        id: patient.id ?? "",
                                                        firstName: patient.firstName ?? "",
                                                        lastName: patient.lastName ?? "",
                                                        phoneNumber: formatPhone(patient.userAccount?.mobileNumber, countries),
                                                        email: patient.userAccount?.emailAddress ?? ""
                                                    };
                                                }) ?? []
                    } title='Doctors' onEditPerson={onEditDoctor} onReload={reloadDoctors} onSearch={onSearch} />
                    }
                </div>);
        case "idle":
            return <Spin />;
    }

    // return (
    //     <div style={{height: '100%'}}>
    //         {isEditPage ? <Outlet /> : <PersonList persons={doctors?.map<Person>((patient, _, __) => {
    //                         return {
    //                             id: patient.id ?? "",
    //                             firstName: patient.firstName ?? "",
    //                             lastName: patient.lastName ?? "",
    //                             phoneNumber: formatPhone(patient.userAccount?.mobileNumber, countries),
    //                             email: patient.userAccount?.emailAddress ?? ""
    //                         };
    //                     }) ?? []} title='Doctors' onEditPerson={onEditDoctor} />
    //         }
    //     </div>

    // );
}




