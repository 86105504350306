import { configureStore } from "@reduxjs/toolkit";
import caretakerReducer from "../features/caretaker/caretakerSlice";
import caretakersReducer from "../features/caretaker/caretakersSlice";
import countryReducer from "../features/country/countrySlice";
import patientsReducer from "../features/patient/patientsSlice";
import addPatientReducer from "../features/patient/addPatientSlice";
import doctorReducer from "../features/doctor/doctorSlice";
import doctorsReducer from "../features/doctor/doctorsSlice";
import patientReducer from "../features/patient/patientSlice";
import patientsToAssignReducer from "../features/patientsToAssign/patientsToAssignSlice";
import caretakersToAssignReducer from "../features/caretakersToAssign/caretakersToAssignSlice";
import otpReducer from "../features/otp/otpSlice";
import addPatientByCodeReducer from "../features/patient/addPatientByCode"

export const store = configureStore({  
    reducer: {
        country: countryReducer,
        patients: patientsReducer,
        addPatient: addPatientReducer,
        patient: patientReducer,
        patientsToAssign: patientsToAssignReducer,
        caretaker: caretakerReducer,
        caretakers: caretakersReducer,
        caretakersToAssign: caretakersToAssignReducer,
        doctor: doctorReducer,
        doctors: doctorsReducer,
        otp: otpReducer,
        addPatientByCode:addPatientByCodeReducer
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;