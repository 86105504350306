import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { CaretakerResponseDto, OrganizationDoctorsApi } from "../../api/client-axios";

import { ApiError } from "../../model/apiError";
import { Problem } from "../../model/problem";
import {apiConfiguration} from "../server";

export interface CaretakersToAssignState {
    caretakersAvaliable: CaretakerResponseDto[];
    status: "idle" | "loading" | "error" | "success" | "auth-error";
    error?: string | Problem;
    code?: number;
}


const initialCaretakersToAssignState: CaretakersToAssignState = {
    status: "idle",
    error: "",
    caretakersAvaliable: []
}

export const fetchDoctorAvaliableCaretakers = createAsyncThunk<CaretakerResponseDto[], string, { rejectValue: ApiError }>('caretakersToAssign/fetchAvaliableCaretakersForDoctor', async (doctorId, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAvailableGet(doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});




export const clearDoctorAvaliableCaretakers = createAction('caretakersToAssign/clearDoctorAvaliableCaretakers');

const caretakersAvaliableSlice = createSlice({
    name: 'caretaker',
    initialState: initialCaretakersToAssignState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDoctorAvaliableCaretakers.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchDoctorAvaliableCaretakers.fulfilled, (state, action) => {
                state.caretakersAvaliable = action.payload;
                state.status = "success";
            })
            .addCase(fetchDoctorAvaliableCaretakers.rejected, (state, action) => {
                processError(action, state);
            })
    }
});


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: any; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: CaretakersToAssignState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;
        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
                if (state.code == 0) {
                    state.code = 500;
                    state.error = "Internal Server Error";
                }
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}

export default caretakersAvaliableSlice.reducer;