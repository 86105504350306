import { Alert, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { RouteNames } from "../routes";
import { fetchPatients } from "../features/patient/patientsSlice";
import { PersonList } from "../components/PersonList";
import { Person } from "../model/person";
import { formatPhone } from "../utils/formatPhone";
import { Problem } from "../model/problem";

export const PatientsPage = () => {
    const dispatch = useAppDispatch();
    const patients = useAppSelector((state: RootState) => state.patients.patients);
    const countries = useAppSelector((state: RootState) => state.country.countries);
    const status = useAppSelector((state: RootState) => state.patients.status);
    const error = useAppSelector((state: RootState) => state.patients.error);
    const navigate = useNavigate();
    const params = useParams();


    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchPatients())
        }

    });

    const [isModalVisible, setIsModalVisible] = useState(false);

    const isEditPage = params.patientId !== undefined;
    const onEditPatient = (id?: String) => {

        const route = `/${RouteNames.patients}/${id}`;
        console.log(route);
        navigate(route);
    }

    const reloadPatients = () => {
        dispatch(fetchPatients());
    }
   
    const onSearch = (query: string)=>{
        dispatch(fetchPatients({q: query}));
    }

    const handleOk = () => {
        setIsModalVisible(false)
        dispatch(fetchPatients());
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }



    //todo implement server side paging and sorting
    switch (status) {
        case "loading":
            return <Spin />;
        case "error":
        case "auth-error":
            const apiError =  error as Problem;
            const title =  typeof error === 'string' ? error : apiError.title ?? "Server Error";
            return <Modal title="Error" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>{title}</p>
            </Modal>
        case "success":
            return (
                <div style={{ height: '100%' }}>
                    {isEditPage ? <Outlet /> : <PersonList persons={
                        patients!.map<Person>((patient, _, __) => {
                            return {
                                id: patient.id ?? "",
                                firstName: patient.firstName ?? "",
                                lastName: patient.lastName ?? "",
                                phoneNumber: formatPhone(patient.userAccount?.mobileNumber, countries),
                                email: patient.userAccount?.emailAddress ?? ""
                            };
                        })
                    } title='Patients' onEditPerson={onEditPatient} onReload={reloadPatients} onSearch={onSearch} />
                    }
                </div>);
        case "idle":
            return <Spin />;
    }
}
