import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { OrganizationPatientsApi, PatientResponseDto, PatientResponseDtoPagedList } from "../../api/client-axios/api";
import { Configuration } from "../../api/client-axios/configuration";
import { ApiError } from "../../model/apiError";
import { Problem } from "../../model/problem";
import {apiConfiguration} from "../server";

export interface PatientsState {
    patients?: PatientResponseDto[],
    status: "idle" | "loading" | "error" | "success" | "auth-error";
    error?: string | Problem;
    code?: number;
}



const initialPatientState: PatientsState = {
    status: "idle",
    error: ""
}


export const fetchPatients = createAsyncThunk<PatientResponseDtoPagedList, {pageNumber?: number, pageSize?: number, q?: string} | undefined, { rejectValue: ApiError }>('patient/fetchPatients', async (args, thunkApi) => {
    try {
      
        const api = new OrganizationPatientsApi (apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgPatientsGet(args?.pageNumber, args?.pageSize,args?.q);    
        return response.data;
        
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});




export const deletePatient = createAsyncThunk<void, String, { rejectValue: ApiError }>('patient/deletePatient', async (patientId, thunkApi) => {
    try {
        //const api = new CountriesApi(apiConfiguration());
        //const api = new ActivityApi(apiConfiguration());
        //const response = await api.apiV1ActivityGet();    
        //return response.data;
        return thunkApi.rejectWithValue({ message: "Todo add real api call", statusCode: 500 });
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});


const patientsSlice = createSlice({
    name: 'patients',
    initialState: initialPatientState,
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(fetchPatients.pending, (state, _) => {
            state.status = "loading";
        })
        .addCase(fetchPatients.fulfilled, (state, action) => {
            state.patients = action.payload.items;
            state.status = "success";
        })
        .addCase(fetchPatients.rejected, (state, action) => {
            if(action.payload){
                state.status = "auth-error";
                state.error = action.payload.message;
                state.code = action.payload.statusCode;
            }else{
                state.status = "error";
                state.error = action.error.message;
            }
        })
        .addCase(deletePatient.pending, (state, _) => {
            state.status = "loading";
        })
        .addCase(deletePatient.fulfilled, (state, action) => {
            state.status = "success";
        })
        .addCase(deletePatient.rejected, (state, action) => {
            if(action.payload){
                state.status = "auth-error";
                state.error = action.payload.message;
                state.code = action.payload.statusCode;
            }else{
                state.status = "error";
                state.error = action.error.message;
            }
        })
                         
    }
});

export default patientsSlice.reducer;
