import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { OrganizationCaretakersApi, CaretakerResponseDto, CaretakerResponseDtoPagedList } from "../../api/client-axios/api";
import { Configuration } from "../../api/client-axios/configuration";
import { ApiError } from "../../model/apiError";
import { Problem } from "../../model/problem";
import {apiConfiguration} from "../server";

export interface CaretakersState {
    caretakers?: CaretakerResponseDto[],
    status: "idle" | "loading" | "error" | "success" | "auth-error";
    error?: string | Problem;
    code?: number;
}


const initialCaretakerState: CaretakersState = {
    status: "idle",
    error: ""
}


export const fetchCaretakers = createAsyncThunk<CaretakerResponseDtoPagedList, {pageNumber?: number, pageSize?: number, q?: string} | undefined, { rejectValue: ApiError }>('caretaker/fetchCaretakers', async (args, thunkApi) => {
    try {
      
        const api = new OrganizationCaretakersApi (apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersGet(args?.pageNumber, args?.pageSize,args?.q);    
        return response.data;
        
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});

const caretakersSlice = createSlice({
    name: 'caretakers',
    initialState: initialCaretakerState,
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(fetchCaretakers.pending, (state, _) => {
            state.status = "loading";
        })
        .addCase(fetchCaretakers.fulfilled, (state, action) => {
            state.caretakers = action.payload.items;
            state.status = "success";
        })
        .addCase(fetchCaretakers.rejected, (state, action) => {
            if(action.payload){
                state.status = "auth-error";
                state.error = action.payload.message;
                state.code = action.payload.statusCode;
            }else{
                state.status = "error";
                state.error = action.error.message;
            }
        })

                         
    }
});

export default caretakersSlice.reducer;