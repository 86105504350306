// This is just for the demo. Fetch data with an API jwt token
// Should be removed completely in production

import { Alert, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { fetchCountries } from "../features/country/countrySlice";
import { Problem } from "../model/problem";

export const CountriesList = () => {
    const dispatch = useAppDispatch();
    const countries = useAppSelector((state: RootState) => state.country.countries);
    const status = useAppSelector((state: RootState) => state.country.status);
    const error = useAppSelector((state: RootState) => state.country.error);
    const code = useAppSelector((state: RootState) => state.country.code);

    useEffect(() => {
        if (status === "idle") {
            dispatch(fetchCountries());
        }
    }, [status, dispatch]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    // useEffect(() => {
    //     if (status === "auth-error") {
    //         window.location.href = "/";
    //     }
    // },[status]);

    useEffect(()=>{
        setIsModalVisible(true);
    },[status])


    const handleOk = ()=>{
        setIsModalVisible(false)
        dispatch(fetchCountries());
    }

    const handleCancel = ()=>{
        setIsModalVisible(false)
    }

    switch (status) {
        case "loading":
            return <Spin />;
        case "error":
            const apiError  = error as Problem;
            const title = apiError.title ?? "Server Error";
            return <Alert type="error" message={title} />;
        case "auth-error":
            // return <Alert type="error" message={`Failed with code ${code}`} />;
            return       <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
          </Modal>
        case "success":
            return <div>
                {countries.map(country => <div key={country.name}>{country.name}</div>)}</div>;
        case "idle":
            return <Spin />;
    }

}