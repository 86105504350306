import {Button} from "antd";
import {useAuth} from "react-oidc-context";

export const AccountPage = () => {
    const auth = useAuth();

    const logoutAuth0 = async () => {
        localStorage.removeItem("token");
        await auth.signoutRedirect({
            post_logout_redirect_uri: window.location.origin,
            id_token_hint: auth.user?.id_token
        })
        await auth.removeUser();
    };

    return (
        <div>
            {auth.isAuthenticated ? <div style={{width: '100%', textAlign: 'right', padding: '20px'}}><span
                    style={{paddingRight: '20px'}}>{auth.user?.profile.name}</span> <Button type="primary" onClick={logoutAuth0}>Logout</Button></div> :
                <div>Not authenticated</div>}
        </div>
    );
}