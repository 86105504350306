import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RouteNames } from './routes';
import { EditDoctor } from './pages/EditDoctorPage';
import { DoctorsPage } from './pages/DoctorsPage';
import { CaretakersPage } from './pages/CaretakersPage';
import { PatientsPage } from './pages/PatientsPage';
import { EditCaretakerPage } from './pages/EditCaretakerPage';
import { EditPatientPage } from './pages/EditPatientPage';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { CountriesList } from './pages/Countries';
import { AccountPage } from './pages/AccountPage';
import { AssignPersons } from './components/AssignPersons';
import keycloak from "./authConfig/keyCloakConfig";
import {AuthProvider} from "react-oidc-context";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
 // <React.StrictMode>
    <Provider store={store}>
      <AuthProvider {...keycloak}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<App />}>
              <Route path={`${RouteNames.doctors}`} element={<DoctorsPage />}>
                <Route path=":doctorId" element={<EditDoctor />} />
              </Route>
              <Route path={`${RouteNames.caretakers}`} element={<CaretakersPage />} >
                <Route path=":caretakerId" element={<EditCaretakerPage />} />
              </Route>
              <Route path={`${RouteNames.patients}`} element={<PatientsPage />}>
                <Route path=":patientId" element={<EditPatientPage />} />
              </Route>
              <Route path='*' element={<AccountPage />} />
           
            </Route>
            <Route path={`countries`} element={<CountriesList />} />

          </Routes>
        </BrowserRouter>
      </AuthProvider>

    </Provider>


 // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
