import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { OrganizationAuthorizeApi, UserAccessRequest, UserAccessSubmitOtpRequest } from "../../api/client-axios/api";
import { Configuration } from "../../api/client-axios/configuration";
import { ApiError } from "../../model/apiError";
import { ConfirmMode } from "../../model/confirmEnum";
import { Problem } from "../../model/problem";

const REACT_APP_SIMULATE_SEND_SMS =  process.env.REACT_APP_SIMULATE_SEND_SMS !== "false" ?? true;
console.log("REACT_APP_SIMULATE_SEND_SMS", REACT_APP_SIMULATE_SEND_SMS);
export interface OtpState{
    confirmMode? : ConfirmMode,
    phoneNumber?: string,
    otpCode?: string,
    status?: "idle" | "loading" | "error" | "success" | "auth-error";
    error?: string | Problem;
    code?: number;
}


export const initialOtpState : OtpState = {
    status: "idle",
    confirmMode: ConfirmMode.None,
}

export const requiestOtpCode =  createAsyncThunk<void, UserAccessRequest, { rejectValue: ApiError }>('otp/requestOtpCode', async (userAccessRequest, thunkApi) => {
    try {
        const api = new OrganizationAuthorizeApi(new Configuration({ apiKey: `Bearer  ${localStorage.getItem('token')}` }));
        const response = await api.apiV1OrganizationsMyAdministrationRequestAccessPost(REACT_APP_SIMULATE_SEND_SMS, userAccessRequest)
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const confirmOtp =  createAsyncThunk<void, UserAccessSubmitOtpRequest, { rejectValue: ApiError }>('otp/confirmOtpCode', async (userAccessSubmitOtpRequest, thunkApi) => {
    try {
        const api = new OrganizationAuthorizeApi(new Configuration({ apiKey: `Bearer  ${localStorage.getItem('token')}` }));
        const response = await api.apiV1OrganizationsMyAdministrationConfirmNewAccountPost(userAccessSubmitOtpRequest)
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

  export const initOtpState = createAction('otp/initState');

  export const clearSendOtpError = createAction('otp/clearError');


const otpSlice = createSlice({
    name: 'otp',
    initialState: initialOtpState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(requiestOtpCode.pending, (state, _) => {

                state.status = "loading";
            })
            .addCase(requiestOtpCode.fulfilled, (state, action) => {
                state.status = "success";
                state.phoneNumber =  action.meta.arg.phoneNumber!
                state.confirmMode = ConfirmMode.Send;
            })
            .addCase(requiestOtpCode.rejected, (state, action) => {
                state.phoneNumber =  action.meta.arg.phoneNumber!
                processError(action, state);
            })
            .addCase(confirmOtp.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(confirmOtp.fulfilled, (state, action) => {
                state.otpCode = action.meta.arg.code!;
                state.phoneNumber =  action.meta.arg.phoneNumber!
                state.confirmMode = ConfirmMode.Confirm;
                state.status = "success";
            })
            .addCase(confirmOtp.rejected, (state, action) => {
                state.otpCode = action.meta.arg.code!;
                processError(action, state);
            })
            .addCase(initOtpState,(state,_)=>{
              state.status = "idle";
              state.code = undefined;
              state.phoneNumber = undefined;
              state.confirmMode = ConfirmMode.None;
              state.otpCode = undefined;
              state.error = undefined;
                
            })
            .addCase(clearSendOtpError, (state, action) => {
                state.status = "idle";
            })
            .addDefaultCase((state,action)=>{})
          

    }
});

export default otpSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg:  UserAccessSubmitOtpRequest | UserAccessRequest; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: OtpState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;
        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (action.payload.statusCode == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}



