import { applyMiddleware, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { DoctorResponseDto, DoctorResponseDtoPagedList, OrganizationDoctorsApi } from "../../api/client-axios";
import { Configuration } from "../../api/client-axios";
import { ApiError } from "../../model/apiError";
import { Problem } from "../../model/problem";
import {apiConfiguration} from "../server";

export interface DoctorState {
    doctors?: DoctorResponseDto[],
    status: "idle" | "loading" | "error" | "success" | "auth-error",
    error?: string | Problem,
    code?: number
}


const initialDoctorState: DoctorState = {
    status: "idle",
    error: ""
}

export const fetchDoctors = createAsyncThunk<DoctorResponseDtoPagedList, { pageNumber?: number, pageSize?: number, q?: string } | undefined, { rejectValue: ApiError }>('doctor/fetchDoctors', async (_, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsGet()
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const attachDoctor = createAsyncThunk<DoctorResponseDtoPagedList, string, { rejectValue: ApiError }>('doctor/detachDoctor', async (doctorId, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDetachPost(doctorId);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsGet()
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const detachDoctor = createAsyncThunk<DoctorResponseDtoPagedList, string, { rejectValue: ApiError }>('doctor/attachDoctor', async (doctorId, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdAttachPost(doctorId);
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsGet()
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


const doctorsSlice = createSlice({
    name: 'doctor',
    initialState: initialDoctorState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDoctors.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchDoctors.fulfilled, (state, action) => {
                state.doctors = action.payload.items;
                state.status = "success";
            })
            .addCase(fetchDoctors.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(detachDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(detachDoctor.fulfilled, (state, action) => {
                state.status = "success";
                state.doctors = action.payload.items;
            })
            .addCase(detachDoctor.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(attachDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(attachDoctor.fulfilled, (state, action) => {
                state.status = "success";
                state.doctors = action.payload.items;
            })
            .addCase(attachDoctor.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })

    }
});

export default doctorsSlice.reducer;