import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { AssignPersons } from "../components/AssignPersons";
import { Person } from "../model/person";
import { Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { clearDoctorAvaliableCaretakers, fetchDoctorAvaliableCaretakers } from "../features/caretakersToAssign/caretakersToAssignSlice";
import { assignCaretakersToDoctor, cancelAddPersonsToDoctor } from "../features/doctor/doctorSlice";
import { Problem } from "../model/problem";


export const AssignCaretakersToDoctor = (
  props: {
    doctorId: string;
  }
) => {

  const dispatch = useAppDispatch();
  const caretakers = useAppSelector((state) => state.caretakersToAssign.caretakersAvaliable);
  const status = useAppSelector((state) => state.caretakersToAssign.status);
  const error = useAppSelector((state) => state.caretakersToAssign.error);
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (status == "idle") {
      dispatch(fetchDoctorAvaliableCaretakers(props.doctorId));
    }
  });

  const handleErrorOk = () => {
    setIsModalVisible(false);
    dispatch(clearDoctorAvaliableCaretakers());
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleAuthOk = () => {
    setIsModalVisible(false)
    navigate("/")
  }
 
  const onCancel = () => {
    dispatch(cancelAddPersonsToDoctor());
  }

  const oncaretakersAssign = (persons: Person[]) => {
    dispatch(assignCaretakersToDoctor({ doctorId: props.doctorId, caretakerIds: persons.map((person) => person.id) }));
  }

  if (status === "idle" || status === "loading") {
    return <Spin />;
  }

  if (status === "auth-error") {
    return <Modal title="Auth error" visible={isModalVisible} onOk={handleAuthOk} onCancel={handleCancel}>
      <p>"Authentication failed, please login"</p>
    </Modal>
  }

  if (status === "error") {
    const apiError = error as Problem;
    const title = apiError.title ?? "Server Error";
    const detail = apiError.detail ?? error?.toString;
    return <Modal title={title} visible={isModalVisible} onOk={handleErrorOk} onCancel={handleCancel}>
      <p>{detail}</p>
    </Modal>
  }

  return <AssignPersons

    onPersonsAssign={oncaretakersAssign}
    onCancel={onCancel}

    persons={caretakers.map(p => {
      return {
        id: p.id,
        firstName: p.firstName,
        lastName: p.lastName,
        phoneNumber: p.userAccount?.mobileNumber,
        email: p.userAccount?.emailAddress
      } as Person;
    })} />;
}


