import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { OrganizationCaretakersApi, OrganizationDoctorsApi, PatientResponseDto} from "../../api/client-axios/api";
import { ApiError } from "../../model/apiError";
import { Problem } from "../../model/problem";
import {apiConfiguration} from "../server";

export interface PatientsToAssignState {
    patientsAvaliable: PatientResponseDto[];
    status: "idle" | "loading" | "error" | "success" | "auth-error";
    error?: string | Problem;
    code?: number;
}

const initialPatientsToAssignState: PatientsToAssignState = {
    status: "idle",
    error: "",
    patientsAvaliable: []
}

export const fetchDoctorAvaliablePatients = createAsyncThunk<PatientResponseDto[], string, { rejectValue: ApiError }>('patientsToAssign/fetchAvaliablePatientsForDoctor', async (doctorId, thunkApi) => {
    try {
        const api = new OrganizationDoctorsApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAvailableGet(doctorId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const fetchCaretakerAvaliablePatients = createAsyncThunk<PatientResponseDto[], string, { rejectValue: ApiError }>('patientsToAssign/fetchAvaliablePatientsForCaretaker', async (caretakerId, thunkApi) => {
    try {
        const api = new OrganizationCaretakersApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAvailableGet(caretakerId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const clearErrorAvaliablePatients = createAction('patientsToAssign/clearDoctorAvaliablePatients');

const patientsAvaliableSlice = createSlice({
    name: 'patient',
    initialState: initialPatientsToAssignState,
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(fetchDoctorAvaliablePatients.pending, (state, _) => {
            state.status = "loading";
        })
        .addCase(fetchDoctorAvaliablePatients.fulfilled, (state, action) => {
            state.patientsAvaliable = action.payload;
            state.status = "success";
        })
        .addCase(fetchDoctorAvaliablePatients.rejected, (state, action) => {
            processError(action, state);
        })
        .addCase(fetchCaretakerAvaliablePatients.pending, (state, _) => {
            state.status = "loading";
        })
        .addCase(fetchCaretakerAvaliablePatients.fulfilled, (state, action) => {
            state.patientsAvaliable = action.payload;
            state.status = "success";
        })
        .addCase(fetchCaretakerAvaliablePatients.rejected, (state, action) => {
            processError(action, state);
        })
        .addCase(clearErrorAvaliablePatients, (state, _) => {
            state.status = "success";
        })
    
    }});


    function processError(
        action:
            PayloadAction<ApiError | undefined,
                string,
                { arg: any; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
                ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
                SerializedError>,
        state: PatientsToAssignState) {
        if (action.payload) {
    
            //state.code = action.payload.statusCode;
            //const apiError = action.payload as ApiError;
            if (action.payload !== undefined) {
                state.error = action.payload.message;
                state.code = action.payload.statusCode;
                if (state.code == 401) {
                    state.status = "auth-error";
                } else {
                    state.status = "error";
                    if(state.code == 0){
                        state.code = 500;
                        state.error = "Internal Server Error";
                    }
                }
            }
        } else {
            state.status = "error";
            state.error = action.error.message;
        }
    }
    
export default patientsAvaliableSlice.reducer;