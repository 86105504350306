/* tslint:disable */
/* eslint-disable */
/**
 * Aiutante API 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessTokenExtra
 */
export interface AccessTokenExtra {
    /**
     * 
     * @type {string}
     * @memberof AccessTokenExtra
     */
    'https://aiutante.com/id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessTokenExtra
     */
    'https://aiutante.com/patients'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenExtra
     */
    'https://aiutante.com/family_member'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenExtra
     */
    'https://aiutante.com/caretaker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenExtra
     */
    'https://aiutante.com/patient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenExtra
     */
    'https://aiutante.com/doctor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenExtra
     */
    'https://aiutante.com/organization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenExtra
     */
    'https://aiutante.com/organization_admin'?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityRequestDto
 */
export interface ActivityRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityResponseDto
 */
export interface ActivityResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'alpha3Code'?: string | null;
}
/**
 * 
 * @export
 * @interface AudioFileResponseDto
 */
export interface AudioFileResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AudioFileResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudioFileResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileResponseDto
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileResponseDto
     */
    'tags'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileResponseDto
     */
    'uploadedDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudioFileResponseDto
     */
    'duration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileResponseDto
     */
    'contentId'?: string;
}
/**
 * 
 * @export
 * @interface BinaryData
 */
export interface BinaryData {
    /**
     * 
     * @type {string}
     * @memberof BinaryData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BinaryData
     */
    'body': string;
}
/**
 * 
 * @export
 * @interface CaretakerAvailability
 */
export interface CaretakerAvailability {
    /**
     * 
     * @type {boolean}
     * @memberof CaretakerAvailability
     */
    'isExists'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CaretakerAvailability
     */
    'isMyOrganization'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CaretakerAvailability
     */
    'caretakerId'?: string | null;
}
/**
 * 
 * @export
 * @interface CaretakerCreateRequestDto
 */
export interface CaretakerCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CaretakerCreateRequestDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CaretakerCreateRequestDto
     */
    'lastName': string;
    /**
     * 
     * @type {Address}
     * @memberof CaretakerCreateRequestDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CaretakerCreateRequestDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaretakerCreateRequestDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaretakerCreateRequestDto
     */
    'userAccountId'?: string | null;
    /**
     * 
     * @type {UserAccountOrganizationCreateRequestDto}
     * @memberof CaretakerCreateRequestDto
     */
    'userAccount'?: UserAccountOrganizationCreateRequestDto;
    /**
     * 
     * @type {string}
     * @memberof CaretakerCreateRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof CaretakerCreateRequestDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof CaretakerCreateRequestDto
     */
    'birthday'?: string | null;
}


/**
 * 
 * @export
 * @interface CaretakerListResponseDto
 */
export interface CaretakerListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CaretakerListResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CaretakerListResponseDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaretakerListResponseDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaretakerListResponseDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {PatientCaretakerStatus}
     * @memberof CaretakerListResponseDto
     */
    'status'?: PatientCaretakerStatus;
}


/**
 * 
 * @export
 * @interface CaretakerResponseDto
 */
export interface CaretakerResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CaretakerResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CaretakerResponseDto
     */
    'userAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CaretakerResponseDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaretakerResponseDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof CaretakerResponseDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CaretakerResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaretakerResponseDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaretakerResponseDto
     */
    'currentPatientId'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof CaretakerResponseDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof CaretakerResponseDto
     */
    'birthday'?: string | null;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof CaretakerResponseDto
     */
    'userAccount'?: UserAccountDto;
}


/**
 * 
 * @export
 * @interface CaretakerResponseDtoPagedList
 */
export interface CaretakerResponseDtoPagedList {
    /**
     * 
     * @type {Array<CaretakerResponseDto>}
     * @memberof CaretakerResponseDtoPagedList
     */
    'items': Array<CaretakerResponseDto>;
    /**
     * 
     * @type {PageInfo}
     * @memberof CaretakerResponseDtoPagedList
     */
    'paging': PageInfo;
}
/**
 * 
 * @export
 * @interface CaretakerUpdateRequestDto
 */
export interface CaretakerUpdateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CaretakerUpdateRequestDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaretakerUpdateRequestDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof CaretakerUpdateRequestDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CaretakerUpdateRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaretakerUpdateRequestDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof CaretakerUpdateRequestDto
     */
    'userAccount'?: UserAccountDto;
    /**
     * 
     * @type {Gender}
     * @memberof CaretakerUpdateRequestDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof CaretakerUpdateRequestDto
     */
    'birthday'?: string | null;
}


/**
 * 
 * @export
 * @interface ChangeStatusRequest
 */
export interface ChangeStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeStatusRequest
     */
    'id'?: string;
    /**
     * 
     * @type {UserAccountStatus}
     * @memberof ChangeStatusRequest
     */
    'status'?: UserAccountStatus;
}


/**
 * 
 * @export
 * @interface CountryDto
 */
export interface CountryDto {
    /**
     * 
     * @type {string}
     * @memberof CountryDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CountryDto
     */
    'topLevelDomain'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CountryDto
     */
    'alpha2Code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryDto
     */
    'alpha3Code'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CountryDto
     */
    'callingCodes'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CountryDto
     */
    'flag'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DateRange = {
    Week: 'Week',
    Month: 'Month',
    ThreeMonth: 'ThreeMonth',
    SixMonth: 'SixMonth',
    Year: 'Year'
} as const;

export type DateRange = typeof DateRange[keyof typeof DateRange];


/**
 * 
 * @export
 * @interface DoctorAvailability
 */
export interface DoctorAvailability {
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAvailability
     */
    'isExists'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAvailability
     */
    'isMyOrganization'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoctorAvailability
     */
    'doctorId'?: string | null;
}
/**
 * 
 * @export
 * @interface DoctorRequestDto
 */
export interface DoctorRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DoctorRequestDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof DoctorRequestDto
     */
    'lastName': string;
    /**
     * 
     * @type {Address}
     * @memberof DoctorRequestDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof DoctorRequestDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoctorRequestDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoctorRequestDto
     */
    'userAccountId'?: string | null;
    /**
     * 
     * @type {UserAccountOrganizationCreateRequestDto}
     * @memberof DoctorRequestDto
     */
    'userAccount'?: UserAccountOrganizationCreateRequestDto;
    /**
     * 
     * @type {UserType}
     * @memberof DoctorRequestDto
     */
    'userType'?: UserType;
}


/**
 * 
 * @export
 * @interface DoctorResponseDto
 */
export interface DoctorResponseDto {
    /**
     * 
     * @type {string}
     * @memberof DoctorResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorResponseDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoctorResponseDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof DoctorResponseDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof DoctorResponseDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof DoctorResponseDto
     */
    'userAccount'?: UserAccountDto;
    /**
     * 
     * @type {string}
     * @memberof DoctorResponseDto
     */
    'currentPatientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoctorResponseDto
     */
    'organizationId'?: string | null;
}
/**
 * 
 * @export
 * @interface DoctorResponseDtoPagedList
 */
export interface DoctorResponseDtoPagedList {
    /**
     * 
     * @type {Array<DoctorResponseDto>}
     * @memberof DoctorResponseDtoPagedList
     */
    'items': Array<DoctorResponseDto>;
    /**
     * 
     * @type {PageInfo}
     * @memberof DoctorResponseDtoPagedList
     */
    'paging': PageInfo;
}
/**
 * 
 * @export
 * @interface DoctorUpdateRequestDto
 */
export interface DoctorUpdateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DoctorUpdateRequestDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoctorUpdateRequestDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof DoctorUpdateRequestDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof DoctorUpdateRequestDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {DoctorUserAccountUpdateRequestDto}
     * @memberof DoctorUpdateRequestDto
     */
    'userAccount'?: DoctorUserAccountUpdateRequestDto;
}
/**
 * 
 * @export
 * @interface DoctorUserAccountUpdateRequestDto
 */
export interface DoctorUserAccountUpdateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DoctorUserAccountUpdateRequestDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoctorUserAccountUpdateRequestDto
     */
    'mobilePhone'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentResponseDto
 */
export interface DocumentResponseDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseDto
     */
    'documentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseDto
     */
    'documentBodyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseDto
     */
    'ownerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {FormatType}
     * @memberof DocumentResponseDto
     */
    'formatType'?: FormatType;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentResponseDto
     */
    'documentType'?: DocumentType;
    /**
     * 
     * @type {number}
     * @memberof DocumentResponseDto
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseDto
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseDto
     */
    'extension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseDto
     */
    'uploadedDateTimeOffset'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseDto
     */
    'updatedDateTimeOffset'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentType = {
    Other: 'Other',
    Prescription: 'Prescription',
    MedicalTest: 'MedicalTest',
    MentalStatusExamination: 'MentalStatusExamination',
    DischargeSummary: 'DischargeSummary',
    OperativeReport: 'OperativeReport',
    MedicalHistoryRecord: 'MedicalHistoryRecord',
    ProfilePicture: 'ProfilePicture',
    PersonalPicture: 'PersonalPicture',
    CaretakerNotePicture: 'CaretakerNotePicture'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @interface DocumentUpdateDto
 */
export interface DocumentUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'documentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentUpdateDto
     */
    'documentType'?: DocumentType;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface EmergencyContactRequestDto
 */
export interface EmergencyContactRequestDto {
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactRequestDto
     */
    'patientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactRequestDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactRequestDto
     */
    'contactName'?: string | null;
}
/**
 * 
 * @export
 * @interface EmergencyContactResponseDto
 */
export interface EmergencyContactResponseDto {
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactResponseDto
     */
    'patientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactResponseDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactResponseDto
     */
    'contactName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactResponseDto
     */
    'profilePhotoUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface EnrichTokenRequest
 */
export interface EnrichTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof EnrichTokenRequest
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface FamilyMemberCaretakerRequestDto
 */
export interface FamilyMemberCaretakerRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberCaretakerRequestDto
     */
    'familyMemberId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberCaretakerRequestDto
     */
    'familyMemberPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberCaretakerRequestDto
     */
    'patientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberCaretakerRequestDto
     */
    'familyMemberName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberCaretakerRequestDto
     */
    'profilePhotoUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface FamilyMemberCreateRequestDto
 */
export interface FamilyMemberCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberCreateRequestDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberCreateRequestDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof FamilyMemberCreateRequestDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberCreateRequestDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {UserAccountCreateRequestDto}
     * @memberof FamilyMemberCreateRequestDto
     */
    'userAccount'?: UserAccountCreateRequestDto;
}
/**
 * 
 * @export
 * @interface FamilyMemberRequestDto
 */
export interface FamilyMemberRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberRequestDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberRequestDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberRequestDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof FamilyMemberRequestDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberRequestDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof FamilyMemberRequestDto
     */
    'userAccount'?: UserAccountDto;
}
/**
 * 
 * @export
 * @interface FamilyMemberResponseDto
 */
export interface FamilyMemberResponseDto {
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberResponseDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberResponseDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof FamilyMemberResponseDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberResponseDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof FamilyMemberResponseDto
     */
    'userAccount'?: UserAccountDto;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberResponseDto
     */
    'currentPatientId'?: string | null;
}
/**
 * 
 * @export
 * @interface FamilyMemberResponseDtoPagedList
 */
export interface FamilyMemberResponseDtoPagedList {
    /**
     * 
     * @type {Array<FamilyMemberResponseDto>}
     * @memberof FamilyMemberResponseDtoPagedList
     */
    'items': Array<FamilyMemberResponseDto>;
    /**
     * 
     * @type {PageInfo}
     * @memberof FamilyMemberResponseDtoPagedList
     */
    'paging': PageInfo;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FormatType = {
    Image: 'Image',
    PlainText: 'PlainText',
    Pdf: 'Pdf',
    Word: 'Word',
    Excel: 'Excel',
    Csv: 'Csv'
} as const;

export type FormatType = typeof FormatType[keyof typeof FormatType];


/**
 * 
 * @export
 * @enum {string}
 */

export const Gender = {
    Male: 'Male',
    Female: 'Female',
    Other: 'Other'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];


/**
 * 
 * @export
 * @interface HappinessAnalyticsResponse
 */
export interface HappinessAnalyticsResponse {
    /**
     * 
     * @type {string}
     * @memberof HappinessAnalyticsResponse
     */
    'dateTimeUtc'?: string;
    /**
     * 
     * @type {number}
     * @memberof HappinessAnalyticsResponse
     */
    'happiness'?: number;
}
/**
 * 
 * @export
 * @interface ImageFileResponseDto
 */
export interface ImageFileResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ImageFileResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageFileResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageFileResponseDto
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageFileResponseDto
     */
    'tags'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageFileResponseDto
     */
    'uploadedDateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageFileResponseDto
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ImageFileResponseDto
     */
    'height'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ImageFileResponseDto
     */
    'contentId'?: string;
}
/**
 * 
 * @export
 * @interface LocationHistoryDto
 */
export interface LocationHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof LocationHistoryDto
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationHistoryDto
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationHistoryDto
     */
    'dateTime'?: string;
}
/**
 * 
 * @export
 * @interface LocationHistoryUpdateDto
 */
export interface LocationHistoryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof LocationHistoryUpdateDto
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationHistoryUpdateDto
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface MeasurementAnalyticResponse
 */
export interface MeasurementAnalyticResponse {
    /**
     * 
     * @type {string}
     * @memberof MeasurementAnalyticResponse
     */
    'dateTimeUtc'?: string;
    /**
     * 
     * @type {Array<MeasurementValueResponseDto>}
     * @memberof MeasurementAnalyticResponse
     */
    'measurementValues'?: Array<MeasurementValueResponseDto> | null;
}
/**
 * 
 * @export
 * @interface MeasurementRequestDto
 */
export interface MeasurementRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MeasurementRequestDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementRequestDto
     */
    'measurementTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementRequestDto
     */
    'patientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementRequestDto
     */
    'patientScheduleItemResultId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementRequestDto
     */
    'caretakerId'?: string | null;
    /**
     * 
     * @type {Array<MeasurementValueRequestDto>}
     * @memberof MeasurementRequestDto
     */
    'measurementValues'?: Array<MeasurementValueRequestDto> | null;
}
/**
 * 
 * @export
 * @interface MeasurementResponseDto
 */
export interface MeasurementResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseDto
     */
    'measurementTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseDto
     */
    'patientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseDto
     */
    'patientScheduleItemResultId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseDto
     */
    'caretakerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseDto
     */
    'takenDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<MeasurementValueResponseDto>}
     * @memberof MeasurementResponseDto
     */
    'measurementValues'?: Array<MeasurementValueResponseDto> | null;
}
/**
 * 
 * @export
 * @interface MeasurementTypeDto
 */
export interface MeasurementTypeDto {
    /**
     * 
     * @type {string}
     * @memberof MeasurementTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementTypeDto
     */
    'measurementProcedure'?: string | null;
    /**
     * 
     * @type {Array<MeasurementValueTypeDto>}
     * @memberof MeasurementTypeDto
     */
    'valueTypes'?: Array<MeasurementValueTypeDto> | null;
}
/**
 * 
 * @export
 * @interface MeasurementTypeRequestDto
 */
export interface MeasurementTypeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MeasurementTypeRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementTypeRequestDto
     */
    'measurementProcedure'?: string | null;
}
/**
 * 
 * @export
 * @interface MeasurementValueRequestDto
 */
export interface MeasurementValueRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MeasurementValueRequestDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementValueRequestDto
     */
    'measurementId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementValueRequestDto
     */
    'measurementValueTypeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MeasurementValueRequestDto
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface MeasurementValueResponseDto
 */
export interface MeasurementValueResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MeasurementValueResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementValueResponseDto
     */
    'uoM'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementValueResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MeasurementValueResponseDto
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface MeasurementValueTypeDto
 */
export interface MeasurementValueTypeDto {
    /**
     * 
     * @type {string}
     * @memberof MeasurementValueTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementValueTypeDto
     */
    'uoM'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementValueTypeDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface MeasurementValueTypeRequestDto
 */
export interface MeasurementValueTypeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MeasurementValueTypeRequestDto
     */
    'uoM'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeasurementValueTypeRequestDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaFile
 */
export interface MediaFile {
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'id'?: string;
    /**
     * 
     * @type {MediaFileType}
     * @memberof MediaFile
     */
    'type'?: MediaFileType;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'contentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'ownerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'tags'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'uploadedDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'duration'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MediaFile
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MediaFile
     */
    'height'?: number | null;
    /**
     * 
     * @type {BinaryData}
     * @memberof MediaFile
     */
    'content'?: BinaryData;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MediaFileType = {
    Unknown: 'Unknown',
    Audio: 'Audio',
    Image: 'Image',
    Video: 'Video',
    LinkedImage: 'LinkedImage'
} as const;

export type MediaFileType = typeof MediaFileType[keyof typeof MediaFileType];


/**
 * 
 * @export
 * @interface MedicationRequestDto
 */
export interface MedicationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MedicationRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicationRequestDto
     */
    'instructions'?: string | null;
}
/**
 * 
 * @export
 * @interface MedicationResponseDto
 */
export interface MedicationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MedicationResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicationResponseDto
     */
    'instructions'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedicationResponseDto
     */
    'cancelled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicationResponseDto
     */
    'createdDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationResponseDto
     */
    'cancelledDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicationResponseDto
     */
    'image'?: string | null;
}
/**
 * 
 * @export
 * @interface NotePhotoResponseDto
 */
export interface NotePhotoResponseDto {
    /**
     * 
     * @type {string}
     * @memberof NotePhotoResponseDto
     */
    'binaryDataId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotePhotoResponseDto
     */
    'patientNoteId'?: string;
}
/**
 * 
 * @export
 * @interface ObjectIdDto
 */
export interface ObjectIdDto {
    /**
     * 
     * @type {string}
     * @memberof ObjectIdDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface OrganizationAdminCreateRequest
 */
export interface OrganizationAdminCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminCreateRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminCreateRequest
     */
    'lastName': string;
    /**
     * 
     * @type {Address}
     * @memberof OrganizationAdminCreateRequest
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminCreateRequest
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminCreateRequest
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminCreateRequest
     */
    'userAccountId'?: string | null;
    /**
     * 
     * @type {UserAccountOrganizationCreateRequestDto}
     * @memberof OrganizationAdminCreateRequest
     */
    'userAccount'?: UserAccountOrganizationCreateRequestDto;
    /**
     * 
     * @type {UserType}
     * @memberof OrganizationAdminCreateRequest
     */
    'userType'?: UserType;
}


/**
 * 
 * @export
 * @interface OrganizationAdminDto
 */
export interface OrganizationAdminDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminDto
     */
    'userAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminDto
     */
    'organizationId'?: string;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof OrganizationAdminDto
     */
    'userAccount'?: UserAccountDto;
}
/**
 * 
 * @export
 * @interface OrganizationAdminUpdateRequest
 */
export interface OrganizationAdminUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminUpdateRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAdminUpdateRequest
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationDto
 */
export interface OrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationFilter
 */
export interface OrganizationFilter {
    /**
     * 
     * @type {string}
     * @memberof OrganizationFilter
     */
    'searchTerm'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationFilter
     */
    'caretakers'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface OrganizationRequestDto
 */
export interface OrganizationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface PageInfo
 */
export interface PageInfo {
    /**
     * 
     * @type {number}
     * @memberof PageInfo
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInfo
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInfo
     */
    'pageSize'?: number;
}
/**
 * 
 * @export
 * @interface PatientAvailability
 */
export interface PatientAvailability {
    /**
     * 
     * @type {boolean}
     * @memberof PatientAvailability
     */
    'isExists'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientAvailability
     */
    'isMyOrganization'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientAvailability
     */
    'patientId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PatientCaretakerStatus = {
    Invited: 'Invited',
    Accepted: 'Accepted',
    Rejected: 'Rejected'
} as const;

export type PatientCaretakerStatus = typeof PatientCaretakerStatus[keyof typeof PatientCaretakerStatus];


/**
 * 
 * @export
 * @interface PatientCreateRequestDto
 */
export interface PatientCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PatientCreateRequestDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientCreateRequestDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof PatientCreateRequestDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof PatientCreateRequestDto
     */
    'diagnosis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientCreateRequestDto
     */
    'allowedDistance'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientCreateRequestDto
     */
    'checkAllowedDistance'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientCreateRequestDto
     */
    'trackLocation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PatientCreateRequestDto
     */
    'locationHistoryDays'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatientCreateRequestDto
     */
    'doctorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientCreateRequestDto
     */
    'doctorLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientCreateRequestDto
     */
    'doctorPhone'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof PatientCreateRequestDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof PatientCreateRequestDto
     */
    'birthday'?: string | null;
    /**
     * 
     * @type {UserAccountCreateRequestDto}
     * @memberof PatientCreateRequestDto
     */
    'userAccount'?: UserAccountCreateRequestDto;
}


/**
 * 
 * @export
 * @interface PatientDashboardDto
 */
export interface PatientDashboardDto {
    /**
     * 
     * @type {PatientDashboardItemSummaryDto}
     * @memberof PatientDashboardDto
     */
    'tasks'?: PatientDashboardItemSummaryDto;
    /**
     * 
     * @type {PatientDashboardItemSummaryDto}
     * @memberof PatientDashboardDto
     */
    'treatments'?: PatientDashboardItemSummaryDto;
    /**
     * 
     * @type {PatientDashboardItemSummaryDto}
     * @memberof PatientDashboardDto
     */
    'medications'?: PatientDashboardItemSummaryDto;
    /**
     * 
     * @type {PatientDashboardItemSummaryDto}
     * @memberof PatientDashboardDto
     */
    'monitoring'?: PatientDashboardItemSummaryDto;
    /**
     * 
     * @type {PatientDashboardItemSummaryDto}
     * @memberof PatientDashboardDto
     */
    'happiness'?: PatientDashboardItemSummaryDto;
    /**
     * 
     * @type {PatientDashboardItemSummaryDto}
     * @memberof PatientDashboardDto
     */
    'fixedQuestions'?: PatientDashboardItemSummaryDto;
    /**
     * 
     * @type {PatientDashboardItemSummaryDto}
     * @memberof PatientDashboardDto
     */
    'freeQuestions'?: PatientDashboardItemSummaryDto;
    /**
     * 
     * @type {PatientDashboardItemSummaryDto}
     * @memberof PatientDashboardDto
     */
    'careTakersOnly'?: PatientDashboardItemSummaryDto;
}
/**
 * 
 * @export
 * @interface PatientDashboardItemSummaryDto
 */
export interface PatientDashboardItemSummaryDto {
    /**
     * 
     * @type {ScheduleItemType}
     * @memberof PatientDashboardItemSummaryDto
     */
    'type'?: ScheduleItemType;
    /**
     * 
     * @type {number}
     * @memberof PatientDashboardItemSummaryDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientDashboardItemSummaryDto
     */
    'completedCount'?: number;
}


/**
 * 
 * @export
 * @interface PatientNoteGroup
 */
export interface PatientNoteGroup {
    /**
     * 
     * @type {string}
     * @memberof PatientNoteGroup
     */
    'date'?: string;
    /**
     * 
     * @type {Array<PatientNoteResponseDto>}
     * @memberof PatientNoteGroup
     */
    'patientNotes'?: Array<PatientNoteResponseDto> | null;
}
/**
 * 
 * @export
 * @interface PatientNoteRequestDto
 */
export interface PatientNoteRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PatientNoteRequestDto
     */
    'patientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientNoteRequestDto
     */
    'ownerId'?: string;
    /**
     * 
     * @type {UserType}
     * @memberof PatientNoteRequestDto
     */
    'userType'?: UserType;
    /**
     * 
     * @type {string}
     * @memberof PatientNoteRequestDto
     */
    'note'?: string | null;
}


/**
 * 
 * @export
 * @interface PatientNoteResponseDto
 */
export interface PatientNoteResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PatientNoteResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientNoteResponseDto
     */
    'patientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientNoteResponseDto
     */
    'ownerId'?: string;
    /**
     * 
     * @type {UserType}
     * @memberof PatientNoteResponseDto
     */
    'userType'?: UserType;
    /**
     * 
     * @type {string}
     * @memberof PatientNoteResponseDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientNoteResponseDto
     */
    'createdDateTime'?: string;
    /**
     * 
     * @type {Array<NotePhotoResponseDto>}
     * @memberof PatientNoteResponseDto
     */
    'notePhotos'?: Array<NotePhotoResponseDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientNoteResponseDto
     */
    'viewed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientNoteResponseDto
     */
    'viewedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientNoteResponseDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientNoteResponseDto
     */
    'patientName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientNoteResponseDto
     */
    'isMine'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientNoteResponseDto
     */
    'ownerPhotoId'?: string | null;
}


/**
 * 
 * @export
 * @interface PatientRequestDto
 */
export interface PatientRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof PatientRequestDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDto
     */
    'diagnosis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientRequestDto
     */
    'allowedDistance'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientRequestDto
     */
    'checkAllowedDistance'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientRequestDto
     */
    'trackLocation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PatientRequestDto
     */
    'locationHistoryDays'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDto
     */
    'doctorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDto
     */
    'doctorLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDto
     */
    'doctorPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDto
     */
    'userAccountId'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof PatientRequestDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDto
     */
    'birthday'?: string | null;
    /**
     * 
     * @type {UserAccountCreateRequestDto}
     * @memberof PatientRequestDto
     */
    'userAccount'?: UserAccountCreateRequestDto;
}


/**
 * 
 * @export
 * @interface PatientResponseDto
 */
export interface PatientResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof PatientResponseDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'diagnosis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientResponseDto
     */
    'allowedDistance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponseDto
     */
    'checkAllowedDistance'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponseDto
     */
    'trackLocation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PatientResponseDto
     */
    'locationHistoryDays'?: number;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof PatientResponseDto
     */
    'userAccount'?: UserAccountDto;
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'currentEmergencyContactId'?: string;
    /**
     * 
     * @type {Gender}
     * @memberof PatientResponseDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'birthday'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'doctorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'doctorLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'doctorPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'userAccountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponseDto
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponseDto
     */
    'isPhoneConfirmed'?: boolean;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof PatientResponseDto
     */
    'organization'?: OrganizationDto;
}


/**
 * 
 * @export
 * @interface PatientResponseDtoPagedList
 */
export interface PatientResponseDtoPagedList {
    /**
     * 
     * @type {Array<PatientResponseDto>}
     * @memberof PatientResponseDtoPagedList
     */
    'items': Array<PatientResponseDto>;
    /**
     * 
     * @type {PageInfo}
     * @memberof PatientResponseDtoPagedList
     */
    'paging': PageInfo;
}
/**
 * 
 * @export
 * @interface PatientScheduleItemResultFullResponseDto
 */
export interface PatientScheduleItemResultFullResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'patientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'displayedDate'?: string;
    /**
     * 
     * @type {ScheduleItemType}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'scheduleItemType'?: ScheduleItemType;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'treatmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'itemText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'itemImageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'itemAudioId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'itemStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'itemEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'question'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'fixedAnswers'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'isAudioResponsePossible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'isForCaretakerOnly'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'textResponse'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'happinessResponse'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'isTaskDone'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'taskDateTimeDone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'medicationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'audioResponseId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'measurementTypeId'?: string | null;
    /**
     * 
     * @type {MeasurementResponseDto}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'measurement'?: MeasurementResponseDto;
    /**
     * 
     * @type {MeasurementTypeDto}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'measurementType'?: MeasurementTypeDto;
    /**
     * 
     * @type {MedicationResponseDto}
     * @memberof PatientScheduleItemResultFullResponseDto
     */
    'medication'?: MedicationResponseDto;
}


/**
 * 
 * @export
 * @interface PatientScheduleItemResultRequestDto
 */
export interface PatientScheduleItemResultRequestDto {
    /**
     * 
     * @type {MeasurementRequestDto}
     * @memberof PatientScheduleItemResultRequestDto
     */
    'measurement'?: MeasurementRequestDto;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultRequestDto
     */
    'textResponse'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientScheduleItemResultRequestDto
     */
    'happinessResponse'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemResultRequestDto
     */
    'isTaskDone'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultRequestDto
     */
    'taskDateTimeDone'?: string | null;
}
/**
 * 
 * @export
 * @interface PatientScheduleItemResultResponseDto
 */
export interface PatientScheduleItemResultResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'patientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'displayedDate'?: string;
    /**
     * 
     * @type {ScheduleItemType}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'scheduleItemType'?: ScheduleItemType;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'treatmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'itemText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'itemImageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'itemAudioId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'itemStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'itemEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'question'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'fixedAnswers'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'isAudioResponsePossible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'isForCaretakerOnly'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'textResponse'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'happinessResponse'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'isTaskDone'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'taskDateTimeDone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'medicationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'audioResponseId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'measurementTypeId'?: string | null;
    /**
     * 
     * @type {MeasurementResponseDto}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'measurement'?: MeasurementResponseDto;
    /**
     * 
     * @type {MeasurementTypeDto}
     * @memberof PatientScheduleItemResultResponseDto
     */
    'measurementType'?: MeasurementTypeDto;
}


/**
 * 
 * @export
 * @interface PatientScheduleItemTemplateRequestDto
 */
export interface PatientScheduleItemTemplateRequestDto {
    /**
     * 
     * @type {ScheduleItemType}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'scheduleItemType'?: ScheduleItemType;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'measurementTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'treatmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'itemText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'itemImageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'itemAudioId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'itemStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'itemEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'question'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'fixedAnswers'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'weekDay1ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'weekDay2ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'weekDay3ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'weekDay4ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'weekDay5ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'weekDay6ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'weekDay7ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'isAudioResponsePossible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'isForCaretakerOnly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateRequestDto
     */
    'medicationId'?: string | null;
}


/**
 * 
 * @export
 * @interface PatientScheduleItemTemplateResponseDto
 */
export interface PatientScheduleItemTemplateResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'patientId'?: string;
    /**
     * 
     * @type {ScheduleItemType}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'scheduleItemType'?: ScheduleItemType;
    /**
     * 
     * @type {MeasurementTypeDto}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'measurementType'?: MeasurementTypeDto;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'treatmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'itemText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'itemImageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'itemAudioId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'itemStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'itemEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'question'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'fixedAnswers'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'weekDay1ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'weekDay2ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'weekDay3ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'weekDay4ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'weekDay5ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'weekDay6ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'weekDay7ToRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'isAudioResponsePossible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'isForCaretakerOnly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'medicationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientScheduleItemTemplateResponseDto
     */
    'measurementTypeId'?: string | null;
}


/**
 * 
 * @export
 * @interface PatientUpdateRequestDto
 */
export interface PatientUpdateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PatientUpdateRequestDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdateRequestDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdateRequestDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof PatientUpdateRequestDto
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdateRequestDto
     */
    'diagnosis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientUpdateRequestDto
     */
    'allowedDistance'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientUpdateRequestDto
     */
    'checkAllowedDistance'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientUpdateRequestDto
     */
    'trackLocation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PatientUpdateRequestDto
     */
    'locationHistoryDays'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdateRequestDto
     */
    'doctorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdateRequestDto
     */
    'doctorLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdateRequestDto
     */
    'doctorPhone'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof PatientUpdateRequestDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdateRequestDto
     */
    'birthday'?: string | null;
    /**
     * 
     * @type {UserAccountUpdateRequestDto}
     * @memberof PatientUpdateRequestDto
     */
    'userAccount'?: UserAccountUpdateRequestDto;
}


/**
 * 
 * @export
 * @interface PushMessageAcknowledgement
 */
export interface PushMessageAcknowledgement {
    /**
     * 
     * @type {PushMessageId}
     * @memberof PushMessageAcknowledgement
     */
    'message'?: PushMessageId;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PushMessageAcknowledgement
     */
    'data'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface PushMessageId
 */
export interface PushMessageId {
    /**
     * 
     * @type {string}
     * @memberof PushMessageId
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface RelatedPatientResponseDto
 */
export interface RelatedPatientResponseDto {
    /**
     * 
     * @type {RelationType}
     * @memberof RelatedPatientResponseDto
     */
    'relation'?: RelationType;
    /**
     * 
     * @type {PatientResponseDto}
     * @memberof RelatedPatientResponseDto
     */
    'patient'?: PatientResponseDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RelationType = {
    Other: 'other',
    Mother: 'mother',
    Father: 'father',
    Grandmother: 'grandmother',
    Grandfather: 'grandfather',
    GreatGrandmother: 'great_grandmother',
    GreatGrandfather: 'great_grandfather',
    Brother: 'brother',
    Sister: 'sister',
    Daughter: 'daughter',
    Son: 'son',
    Wife: 'wife',
    Husband: 'husband'
} as const;

export type RelationType = typeof RelationType[keyof typeof RelationType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ScheduleItemType = {
    Alarm: 'Alarm',
    Medication: 'Medication',
    SpecialDayReminder: 'SpecialDayReminder',
    Task: 'Task',
    HappinessQuestion: 'HappinessQuestion',
    FixedQuestion: 'FixedQuestion',
    FreeQuestion: 'FreeQuestion',
    Treatment: 'Treatment',
    Monitoring: 'Monitoring'
} as const;

export type ScheduleItemType = typeof ScheduleItemType[keyof typeof ScheduleItemType];


/**
 * 
 * @export
 * @interface SendOtpRequest
 */
export interface SendOtpRequest {
    /**
     * 
     * @type {string}
     * @memberof SendOtpRequest
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface SetFcmTokenRequest
 */
export interface SetFcmTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof SetFcmTokenRequest
     */
    'application': string;
    /**
     * 
     * @type {string}
     * @memberof SetFcmTokenRequest
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface SubmitOtpRequest
 */
export interface SubmitOtpRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmitOtpRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOtpRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ThumbnailType = {
    Icon: 'Icon',
    Mobile: 'Mobile',
    Tablet: 'Tablet',
    Desktop: 'Desktop',
    Original: 'Original'
} as const;

export type ThumbnailType = typeof ThumbnailType[keyof typeof ThumbnailType];


/**
 * 
 * @export
 * @interface TreatmentRequestDto
 */
export interface TreatmentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof TreatmentRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TreatmentRequestDto
     */
    'instructions'?: string | null;
}
/**
 * 
 * @export
 * @interface TreatmentResponseDto
 */
export interface TreatmentResponseDto {
    /**
     * 
     * @type {string}
     * @memberof TreatmentResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TreatmentResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TreatmentResponseDto
     */
    'instructions'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TreatmentResponseDto
     */
    'cancelled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TreatmentResponseDto
     */
    'createdDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TreatmentResponseDto
     */
    'cancelledDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TreatmentResponseDto
     */
    'image'?: string | null;
}
/**
 * 
 * @export
 * @interface TreatmentTemplateRequestDto
 */
export interface TreatmentTemplateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof TreatmentTemplateRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TreatmentTemplateRequestDto
     */
    'instructions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TreatmentTemplateRequestDto
     */
    'imageMediaFileId'?: string | null;
}
/**
 * 
 * @export
 * @interface TreatmentTemplateResponseDto
 */
export interface TreatmentTemplateResponseDto {
    /**
     * 
     * @type {string}
     * @memberof TreatmentTemplateResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TreatmentTemplateResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TreatmentTemplateResponseDto
     */
    'instructions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TreatmentTemplateResponseDto
     */
    'imageMediaFileId'?: string | null;
}
/**
 * 
 * @export
 * @interface UserAccessRequest
 */
export interface UserAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAccessRequest
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface UserAccessSubmitOtpRequest
 */
export interface UserAccessSubmitOtpRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAccessSubmitOtpRequest
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccessSubmitOtpRequest
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface UserAccountCreateRequestDto
 */
export interface UserAccountCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UserAccountCreateRequestDto
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountCreateRequestDto
     */
    'emailAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface UserAccountDto
 */
export interface UserAccountDto {
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'id'?: string;
    /**
     * 
     * @type {UserAccountStatus}
     * @memberof UserAccountDto
     */
    'status'?: UserAccountStatus;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof UserAccountDto
     */
    'userType'?: UserType;
}


/**
 * 
 * @export
 * @interface UserAccountOrganizationCreateRequestDto
 */
export interface UserAccountOrganizationCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UserAccountOrganizationCreateRequestDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountOrganizationCreateRequestDto
     */
    'mobileNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserAccountStatus = {
    Pending: 'Pending',
    Active: 'Active',
    Suspended: 'Suspended',
    Blocked: 'Blocked',
    Cancelled: 'Cancelled'
} as const;

export type UserAccountStatus = typeof UserAccountStatus[keyof typeof UserAccountStatus];


/**
 * 
 * @export
 * @interface UserAccountUpdateRequestDto
 */
export interface UserAccountUpdateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UserAccountUpdateRequestDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountUpdateRequestDto
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccountUpdateRequestDto
     */
    'emailAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface UserIdTokenExtra
 */
export interface UserIdTokenExtra {
    /**
     * 
     * @type {UserAccountStatus}
     * @memberof UserIdTokenExtra
     */
    'https://aiutante.com/user_status'?: UserAccountStatus;
}


/**
 * 
 * @export
 * @interface UserTokenExtra
 */
export interface UserTokenExtra {
    /**
     * 
     * @type {UserIdTokenExtra}
     * @memberof UserTokenExtra
     */
    'idToken'?: UserIdTokenExtra;
    /**
     * 
     * @type {AccessTokenExtra}
     * @memberof UserTokenExtra
     */
    'accessToken'?: AccessTokenExtra;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserType = {
    Pending: 'Pending',
    FamilyMember: 'FamilyMember',
    Patient: 'Patient',
    Caretaker: 'Caretaker',
    Doctor: 'Doctor',
    OrganizationAdmin: 'OrganizationAdmin'
} as const;

export type UserType = typeof UserType[keyof typeof UserType];



/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ActivityGet: async (searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ActivityIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ActivityIdDelete', 'id', id)
            const localVarPath = `/api/v1/Activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ActivityRequestDto} [activityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ActivityIdPost: async (id: string, activityRequestDto?: ActivityRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ActivityIdPost', 'id', id)
            const localVarPath = `/api/v1/Activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActivityRequestDto} [activityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ActivityPut: async (activityRequestDto?: ActivityRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ActivityGet(searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ActivityGet(searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ActivityIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ActivityIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ActivityRequestDto} [activityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ActivityIdPost(id: string, activityRequestDto?: ActivityRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ActivityIdPost(id, activityRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ActivityRequestDto} [activityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ActivityPut(activityRequestDto?: ActivityRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ActivityPut(activityRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ActivityGet(searchTerm?: string, options?: any): AxiosPromise<Array<ActivityResponseDto>> {
            return localVarFp.apiV1ActivityGet(searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ActivityIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ActivityIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ActivityRequestDto} [activityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ActivityIdPost(id: string, activityRequestDto?: ActivityRequestDto, options?: any): AxiosPromise<ActivityResponseDto> {
            return localVarFp.apiV1ActivityIdPost(id, activityRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActivityRequestDto} [activityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ActivityPut(activityRequestDto?: ActivityRequestDto, options?: any): AxiosPromise<ActivityResponseDto> {
            return localVarFp.apiV1ActivityPut(activityRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public apiV1ActivityGet(searchTerm?: string, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).apiV1ActivityGet(searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public apiV1ActivityIdDelete(id: string, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).apiV1ActivityIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ActivityRequestDto} [activityRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public apiV1ActivityIdPost(id: string, activityRequestDto?: ActivityRequestDto, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).apiV1ActivityIdPost(id, activityRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityRequestDto} [activityRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public apiV1ActivityPut(activityRequestDto?: ActivityRequestDto, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).apiV1ActivityPut(activityRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangeStatusRequest} [changeStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminChangeUserAccountStatusPost: async (changeStatusRequest?: ChangeStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/ChangeUserAccountStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGetUserAccountsPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/GetUserAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangeStatusRequest} [changeStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminChangeUserAccountStatusPost(changeStatusRequest?: ChangeStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminChangeUserAccountStatusPost(changeStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminGetUserAccountsPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAccountDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminGetUserAccountsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangeStatusRequest} [changeStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminChangeUserAccountStatusPost(changeStatusRequest?: ChangeStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdminChangeUserAccountStatusPost(changeStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminGetUserAccountsPost(body?: object, options?: any): AxiosPromise<Array<UserAccountDto>> {
            return localVarFp.apiV1AdminGetUserAccountsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {ChangeStatusRequest} [changeStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiV1AdminChangeUserAccountStatusPost(changeStatusRequest?: ChangeStatusRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiV1AdminChangeUserAccountStatusPost(changeStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiV1AdminGetUserAccountsPost(body?: object, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiV1AdminGetUserAccountsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrganizationsApi - axios parameter creator
 * @export
 */
export const AdminOrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdAdminGet: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationsOrganizationIdAdminGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organizations/{organizationId}/admin`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdAdminIdDelete: async (organizationId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationsOrganizationIdAdminIdDelete', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationsOrganizationIdAdminIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/organizations/{organizationId}/admin/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdAdminIdGet: async (organizationId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationsOrganizationIdAdminIdGet', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationsOrganizationIdAdminIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/organizations/{organizationId}/admin/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {OrganizationAdminUpdateRequest} [organizationAdminUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdAdminIdPost: async (organizationId: string, id: string, organizationAdminUpdateRequest?: OrganizationAdminUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationsOrganizationIdAdminIdPost', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationsOrganizationIdAdminIdPost', 'id', id)
            const localVarPath = `/api/v1/administration/organizations/{organizationId}/admin/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationAdminUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {OrganizationAdminCreateRequest} [organizationAdminCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdAdminPut: async (organizationId: string, organizationAdminCreateRequest?: OrganizationAdminCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationsOrganizationIdAdminPut', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organizations/{organizationId}/admin`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationAdminCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdDelete: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationsOrganizationIdDelete', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdGet: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationsOrganizationIdGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {OrganizationRequestDto} [organizationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdPut: async (organizationId: string, organizationRequestDto?: OrganizationRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationsOrganizationIdPut', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganizationRequestDto} [organizationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsPost: async (organizationRequestDto?: OrganizationRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrganizationsApi - functional programming interface
 * @export
 */
export const AdminOrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationsOrganizationIdAdminGet(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationAdminDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationsOrganizationIdAdminGet(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationsOrganizationIdAdminIdDelete(organizationId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationsOrganizationIdAdminIdDelete(organizationId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationsOrganizationIdAdminIdGet(organizationId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationsOrganizationIdAdminIdGet(organizationId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {OrganizationAdminUpdateRequest} [organizationAdminUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationsOrganizationIdAdminIdPost(organizationId: string, id: string, organizationAdminUpdateRequest?: OrganizationAdminUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationsOrganizationIdAdminIdPost(organizationId, id, organizationAdminUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {OrganizationAdminCreateRequest} [organizationAdminCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationsOrganizationIdAdminPut(organizationId: string, organizationAdminCreateRequest?: OrganizationAdminCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationsOrganizationIdAdminPut(organizationId, organizationAdminCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationsOrganizationIdDelete(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationsOrganizationIdDelete(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationsOrganizationIdGet(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationsOrganizationIdGet(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {OrganizationRequestDto} [organizationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationsOrganizationIdPut(organizationId: string, organizationRequestDto?: OrganizationRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationsOrganizationIdPut(organizationId, organizationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrganizationRequestDto} [organizationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationsPost(organizationRequestDto?: OrganizationRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationsPost(organizationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrganizationsApi - factory interface
 * @export
 */
export const AdminOrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsGet(options?: any): AxiosPromise<Array<OrganizationDto>> {
            return localVarFp.apiV1AdministrationOrganizationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdAdminGet(organizationId: string, options?: any): AxiosPromise<Array<OrganizationAdminDto>> {
            return localVarFp.apiV1AdministrationOrganizationsOrganizationIdAdminGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdAdminIdDelete(organizationId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationOrganizationsOrganizationIdAdminIdDelete(organizationId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdAdminIdGet(organizationId: string, id: string, options?: any): AxiosPromise<OrganizationAdminDto> {
            return localVarFp.apiV1AdministrationOrganizationsOrganizationIdAdminIdGet(organizationId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {OrganizationAdminUpdateRequest} [organizationAdminUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdAdminIdPost(organizationId: string, id: string, organizationAdminUpdateRequest?: OrganizationAdminUpdateRequest, options?: any): AxiosPromise<OrganizationAdminDto> {
            return localVarFp.apiV1AdministrationOrganizationsOrganizationIdAdminIdPost(organizationId, id, organizationAdminUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {OrganizationAdminCreateRequest} [organizationAdminCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdAdminPut(organizationId: string, organizationAdminCreateRequest?: OrganizationAdminCreateRequest, options?: any): AxiosPromise<OrganizationAdminDto> {
            return localVarFp.apiV1AdministrationOrganizationsOrganizationIdAdminPut(organizationId, organizationAdminCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdDelete(organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationOrganizationsOrganizationIdDelete(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdGet(organizationId: string, options?: any): AxiosPromise<OrganizationDto> {
            return localVarFp.apiV1AdministrationOrganizationsOrganizationIdGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {OrganizationRequestDto} [organizationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsOrganizationIdPut(organizationId: string, organizationRequestDto?: OrganizationRequestDto, options?: any): AxiosPromise<OrganizationDto> {
            return localVarFp.apiV1AdministrationOrganizationsOrganizationIdPut(organizationId, organizationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganizationRequestDto} [organizationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationsPost(organizationRequestDto?: OrganizationRequestDto, options?: any): AxiosPromise<OrganizationDto> {
            return localVarFp.apiV1AdministrationOrganizationsPost(organizationRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrganizationsApi - object-oriented interface
 * @export
 * @class AdminOrganizationsApi
 * @extends {BaseAPI}
 */
export class AdminOrganizationsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public apiV1AdministrationOrganizationsGet(options?: AxiosRequestConfig) {
        return AdminOrganizationsApiFp(this.configuration).apiV1AdministrationOrganizationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public apiV1AdministrationOrganizationsOrganizationIdAdminGet(organizationId: string, options?: AxiosRequestConfig) {
        return AdminOrganizationsApiFp(this.configuration).apiV1AdministrationOrganizationsOrganizationIdAdminGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public apiV1AdministrationOrganizationsOrganizationIdAdminIdDelete(organizationId: string, id: string, options?: AxiosRequestConfig) {
        return AdminOrganizationsApiFp(this.configuration).apiV1AdministrationOrganizationsOrganizationIdAdminIdDelete(organizationId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public apiV1AdministrationOrganizationsOrganizationIdAdminIdGet(organizationId: string, id: string, options?: AxiosRequestConfig) {
        return AdminOrganizationsApiFp(this.configuration).apiV1AdministrationOrganizationsOrganizationIdAdminIdGet(organizationId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {OrganizationAdminUpdateRequest} [organizationAdminUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public apiV1AdministrationOrganizationsOrganizationIdAdminIdPost(organizationId: string, id: string, organizationAdminUpdateRequest?: OrganizationAdminUpdateRequest, options?: AxiosRequestConfig) {
        return AdminOrganizationsApiFp(this.configuration).apiV1AdministrationOrganizationsOrganizationIdAdminIdPost(organizationId, id, organizationAdminUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {OrganizationAdminCreateRequest} [organizationAdminCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public apiV1AdministrationOrganizationsOrganizationIdAdminPut(organizationId: string, organizationAdminCreateRequest?: OrganizationAdminCreateRequest, options?: AxiosRequestConfig) {
        return AdminOrganizationsApiFp(this.configuration).apiV1AdministrationOrganizationsOrganizationIdAdminPut(organizationId, organizationAdminCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public apiV1AdministrationOrganizationsOrganizationIdDelete(organizationId: string, options?: AxiosRequestConfig) {
        return AdminOrganizationsApiFp(this.configuration).apiV1AdministrationOrganizationsOrganizationIdDelete(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public apiV1AdministrationOrganizationsOrganizationIdGet(organizationId: string, options?: AxiosRequestConfig) {
        return AdminOrganizationsApiFp(this.configuration).apiV1AdministrationOrganizationsOrganizationIdGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {OrganizationRequestDto} [organizationRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public apiV1AdministrationOrganizationsOrganizationIdPut(organizationId: string, organizationRequestDto?: OrganizationRequestDto, options?: AxiosRequestConfig) {
        return AdminOrganizationsApiFp(this.configuration).apiV1AdministrationOrganizationsOrganizationIdPut(organizationId, organizationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganizationRequestDto} [organizationRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public apiV1AdministrationOrganizationsPost(organizationRequestDto?: OrganizationRequestDto, options?: AxiosRequestConfig) {
        return AdminOrganizationsApiFp(this.configuration).apiV1AdministrationOrganizationsPost(organizationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AudioLibraryApi - axios parameter creator
 * @export
 */
export const AudioLibraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibraryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AudioLibrary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibraryIdContentGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AudioLibraryIdContentGet', 'id', id)
            const localVarPath = `/api/v1/AudioLibrary/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibraryIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AudioLibraryIdDelete', 'id', id)
            const localVarPath = `/api/v1/AudioLibrary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibraryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AudioLibraryIdGet', 'id', id)
            const localVarPath = `/api/v1/AudioLibrary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {string} [fileName] 
         * @param {string} [duration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibraryPost: async (file: File, tags?: string, fileName?: string, duration?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1AudioLibraryPost', 'file', file)
            const localVarPath = `/api/v1/AudioLibrary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibrarySharedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/AudioLibrary/shared`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AudioLibraryApi - functional programming interface
 * @export
 */
export const AudioLibraryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AudioLibraryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AudioLibraryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AudioFileResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AudioLibraryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AudioLibraryIdContentGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AudioLibraryIdContentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AudioLibraryIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AudioLibraryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AudioLibraryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioFileResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AudioLibraryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {string} [fileName] 
         * @param {string} [duration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AudioLibraryPost(file: File, tags?: string, fileName?: string, duration?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioFileResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AudioLibraryPost(file, tags, fileName, duration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AudioLibrarySharedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AudioFileResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AudioLibrarySharedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AudioLibraryApi - factory interface
 * @export
 */
export const AudioLibraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AudioLibraryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibraryGet(options?: any): AxiosPromise<Array<AudioFileResponseDto>> {
            return localVarFp.apiV1AudioLibraryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibraryIdContentGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AudioLibraryIdContentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibraryIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AudioLibraryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibraryIdGet(id: string, options?: any): AxiosPromise<AudioFileResponseDto> {
            return localVarFp.apiV1AudioLibraryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {string} [fileName] 
         * @param {string} [duration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibraryPost(file: File, tags?: string, fileName?: string, duration?: string, options?: any): AxiosPromise<AudioFileResponseDto> {
            return localVarFp.apiV1AudioLibraryPost(file, tags, fileName, duration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AudioLibrarySharedGet(options?: any): AxiosPromise<Array<AudioFileResponseDto>> {
            return localVarFp.apiV1AudioLibrarySharedGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AudioLibraryApi - object-oriented interface
 * @export
 * @class AudioLibraryApi
 * @extends {BaseAPI}
 */
export class AudioLibraryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioLibraryApi
     */
    public apiV1AudioLibraryGet(options?: AxiosRequestConfig) {
        return AudioLibraryApiFp(this.configuration).apiV1AudioLibraryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioLibraryApi
     */
    public apiV1AudioLibraryIdContentGet(id: string, options?: AxiosRequestConfig) {
        return AudioLibraryApiFp(this.configuration).apiV1AudioLibraryIdContentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioLibraryApi
     */
    public apiV1AudioLibraryIdDelete(id: string, options?: AxiosRequestConfig) {
        return AudioLibraryApiFp(this.configuration).apiV1AudioLibraryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioLibraryApi
     */
    public apiV1AudioLibraryIdGet(id: string, options?: AxiosRequestConfig) {
        return AudioLibraryApiFp(this.configuration).apiV1AudioLibraryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {string} [tags] 
     * @param {string} [fileName] 
     * @param {string} [duration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioLibraryApi
     */
    public apiV1AudioLibraryPost(file: File, tags?: string, fileName?: string, duration?: string, options?: AxiosRequestConfig) {
        return AudioLibraryApiFp(this.configuration).apiV1AudioLibraryPost(file, tags, fileName, duration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioLibraryApi
     */
    public apiV1AudioLibrarySharedGet(options?: AxiosRequestConfig) {
        return AudioLibraryApiFp(this.configuration).apiV1AudioLibrarySharedGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiKey] 
         * @param {EnrichTokenRequest} [enrichTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthEnrichTokenPost: async (apiKey?: string, enrichTokenRequest?: EnrichTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Auth/EnrichToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (apiKey != null) {
                localVarHeaderParameter['ApiKey'] = String(apiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enrichTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiKey] 
         * @param {EnrichTokenRequest} [enrichTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AuthEnrichTokenPost(apiKey?: string, enrichTokenRequest?: EnrichTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTokenExtra>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AuthEnrichTokenPost(apiKey, enrichTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiKey] 
         * @param {EnrichTokenRequest} [enrichTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AuthEnrichTokenPost(apiKey?: string, enrichTokenRequest?: EnrichTokenRequest, options?: any): AxiosPromise<UserTokenExtra> {
            return localVarFp.apiV1AuthEnrichTokenPost(apiKey, enrichTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiKey] 
     * @param {EnrichTokenRequest} [enrichTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiV1AuthEnrichTokenPost(apiKey?: string, enrichTokenRequest?: EnrichTokenRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiV1AuthEnrichTokenPost(apiKey, enrichTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BackdoorApi - axios parameter creator
 * @export
 */
export const BackdoorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BackdoorDeletePatientTasksDelete: async (phoneNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('apiV1BackdoorDeletePatientTasksDelete', 'phoneNumber', phoneNumber)
            const localVarPath = `/api/v1/Backdoor/deletePatientTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientPhoneNumber 
         * @param {string} date 
         * @param {string} localTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BackdoorRescheduleTasksGet: async (patientPhoneNumber: string, date: string, localTime: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientPhoneNumber' is not null or undefined
            assertParamExists('apiV1BackdoorRescheduleTasksGet', 'patientPhoneNumber', patientPhoneNumber)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('apiV1BackdoorRescheduleTasksGet', 'date', date)
            // verify required parameter 'localTime' is not null or undefined
            assertParamExists('apiV1BackdoorRescheduleTasksGet', 'localTime', localTime)
            const localVarPath = `/api/v1/Backdoor/rescheduleTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (patientPhoneNumber !== undefined) {
                localVarQueryParameter['patientPhoneNumber'] = patientPhoneNumber;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (localTime !== undefined) {
                localVarQueryParameter['localTime'] = localTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackdoorApi - functional programming interface
 * @export
 */
export const BackdoorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackdoorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BackdoorDeletePatientTasksDelete(phoneNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BackdoorDeletePatientTasksDelete(phoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientPhoneNumber 
         * @param {string} date 
         * @param {string} localTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BackdoorRescheduleTasksGet(patientPhoneNumber: string, date: string, localTime: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BackdoorRescheduleTasksGet(patientPhoneNumber, date, localTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BackdoorApi - factory interface
 * @export
 */
export const BackdoorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackdoorApiFp(configuration)
    return {
        /**
         * 
         * @param {string} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BackdoorDeletePatientTasksDelete(phoneNumber: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1BackdoorDeletePatientTasksDelete(phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientPhoneNumber 
         * @param {string} date 
         * @param {string} localTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BackdoorRescheduleTasksGet(patientPhoneNumber: string, date: string, localTime: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1BackdoorRescheduleTasksGet(patientPhoneNumber, date, localTime, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackdoorApi - object-oriented interface
 * @export
 * @class BackdoorApi
 * @extends {BaseAPI}
 */
export class BackdoorApi extends BaseAPI {
    /**
     * 
     * @param {string} phoneNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackdoorApi
     */
    public apiV1BackdoorDeletePatientTasksDelete(phoneNumber: string, options?: AxiosRequestConfig) {
        return BackdoorApiFp(this.configuration).apiV1BackdoorDeletePatientTasksDelete(phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientPhoneNumber 
     * @param {string} date 
     * @param {string} localTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackdoorApi
     */
    public apiV1BackdoorRescheduleTasksGet(patientPhoneNumber: string, date: string, localTime: string, options?: AxiosRequestConfig) {
        return BackdoorApiFp(this.configuration).apiV1BackdoorRescheduleTasksGet(patientPhoneNumber, date, localTime, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CaretakersApi - axios parameter creator
 * @export
 */
export const CaretakersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} familyMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersFamilyMemberFamilyMemberIdAcceptPost: async (familyMemberId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyMemberId' is not null or undefined
            assertParamExists('apiV1CaretakersFamilyMemberFamilyMemberIdAcceptPost', 'familyMemberId', familyMemberId)
            const localVarPath = `/api/v1/Caretakers/familyMember/{familyMemberId}/accept`
                .replace(`{${"familyMemberId"}}`, encodeURIComponent(String(familyMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} familyMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersFamilyMemberFamilyMemberIdRejectPost: async (familyMemberId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyMemberId' is not null or undefined
            assertParamExists('apiV1CaretakersFamilyMemberFamilyMemberIdRejectPost', 'familyMemberId', familyMemberId)
            const localVarPath = `/api/v1/Caretakers/familyMember/{familyMemberId}/reject`
                .replace(`{${"familyMemberId"}}`, encodeURIComponent(String(familyMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersGet: async (searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Caretakers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CaretakersIdDelete', 'id', id)
            const localVarPath = `/api/v1/Caretakers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CaretakersIdGet', 'id', id)
            const localVarPath = `/api/v1/Caretakers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersIdPhotoGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CaretakersIdPhotoGet', 'id', id)
            const localVarPath = `/api/v1/Caretakers/{id}/photo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersIdPhotoPut: async (id: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CaretakersIdPhotoPut', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1CaretakersIdPhotoPut', 'file', file)
            const localVarPath = `/api/v1/Caretakers/{id}/photo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CaretakerUpdateRequestDto} [caretakerUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersIdPost: async (id: string, caretakerUpdateRequestDto?: CaretakerUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CaretakersIdPost', 'id', id)
            const localVarPath = `/api/v1/Caretakers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caretakerUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersMyFamilyMemberRequestsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Caretakers/my/familyMemberRequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersMyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Caretakers/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersPatientGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Caretakers/patient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersPatientPatientIdSetCurrentPost: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1CaretakersPatientPatientIdSetCurrentPost', 'patientId', patientId)
            const localVarPath = `/api/v1/Caretakers/patient/{patientId}/setCurrent`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersPut: async (caretakerCreateRequestDto?: CaretakerCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Caretakers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caretakerCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaretakersApi - functional programming interface
 * @export
 */
export const CaretakersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaretakersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} familyMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersFamilyMemberFamilyMemberIdAcceptPost(familyMemberId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersFamilyMemberFamilyMemberIdAcceptPost(familyMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} familyMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersFamilyMemberFamilyMemberIdRejectPost(familyMemberId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersFamilyMemberFamilyMemberIdRejectPost(familyMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersGet(searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaretakerListResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersGet(searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersIdPhotoGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersIdPhotoGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersIdPhotoPut(id: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersIdPhotoPut(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CaretakerUpdateRequestDto} [caretakerUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersIdPost(id: string, caretakerUpdateRequestDto?: CaretakerUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersIdPost(id, caretakerUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersMyFamilyMemberRequestsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FamilyMemberCaretakerRequestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersMyFamilyMemberRequestsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersMyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersMyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersPatientGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersPatientGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersPatientPatientIdSetCurrentPost(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersPatientPatientIdSetCurrentPost(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CaretakersPut(caretakerCreateRequestDto?: CaretakerCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CaretakersPut(caretakerCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaretakersApi - factory interface
 * @export
 */
export const CaretakersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaretakersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} familyMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersFamilyMemberFamilyMemberIdAcceptPost(familyMemberId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1CaretakersFamilyMemberFamilyMemberIdAcceptPost(familyMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} familyMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersFamilyMemberFamilyMemberIdRejectPost(familyMemberId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1CaretakersFamilyMemberFamilyMemberIdRejectPost(familyMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersGet(searchTerm?: string, options?: any): AxiosPromise<Array<CaretakerListResponseDto>> {
            return localVarFp.apiV1CaretakersGet(searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1CaretakersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersIdGet(id: string, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1CaretakersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersIdPhotoGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1CaretakersIdPhotoGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersIdPhotoPut(id: string, file: File, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1CaretakersIdPhotoPut(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CaretakerUpdateRequestDto} [caretakerUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersIdPost(id: string, caretakerUpdateRequestDto?: CaretakerUpdateRequestDto, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1CaretakersIdPost(id, caretakerUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersMyFamilyMemberRequestsGet(options?: any): AxiosPromise<Array<FamilyMemberCaretakerRequestDto>> {
            return localVarFp.apiV1CaretakersMyFamilyMemberRequestsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersMyGet(options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1CaretakersMyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersPatientGet(options?: any): AxiosPromise<Array<PatientResponseDto>> {
            return localVarFp.apiV1CaretakersPatientGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersPatientPatientIdSetCurrentPost(patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1CaretakersPatientPatientIdSetCurrentPost(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CaretakersPut(caretakerCreateRequestDto?: CaretakerCreateRequestDto, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1CaretakersPut(caretakerCreateRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaretakersApi - object-oriented interface
 * @export
 * @class CaretakersApi
 * @extends {BaseAPI}
 */
export class CaretakersApi extends BaseAPI {
    /**
     * 
     * @param {string} familyMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersFamilyMemberFamilyMemberIdAcceptPost(familyMemberId: string, options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersFamilyMemberFamilyMemberIdAcceptPost(familyMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} familyMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersFamilyMemberFamilyMemberIdRejectPost(familyMemberId: string, options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersFamilyMemberFamilyMemberIdRejectPost(familyMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersGet(searchTerm?: string, options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersGet(searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersIdDelete(id: string, options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersIdGet(id: string, options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersIdPhotoGet(id: string, options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersIdPhotoGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersIdPhotoPut(id: string, file: File, options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersIdPhotoPut(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CaretakerUpdateRequestDto} [caretakerUpdateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersIdPost(id: string, caretakerUpdateRequestDto?: CaretakerUpdateRequestDto, options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersIdPost(id, caretakerUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersMyFamilyMemberRequestsGet(options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersMyFamilyMemberRequestsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersMyGet(options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersMyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersPatientGet(options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersPatientGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersPatientPatientIdSetCurrentPost(patientId: string, options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersPatientPatientIdSetCurrentPost(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaretakersApi
     */
    public apiV1CaretakersPut(caretakerCreateRequestDto?: CaretakerCreateRequestDto, options?: AxiosRequestConfig) {
        return CaretakersApiFp(this.configuration).apiV1CaretakersPut(caretakerCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountriesApi - axios parameter creator
 * @export
 */
export const CountriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CountriesAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Countries/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CountriesSearchGet: async (searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Countries/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CountriesThumbnailIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CountriesThumbnailIdGet', 'id', id)
            const localVarPath = `/api/v1/Countries/thumbnail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesApi - functional programming interface
 * @export
 */
export const CountriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CountriesAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CountriesAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CountriesSearchGet(searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CountriesSearchGet(searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CountriesThumbnailIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CountriesThumbnailIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountriesApi - factory interface
 * @export
 */
export const CountriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CountriesAllGet(options?: any): AxiosPromise<Array<CountryDto>> {
            return localVarFp.apiV1CountriesAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CountriesSearchGet(searchTerm?: string, options?: any): AxiosPromise<Array<CountryDto>> {
            return localVarFp.apiV1CountriesSearchGet(searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CountriesThumbnailIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1CountriesThumbnailIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountriesApi - object-oriented interface
 * @export
 * @class CountriesApi
 * @extends {BaseAPI}
 */
export class CountriesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public apiV1CountriesAllGet(options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).apiV1CountriesAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public apiV1CountriesSearchGet(searchTerm?: string, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).apiV1CountriesSearchGet(searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public apiV1CountriesThumbnailIdGet(id: string, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).apiV1CountriesThumbnailIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DoctorPatientsApi - axios parameter creator
 * @export
 */
export const DoctorPatientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsCurrentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/doctor/my/patients/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ObjectIdDto} [objectIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsCurrentPut: async (objectIdDto?: ObjectIdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/doctor/my/patients/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ObjectIdDto} [objectIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsDelete: async (objectIdDto?: ObjectIdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/doctor/my/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/doctor/my/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ObjectIdDto} [objectIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsPost: async (objectIdDto?: ObjectIdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/doctor/my/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsRecentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/doctor/my/patients/recent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoctorPatientsApi - functional programming interface
 * @export
 */
export const DoctorPatientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoctorPatientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DoctorMyPatientsCurrentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DoctorMyPatientsCurrentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ObjectIdDto} [objectIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DoctorMyPatientsCurrentPut(objectIdDto?: ObjectIdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DoctorMyPatientsCurrentPut(objectIdDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ObjectIdDto} [objectIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DoctorMyPatientsDelete(objectIdDto?: ObjectIdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DoctorMyPatientsDelete(objectIdDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DoctorMyPatientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DoctorMyPatientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ObjectIdDto} [objectIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DoctorMyPatientsPost(objectIdDto?: ObjectIdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DoctorMyPatientsPost(objectIdDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DoctorMyPatientsRecentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DoctorMyPatientsRecentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoctorPatientsApi - factory interface
 * @export
 */
export const DoctorPatientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoctorPatientsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsCurrentGet(options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1DoctorMyPatientsCurrentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ObjectIdDto} [objectIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsCurrentPut(objectIdDto?: ObjectIdDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1DoctorMyPatientsCurrentPut(objectIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ObjectIdDto} [objectIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsDelete(objectIdDto?: ObjectIdDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1DoctorMyPatientsDelete(objectIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsGet(options?: any): AxiosPromise<Array<PatientResponseDto>> {
            return localVarFp.apiV1DoctorMyPatientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ObjectIdDto} [objectIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsPost(objectIdDto?: ObjectIdDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1DoctorMyPatientsPost(objectIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPatientsRecentGet(options?: any): AxiosPromise<Array<PatientResponseDto>> {
            return localVarFp.apiV1DoctorMyPatientsRecentGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoctorPatientsApi - object-oriented interface
 * @export
 * @class DoctorPatientsApi
 * @extends {BaseAPI}
 */
export class DoctorPatientsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPatientsApi
     */
    public apiV1DoctorMyPatientsCurrentGet(options?: AxiosRequestConfig) {
        return DoctorPatientsApiFp(this.configuration).apiV1DoctorMyPatientsCurrentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ObjectIdDto} [objectIdDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPatientsApi
     */
    public apiV1DoctorMyPatientsCurrentPut(objectIdDto?: ObjectIdDto, options?: AxiosRequestConfig) {
        return DoctorPatientsApiFp(this.configuration).apiV1DoctorMyPatientsCurrentPut(objectIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ObjectIdDto} [objectIdDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPatientsApi
     */
    public apiV1DoctorMyPatientsDelete(objectIdDto?: ObjectIdDto, options?: AxiosRequestConfig) {
        return DoctorPatientsApiFp(this.configuration).apiV1DoctorMyPatientsDelete(objectIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPatientsApi
     */
    public apiV1DoctorMyPatientsGet(options?: AxiosRequestConfig) {
        return DoctorPatientsApiFp(this.configuration).apiV1DoctorMyPatientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ObjectIdDto} [objectIdDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPatientsApi
     */
    public apiV1DoctorMyPatientsPost(objectIdDto?: ObjectIdDto, options?: AxiosRequestConfig) {
        return DoctorPatientsApiFp(this.configuration).apiV1DoctorMyPatientsPost(objectIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPatientsApi
     */
    public apiV1DoctorMyPatientsRecentGet(options?: AxiosRequestConfig) {
        return DoctorPatientsApiFp(this.configuration).apiV1DoctorMyPatientsRecentGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DoctorsApi - axios parameter creator
 * @export
 */
export const DoctorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorByUserAccountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/doctor/byUserAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DoctorUpdateRequestDto} [doctorUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPut: async (doctorUpdateRequestDto?: DoctorUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/doctor/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DoctorRequestDto} [doctorRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorPost: async (doctorRequestDto?: DoctorRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/doctor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoctorsApi - functional programming interface
 * @export
 */
export const DoctorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoctorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DoctorByUserAccountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DoctorByUserAccountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DoctorUpdateRequestDto} [doctorUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DoctorMyPut(doctorUpdateRequestDto?: DoctorUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DoctorMyPut(doctorUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DoctorRequestDto} [doctorRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DoctorPost(doctorRequestDto?: DoctorRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DoctorPost(doctorRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoctorsApi - factory interface
 * @export
 */
export const DoctorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoctorsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorByUserAccountGet(options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1DoctorByUserAccountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DoctorUpdateRequestDto} [doctorUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorMyPut(doctorUpdateRequestDto?: DoctorUpdateRequestDto, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1DoctorMyPut(doctorUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DoctorRequestDto} [doctorRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DoctorPost(doctorRequestDto?: DoctorRequestDto, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1DoctorPost(doctorRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoctorsApi - object-oriented interface
 * @export
 * @class DoctorsApi
 * @extends {BaseAPI}
 */
export class DoctorsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsApi
     */
    public apiV1DoctorByUserAccountGet(options?: AxiosRequestConfig) {
        return DoctorsApiFp(this.configuration).apiV1DoctorByUserAccountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DoctorUpdateRequestDto} [doctorUpdateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsApi
     */
    public apiV1DoctorMyPut(doctorUpdateRequestDto?: DoctorUpdateRequestDto, options?: AxiosRequestConfig) {
        return DoctorsApiFp(this.configuration).apiV1DoctorMyPut(doctorUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DoctorRequestDto} [doctorRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsApi
     */
    public apiV1DoctorPost(doctorRequestDto?: DoctorRequestDto, options?: AxiosRequestConfig) {
        return DoctorsApiFp(this.configuration).apiV1DoctorPost(doctorRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ThumbnailType} thumbnailType 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdThumbnailThumbnailTypeGet: async (thumbnailType: ThumbnailType, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thumbnailType' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdThumbnailThumbnailTypeGet', 'thumbnailType', thumbnailType)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdThumbnailThumbnailTypeGet', 'documentId', documentId)
            const localVarPath = `/api/v1/Documents/{documentId}/thumbnail/{thumbnailType}`
                .replace(`{${"thumbnailType"}}`, encodeURIComponent(String(thumbnailType)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDownLoadDocumentGet: async (documentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Documents/downLoadDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [owner] 
         * @param {DocumentType} [documentType] 
         * @param {FormatType} [formatType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsGet: async (searchTerm?: string, owner?: string, documentType?: DocumentType, formatType?: FormatType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (formatType !== undefined) {
                localVarQueryParameter['formatType'] = formatType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdBodyPost: async (id: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DocumentsIdBodyPost', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1DocumentsIdBodyPost', 'file', file)
            const localVarPath = `/api/v1/Documents/{id}/body`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DocumentsIdDelete', 'id', id)
            const localVarPath = `/api/v1/Documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DocumentsIdGet', 'id', id)
            const localVarPath = `/api/v1/Documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentUpdateDto} [documentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsPost: async (documentUpdateDto?: DocumentUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ThumbnailType} thumbnailType 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1DocumentsThumbnailGet: async (thumbnailType: ThumbnailType, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thumbnailType' is not null or undefined
            assertParamExists('apiV1DocumentsThumbnailGet', 'thumbnailType', thumbnailType)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiV1DocumentsThumbnailGet', 'documentId', documentId)
            const localVarPath = `/api/v1/Documents/thumbnail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (thumbnailType !== undefined) {
                localVarQueryParameter['thumbnailType'] = thumbnailType;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ownerId 
         * @param {DocumentType} documentType 
         * @param {FormatType} formatType 
         * @param {string} description 
         * @param {string} name 
         * @param {string} mimeType 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsUploadDocumentPost: async (ownerId: string, documentType: DocumentType, formatType: FormatType, description: string, name: string, mimeType: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerId' is not null or undefined
            assertParamExists('apiV1DocumentsUploadDocumentPost', 'ownerId', ownerId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('apiV1DocumentsUploadDocumentPost', 'documentType', documentType)
            // verify required parameter 'formatType' is not null or undefined
            assertParamExists('apiV1DocumentsUploadDocumentPost', 'formatType', formatType)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('apiV1DocumentsUploadDocumentPost', 'description', description)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiV1DocumentsUploadDocumentPost', 'name', name)
            // verify required parameter 'mimeType' is not null or undefined
            assertParamExists('apiV1DocumentsUploadDocumentPost', 'mimeType', mimeType)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1DocumentsUploadDocumentPost', 'file', file)
            const localVarPath = `/api/v1/Documents/uploadDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (formatType !== undefined) {
                localVarQueryParameter['formatType'] = formatType;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (mimeType !== undefined) {
                localVarQueryParameter['mimeType'] = mimeType;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ThumbnailType} thumbnailType 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsDocumentIdThumbnailThumbnailTypeGet(thumbnailType: ThumbnailType, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsDocumentIdThumbnailThumbnailTypeGet(thumbnailType, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsDownLoadDocumentGet(documentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsDownLoadDocumentGet(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [owner] 
         * @param {DocumentType} [documentType] 
         * @param {FormatType} [formatType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsGet(searchTerm?: string, owner?: string, documentType?: DocumentType, formatType?: FormatType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsGet(searchTerm, owner, documentType, formatType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsIdBodyPost(id: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsIdBodyPost(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DocumentUpdateDto} [documentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsPost(documentUpdateDto?: DocumentUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsPost(documentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ThumbnailType} thumbnailType 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1DocumentsThumbnailGet(thumbnailType: ThumbnailType, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsThumbnailGet(thumbnailType, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ownerId 
         * @param {DocumentType} documentType 
         * @param {FormatType} formatType 
         * @param {string} description 
         * @param {string} name 
         * @param {string} mimeType 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsUploadDocumentPost(ownerId: string, documentType: DocumentType, formatType: FormatType, description: string, name: string, mimeType: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsUploadDocumentPost(ownerId, documentType, formatType, description, name, mimeType, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {ThumbnailType} thumbnailType 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdThumbnailThumbnailTypeGet(thumbnailType: ThumbnailType, documentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1DocumentsDocumentIdThumbnailThumbnailTypeGet(thumbnailType, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDownLoadDocumentGet(documentId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1DocumentsDownLoadDocumentGet(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [owner] 
         * @param {DocumentType} [documentType] 
         * @param {FormatType} [formatType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsGet(searchTerm?: string, owner?: string, documentType?: DocumentType, formatType?: FormatType, options?: any): AxiosPromise<Array<DocumentResponseDto>> {
            return localVarFp.apiV1DocumentsGet(searchTerm, owner, documentType, formatType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdBodyPost(id: string, file: File, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1DocumentsIdBodyPost(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1DocumentsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdGet(id: string, options?: any): AxiosPromise<DocumentResponseDto> {
            return localVarFp.apiV1DocumentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentUpdateDto} [documentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsPost(documentUpdateDto?: DocumentUpdateDto, options?: any): AxiosPromise<DocumentResponseDto> {
            return localVarFp.apiV1DocumentsPost(documentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ThumbnailType} thumbnailType 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1DocumentsThumbnailGet(thumbnailType: ThumbnailType, documentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1DocumentsThumbnailGet(thumbnailType, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ownerId 
         * @param {DocumentType} documentType 
         * @param {FormatType} formatType 
         * @param {string} description 
         * @param {string} name 
         * @param {string} mimeType 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsUploadDocumentPost(ownerId: string, documentType: DocumentType, formatType: FormatType, description: string, name: string, mimeType: string, file: File, options?: any): AxiosPromise<DocumentResponseDto> {
            return localVarFp.apiV1DocumentsUploadDocumentPost(ownerId, documentType, formatType, description, name, mimeType, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @param {ThumbnailType} thumbnailType 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsDocumentIdThumbnailThumbnailTypeGet(thumbnailType: ThumbnailType, documentId: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsDocumentIdThumbnailThumbnailTypeGet(thumbnailType, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [documentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsDownLoadDocumentGet(documentId?: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsDownLoadDocumentGet(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {string} [owner] 
     * @param {DocumentType} [documentType] 
     * @param {FormatType} [formatType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsGet(searchTerm?: string, owner?: string, documentType?: DocumentType, formatType?: FormatType, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsGet(searchTerm, owner, documentType, formatType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsIdBodyPost(id: string, file: File, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsIdBodyPost(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsIdDelete(id: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsIdGet(id: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentUpdateDto} [documentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsPost(documentUpdateDto?: DocumentUpdateDto, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsPost(documentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ThumbnailType} thumbnailType 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsThumbnailGet(thumbnailType: ThumbnailType, documentId: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsThumbnailGet(thumbnailType, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ownerId 
     * @param {DocumentType} documentType 
     * @param {FormatType} formatType 
     * @param {string} description 
     * @param {string} name 
     * @param {string} mimeType 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsUploadDocumentPost(ownerId: string, documentType: DocumentType, formatType: FormatType, description: string, name: string, mimeType: string, file: File, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsUploadDocumentPost(ownerId, documentType, formatType, description, name, mimeType, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmergencyContactsApi - axios parameter creator
 * @export
 */
export const EmergencyContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EmergencyContactsGet: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1EmergencyContactsGet', 'patientId', patientId)
            const localVarPath = `/api/v1/EmergencyContacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EmergencyContactsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1EmergencyContactsIdDelete', 'id', id)
            const localVarPath = `/api/v1/EmergencyContacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {EmergencyContactRequestDto} [emergencyContactRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EmergencyContactsIdPost: async (id: string, emergencyContactRequestDto?: EmergencyContactRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1EmergencyContactsIdPost', 'id', id)
            const localVarPath = `/api/v1/EmergencyContacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emergencyContactRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmergencyContactRequestDto} [emergencyContactRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EmergencyContactsPut: async (emergencyContactRequestDto?: EmergencyContactRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/EmergencyContacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emergencyContactRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmergencyContactsApi - functional programming interface
 * @export
 */
export const EmergencyContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmergencyContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EmergencyContactsGet(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmergencyContactResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EmergencyContactsGet(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EmergencyContactsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EmergencyContactsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {EmergencyContactRequestDto} [emergencyContactRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EmergencyContactsIdPost(id: string, emergencyContactRequestDto?: EmergencyContactRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmergencyContactResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EmergencyContactsIdPost(id, emergencyContactRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmergencyContactRequestDto} [emergencyContactRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EmergencyContactsPut(emergencyContactRequestDto?: EmergencyContactRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmergencyContactResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EmergencyContactsPut(emergencyContactRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmergencyContactsApi - factory interface
 * @export
 */
export const EmergencyContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmergencyContactsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EmergencyContactsGet(patientId: string, options?: any): AxiosPromise<Array<EmergencyContactResponseDto>> {
            return localVarFp.apiV1EmergencyContactsGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EmergencyContactsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1EmergencyContactsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {EmergencyContactRequestDto} [emergencyContactRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EmergencyContactsIdPost(id: string, emergencyContactRequestDto?: EmergencyContactRequestDto, options?: any): AxiosPromise<EmergencyContactResponseDto> {
            return localVarFp.apiV1EmergencyContactsIdPost(id, emergencyContactRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmergencyContactRequestDto} [emergencyContactRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EmergencyContactsPut(emergencyContactRequestDto?: EmergencyContactRequestDto, options?: any): AxiosPromise<EmergencyContactResponseDto> {
            return localVarFp.apiV1EmergencyContactsPut(emergencyContactRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmergencyContactsApi - object-oriented interface
 * @export
 * @class EmergencyContactsApi
 * @extends {BaseAPI}
 */
export class EmergencyContactsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyContactsApi
     */
    public apiV1EmergencyContactsGet(patientId: string, options?: AxiosRequestConfig) {
        return EmergencyContactsApiFp(this.configuration).apiV1EmergencyContactsGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyContactsApi
     */
    public apiV1EmergencyContactsIdDelete(id: string, options?: AxiosRequestConfig) {
        return EmergencyContactsApiFp(this.configuration).apiV1EmergencyContactsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {EmergencyContactRequestDto} [emergencyContactRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyContactsApi
     */
    public apiV1EmergencyContactsIdPost(id: string, emergencyContactRequestDto?: EmergencyContactRequestDto, options?: AxiosRequestConfig) {
        return EmergencyContactsApiFp(this.configuration).apiV1EmergencyContactsIdPost(id, emergencyContactRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmergencyContactRequestDto} [emergencyContactRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyContactsApi
     */
    public apiV1EmergencyContactsPut(emergencyContactRequestDto?: EmergencyContactRequestDto, options?: AxiosRequestConfig) {
        return EmergencyContactsApiFp(this.configuration).apiV1EmergencyContactsPut(emergencyContactRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FamilyMembersApi - axios parameter creator
 * @export
 */
export const FamilyMembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersByIdGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/FamilyMembers/byId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersByUserAccountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/FamilyMembers/byUserAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/FamilyMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FamilyMemberRequestDto} [familyMemberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersPost: async (familyMemberRequestDto?: FamilyMemberRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/FamilyMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(familyMemberRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FamilyMemberCreateRequestDto} [familyMemberCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersPut: async (familyMemberCreateRequestDto?: FamilyMemberCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/FamilyMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(familyMemberCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersSetCurrentPatientPost: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1FamilyMembersSetCurrentPatientPost', 'patientId', patientId)
            const localVarPath = `/api/v1/FamilyMembers/setCurrentPatient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FamilyMembersApi - functional programming interface
 * @export
 */
export const FamilyMembersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FamilyMembersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FamilyMembersByIdGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FamilyMemberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FamilyMembersByIdGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FamilyMembersByUserAccountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FamilyMemberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FamilyMembersByUserAccountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FamilyMembersDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FamilyMembersDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FamilyMemberRequestDto} [familyMemberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FamilyMembersPost(familyMemberRequestDto?: FamilyMemberRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FamilyMemberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FamilyMembersPost(familyMemberRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FamilyMemberCreateRequestDto} [familyMemberCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FamilyMembersPut(familyMemberCreateRequestDto?: FamilyMemberCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FamilyMemberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FamilyMembersPut(familyMemberCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FamilyMembersSetCurrentPatientPost(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FamilyMembersSetCurrentPatientPost(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FamilyMembersApi - factory interface
 * @export
 */
export const FamilyMembersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FamilyMembersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersByIdGet(options?: any): AxiosPromise<FamilyMemberResponseDto> {
            return localVarFp.apiV1FamilyMembersByIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersByUserAccountGet(options?: any): AxiosPromise<FamilyMemberResponseDto> {
            return localVarFp.apiV1FamilyMembersByUserAccountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersDelete(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1FamilyMembersDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FamilyMemberRequestDto} [familyMemberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersPost(familyMemberRequestDto?: FamilyMemberRequestDto, options?: any): AxiosPromise<FamilyMemberResponseDto> {
            return localVarFp.apiV1FamilyMembersPost(familyMemberRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FamilyMemberCreateRequestDto} [familyMemberCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersPut(familyMemberCreateRequestDto?: FamilyMemberCreateRequestDto, options?: any): AxiosPromise<FamilyMemberResponseDto> {
            return localVarFp.apiV1FamilyMembersPut(familyMemberCreateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FamilyMembersSetCurrentPatientPost(patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1FamilyMembersSetCurrentPatientPost(patientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FamilyMembersApi - object-oriented interface
 * @export
 * @class FamilyMembersApi
 * @extends {BaseAPI}
 */
export class FamilyMembersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyMembersApi
     */
    public apiV1FamilyMembersByIdGet(options?: AxiosRequestConfig) {
        return FamilyMembersApiFp(this.configuration).apiV1FamilyMembersByIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyMembersApi
     */
    public apiV1FamilyMembersByUserAccountGet(options?: AxiosRequestConfig) {
        return FamilyMembersApiFp(this.configuration).apiV1FamilyMembersByUserAccountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyMembersApi
     */
    public apiV1FamilyMembersDelete(options?: AxiosRequestConfig) {
        return FamilyMembersApiFp(this.configuration).apiV1FamilyMembersDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FamilyMemberRequestDto} [familyMemberRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyMembersApi
     */
    public apiV1FamilyMembersPost(familyMemberRequestDto?: FamilyMemberRequestDto, options?: AxiosRequestConfig) {
        return FamilyMembersApiFp(this.configuration).apiV1FamilyMembersPost(familyMemberRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FamilyMemberCreateRequestDto} [familyMemberCreateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyMembersApi
     */
    public apiV1FamilyMembersPut(familyMemberCreateRequestDto?: FamilyMemberCreateRequestDto, options?: AxiosRequestConfig) {
        return FamilyMembersApiFp(this.configuration).apiV1FamilyMembersPut(familyMemberCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyMembersApi
     */
    public apiV1FamilyMembersSetCurrentPatientPost(patientId: string, options?: AxiosRequestConfig) {
        return FamilyMembersApiFp(this.configuration).apiV1FamilyMembersSetCurrentPatientPost(patientId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalAudioLibraryApi - axios parameter creator
 * @export
 */
export const GlobalAudioLibraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAudioLibraryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/audioLibrary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAudioLibraryIdContentGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationAudioLibraryIdContentGet', 'id', id)
            const localVarPath = `/api/v1/administration/audioLibrary/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAudioLibraryIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationAudioLibraryIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/audioLibrary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAudioLibraryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationAudioLibraryIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/audioLibrary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAudioLibraryPost: async (file: File, tags?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1AdministrationAudioLibraryPost', 'file', file)
            const localVarPath = `/api/v1/administration/audioLibrary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalAudioLibraryApi - functional programming interface
 * @export
 */
export const GlobalAudioLibraryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalAudioLibraryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationAudioLibraryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AudioFileResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationAudioLibraryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationAudioLibraryIdContentGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationAudioLibraryIdContentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationAudioLibraryIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationAudioLibraryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationAudioLibraryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioFileResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationAudioLibraryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationAudioLibraryPost(file: File, tags?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioFileResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationAudioLibraryPost(file, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalAudioLibraryApi - factory interface
 * @export
 */
export const GlobalAudioLibraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalAudioLibraryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAudioLibraryGet(options?: any): AxiosPromise<Array<AudioFileResponseDto>> {
            return localVarFp.apiV1AdministrationAudioLibraryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAudioLibraryIdContentGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationAudioLibraryIdContentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAudioLibraryIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationAudioLibraryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAudioLibraryIdGet(id: string, options?: any): AxiosPromise<AudioFileResponseDto> {
            return localVarFp.apiV1AdministrationAudioLibraryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAudioLibraryPost(file: File, tags?: string, options?: any): AxiosPromise<AudioFileResponseDto> {
            return localVarFp.apiV1AdministrationAudioLibraryPost(file, tags, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalAudioLibraryApi - object-oriented interface
 * @export
 * @class GlobalAudioLibraryApi
 * @extends {BaseAPI}
 */
export class GlobalAudioLibraryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAudioLibraryApi
     */
    public apiV1AdministrationAudioLibraryGet(options?: AxiosRequestConfig) {
        return GlobalAudioLibraryApiFp(this.configuration).apiV1AdministrationAudioLibraryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAudioLibraryApi
     */
    public apiV1AdministrationAudioLibraryIdContentGet(id: string, options?: AxiosRequestConfig) {
        return GlobalAudioLibraryApiFp(this.configuration).apiV1AdministrationAudioLibraryIdContentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAudioLibraryApi
     */
    public apiV1AdministrationAudioLibraryIdDelete(id: string, options?: AxiosRequestConfig) {
        return GlobalAudioLibraryApiFp(this.configuration).apiV1AdministrationAudioLibraryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAudioLibraryApi
     */
    public apiV1AdministrationAudioLibraryIdGet(id: string, options?: AxiosRequestConfig) {
        return GlobalAudioLibraryApiFp(this.configuration).apiV1AdministrationAudioLibraryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {string} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAudioLibraryApi
     */
    public apiV1AdministrationAudioLibraryPost(file: File, tags?: string, options?: AxiosRequestConfig) {
        return GlobalAudioLibraryApiFp(this.configuration).apiV1AdministrationAudioLibraryPost(file, tags, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalAuthorizeApi - axios parameter creator
 * @export
 */
export const GlobalAuthorizeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAuthorizationConfirmAccessPost: async (userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/authorization/confirmAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccessSubmitOtpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [organizationId] 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAuthorizationConfirmNewAccountPost: async (organizationId?: string, userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/authorization/confirmNewAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccessSubmitOtpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [simulate] 
         * @param {UserAccessRequest} [userAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAuthorizationRequestAccessPost: async (simulate?: boolean, userAccessRequest?: UserAccessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/authorization/requestAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (simulate !== undefined) {
                localVarQueryParameter['simulate'] = simulate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalAuthorizeApi - functional programming interface
 * @export
 */
export const GlobalAuthorizeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalAuthorizeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationAuthorizationConfirmAccessPost(userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationAuthorizationConfirmAccessPost(userAccessSubmitOtpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [organizationId] 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationAuthorizationConfirmNewAccountPost(organizationId?: string, userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationAuthorizationConfirmNewAccountPost(organizationId, userAccessSubmitOtpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [simulate] 
         * @param {UserAccessRequest} [userAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationAuthorizationRequestAccessPost(simulate?: boolean, userAccessRequest?: UserAccessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationAuthorizationRequestAccessPost(simulate, userAccessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalAuthorizeApi - factory interface
 * @export
 */
export const GlobalAuthorizeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalAuthorizeApiFp(configuration)
    return {
        /**
         * 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAuthorizationConfirmAccessPost(userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationAuthorizationConfirmAccessPost(userAccessSubmitOtpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [organizationId] 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAuthorizationConfirmNewAccountPost(organizationId?: string, userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationAuthorizationConfirmNewAccountPost(organizationId, userAccessSubmitOtpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [simulate] 
         * @param {UserAccessRequest} [userAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationAuthorizationRequestAccessPost(simulate?: boolean, userAccessRequest?: UserAccessRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationAuthorizationRequestAccessPost(simulate, userAccessRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalAuthorizeApi - object-oriented interface
 * @export
 * @class GlobalAuthorizeApi
 * @extends {BaseAPI}
 */
export class GlobalAuthorizeApi extends BaseAPI {
    /**
     * 
     * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAuthorizeApi
     */
    public apiV1AdministrationAuthorizationConfirmAccessPost(userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: AxiosRequestConfig) {
        return GlobalAuthorizeApiFp(this.configuration).apiV1AdministrationAuthorizationConfirmAccessPost(userAccessSubmitOtpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [organizationId] 
     * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAuthorizeApi
     */
    public apiV1AdministrationAuthorizationConfirmNewAccountPost(organizationId?: string, userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: AxiosRequestConfig) {
        return GlobalAuthorizeApiFp(this.configuration).apiV1AdministrationAuthorizationConfirmNewAccountPost(organizationId, userAccessSubmitOtpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [simulate] 
     * @param {UserAccessRequest} [userAccessRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAuthorizeApi
     */
    public apiV1AdministrationAuthorizationRequestAccessPost(simulate?: boolean, userAccessRequest?: UserAccessRequest, options?: AxiosRequestConfig) {
        return GlobalAuthorizeApiFp(this.configuration).apiV1AdministrationAuthorizationRequestAccessPost(simulate, userAccessRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalCaretakerApi - axios parameter creator
 * @export
 */
export const GlobalCaretakerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgCaretakersGet: async (pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/noOrg/caretakers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgCaretakersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgCaretakersIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/caretakers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgCaretakersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgCaretakersIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/caretakers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CaretakerUpdateRequestDto} [caretakerUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgCaretakersIdPost: async (id: string, caretakerUpdateRequestDto?: CaretakerUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgCaretakersIdPost', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/caretakers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caretakerUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersAvailableRawPhoneNumberGet: async (organizationId: string, rawPhoneNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersAvailableRawPhoneNumberGet', 'organizationId', organizationId)
            // verify required parameter 'rawPhoneNumber' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersAvailableRawPhoneNumberGet', 'rawPhoneNumber', rawPhoneNumber)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/caretakers/available/{rawPhoneNumber}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"rawPhoneNumber"}}`, encodeURIComponent(String(rawPhoneNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersGet: async (organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/caretakers`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersIdAttachPost: async (organizationId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersIdAttachPost', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersIdAttachPost', 'id', id)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/caretakers/{id}/attach`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersIdDelete: async (organizationId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersIdDelete', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/caretakers/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersIdDetachPost: async (organizationId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersIdDetachPost', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersIdDetachPost', 'id', id)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/caretakers/{id}/detach`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersIdGet: async (organizationId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersIdGet', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/caretakers/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersPost: async (organizationId: string, caretakerCreateRequestDto?: CaretakerCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdCaretakersPost', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/caretakers`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caretakerCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalCaretakerApi - functional programming interface
 * @export
 */
export const GlobalCaretakerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalCaretakerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgCaretakersGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgCaretakersGet(pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgCaretakersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgCaretakersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgCaretakersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgCaretakersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CaretakerUpdateRequestDto} [caretakerUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgCaretakersIdPost(id: string, caretakerUpdateRequestDto?: CaretakerUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgCaretakersIdPost(id, caretakerUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdCaretakersAvailableRawPhoneNumberGet(organizationId: string, rawPhoneNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdCaretakersAvailableRawPhoneNumberGet(organizationId, rawPhoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdCaretakersGet(organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdCaretakersGet(organizationId, pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdCaretakersIdAttachPost(organizationId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdCaretakersIdAttachPost(organizationId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdCaretakersIdDelete(organizationId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdCaretakersIdDelete(organizationId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdCaretakersIdDetachPost(organizationId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdCaretakersIdDetachPost(organizationId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdCaretakersIdGet(organizationId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdCaretakersIdGet(organizationId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdCaretakersPost(organizationId: string, caretakerCreateRequestDto?: CaretakerCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdCaretakersPost(organizationId, caretakerCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalCaretakerApi - factory interface
 * @export
 */
export const GlobalCaretakerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalCaretakerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgCaretakersGet(pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<CaretakerResponseDtoPagedList> {
            return localVarFp.apiV1AdministrationNoOrgCaretakersGet(pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgCaretakersIdDelete(id: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgCaretakersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgCaretakersIdGet(id: string, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgCaretakersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CaretakerUpdateRequestDto} [caretakerUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgCaretakersIdPost(id: string, caretakerUpdateRequestDto?: CaretakerUpdateRequestDto, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgCaretakersIdPost(id, caretakerUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersAvailableRawPhoneNumberGet(organizationId: string, rawPhoneNumber: string, options?: any): AxiosPromise<CaretakerAvailability> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdCaretakersAvailableRawPhoneNumberGet(organizationId, rawPhoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersGet(organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<CaretakerResponseDtoPagedList> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdCaretakersGet(organizationId, pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersIdAttachPost(organizationId: string, id: string, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdCaretakersIdAttachPost(organizationId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersIdDelete(organizationId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdCaretakersIdDelete(organizationId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersIdDetachPost(organizationId: string, id: string, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdCaretakersIdDetachPost(organizationId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersIdGet(organizationId: string, id: string, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdCaretakersIdGet(organizationId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdCaretakersPost(organizationId: string, caretakerCreateRequestDto?: CaretakerCreateRequestDto, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdCaretakersPost(organizationId, caretakerCreateRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalCaretakerApi - object-oriented interface
 * @export
 * @class GlobalCaretakerApi
 * @extends {BaseAPI}
 */
export class GlobalCaretakerApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCaretakerApi
     */
    public apiV1AdministrationNoOrgCaretakersGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return GlobalCaretakerApiFp(this.configuration).apiV1AdministrationNoOrgCaretakersGet(pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCaretakerApi
     */
    public apiV1AdministrationNoOrgCaretakersIdDelete(id: string, options?: AxiosRequestConfig) {
        return GlobalCaretakerApiFp(this.configuration).apiV1AdministrationNoOrgCaretakersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCaretakerApi
     */
    public apiV1AdministrationNoOrgCaretakersIdGet(id: string, options?: AxiosRequestConfig) {
        return GlobalCaretakerApiFp(this.configuration).apiV1AdministrationNoOrgCaretakersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CaretakerUpdateRequestDto} [caretakerUpdateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCaretakerApi
     */
    public apiV1AdministrationNoOrgCaretakersIdPost(id: string, caretakerUpdateRequestDto?: CaretakerUpdateRequestDto, options?: AxiosRequestConfig) {
        return GlobalCaretakerApiFp(this.configuration).apiV1AdministrationNoOrgCaretakersIdPost(id, caretakerUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} rawPhoneNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCaretakerApi
     */
    public apiV1AdministrationOrganizationOrganizationIdCaretakersAvailableRawPhoneNumberGet(organizationId: string, rawPhoneNumber: string, options?: AxiosRequestConfig) {
        return GlobalCaretakerApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdCaretakersAvailableRawPhoneNumberGet(organizationId, rawPhoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCaretakerApi
     */
    public apiV1AdministrationOrganizationOrganizationIdCaretakersGet(organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return GlobalCaretakerApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdCaretakersGet(organizationId, pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCaretakerApi
     */
    public apiV1AdministrationOrganizationOrganizationIdCaretakersIdAttachPost(organizationId: string, id: string, options?: AxiosRequestConfig) {
        return GlobalCaretakerApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdCaretakersIdAttachPost(organizationId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCaretakerApi
     */
    public apiV1AdministrationOrganizationOrganizationIdCaretakersIdDelete(organizationId: string, id: string, options?: AxiosRequestConfig) {
        return GlobalCaretakerApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdCaretakersIdDelete(organizationId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCaretakerApi
     */
    public apiV1AdministrationOrganizationOrganizationIdCaretakersIdDetachPost(organizationId: string, id: string, options?: AxiosRequestConfig) {
        return GlobalCaretakerApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdCaretakersIdDetachPost(organizationId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCaretakerApi
     */
    public apiV1AdministrationOrganizationOrganizationIdCaretakersIdGet(organizationId: string, id: string, options?: AxiosRequestConfig) {
        return GlobalCaretakerApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdCaretakersIdGet(organizationId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCaretakerApi
     */
    public apiV1AdministrationOrganizationOrganizationIdCaretakersPost(organizationId: string, caretakerCreateRequestDto?: CaretakerCreateRequestDto, options?: AxiosRequestConfig) {
        return GlobalCaretakerApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdCaretakersPost(organizationId, caretakerCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalDoctorsApi - axios parameter creator
 * @export
 */
export const GlobalDoctorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgDoctorsGet: async (pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/noOrg/doctors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgDoctorsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgDoctorsIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/doctors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgDoctorsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgDoctorsIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/doctors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DoctorUpdateRequestDto} [doctorUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgDoctorsIdPost: async (id: string, doctorUpdateRequestDto?: DoctorUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgDoctorsIdPost', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/doctors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsAvailableRawPhoneNumberGet: async (organizationId: string, rawPhoneNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsAvailableRawPhoneNumberGet', 'organizationId', organizationId)
            // verify required parameter 'rawPhoneNumber' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsAvailableRawPhoneNumberGet', 'rawPhoneNumber', rawPhoneNumber)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/doctors/available/{rawPhoneNumber}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"rawPhoneNumber"}}`, encodeURIComponent(String(rawPhoneNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsGet: async (organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/doctors`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsIdAttachPost: async (organizationId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsIdAttachPost', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsIdAttachPost', 'id', id)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/doctors/{id}/attach`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsIdDelete: async (organizationId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsIdDelete', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/doctors/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsIdDetachPost: async (organizationId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsIdDetachPost', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsIdDetachPost', 'id', id)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/doctors/{id}/detach`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsIdGet: async (organizationId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsIdGet', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/doctors/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {DoctorRequestDto} [doctorRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsPost: async (organizationId: string, doctorRequestDto?: DoctorRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdDoctorsPost', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/doctors`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalDoctorsApi - functional programming interface
 * @export
 */
export const GlobalDoctorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalDoctorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgDoctorsGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgDoctorsGet(pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgDoctorsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgDoctorsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgDoctorsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgDoctorsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {DoctorUpdateRequestDto} [doctorUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgDoctorsIdPost(id: string, doctorUpdateRequestDto?: DoctorUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgDoctorsIdPost(id, doctorUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdDoctorsAvailableRawPhoneNumberGet(organizationId: string, rawPhoneNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdDoctorsAvailableRawPhoneNumberGet(organizationId, rawPhoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdDoctorsGet(organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdDoctorsGet(organizationId, pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdDoctorsIdAttachPost(organizationId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdDoctorsIdAttachPost(organizationId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdDoctorsIdDelete(organizationId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdDoctorsIdDelete(organizationId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdDoctorsIdDetachPost(organizationId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdDoctorsIdDetachPost(organizationId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdDoctorsIdGet(organizationId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdDoctorsIdGet(organizationId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {DoctorRequestDto} [doctorRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdDoctorsPost(organizationId: string, doctorRequestDto?: DoctorRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdDoctorsPost(organizationId, doctorRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalDoctorsApi - factory interface
 * @export
 */
export const GlobalDoctorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalDoctorsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgDoctorsGet(pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<DoctorResponseDtoPagedList> {
            return localVarFp.apiV1AdministrationNoOrgDoctorsGet(pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgDoctorsIdDelete(id: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgDoctorsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgDoctorsIdGet(id: string, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgDoctorsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DoctorUpdateRequestDto} [doctorUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgDoctorsIdPost(id: string, doctorUpdateRequestDto?: DoctorUpdateRequestDto, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgDoctorsIdPost(id, doctorUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsAvailableRawPhoneNumberGet(organizationId: string, rawPhoneNumber: string, options?: any): AxiosPromise<DoctorAvailability> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdDoctorsAvailableRawPhoneNumberGet(organizationId, rawPhoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsGet(organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<DoctorResponseDtoPagedList> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdDoctorsGet(organizationId, pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsIdAttachPost(organizationId: string, id: string, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdDoctorsIdAttachPost(organizationId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsIdDelete(organizationId: string, id: string, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdDoctorsIdDelete(organizationId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsIdDetachPost(organizationId: string, id: string, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdDoctorsIdDetachPost(organizationId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsIdGet(organizationId: string, id: string, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdDoctorsIdGet(organizationId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {DoctorRequestDto} [doctorRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdDoctorsPost(organizationId: string, doctorRequestDto?: DoctorRequestDto, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdDoctorsPost(organizationId, doctorRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalDoctorsApi - object-oriented interface
 * @export
 * @class GlobalDoctorsApi
 * @extends {BaseAPI}
 */
export class GlobalDoctorsApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDoctorsApi
     */
    public apiV1AdministrationNoOrgDoctorsGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return GlobalDoctorsApiFp(this.configuration).apiV1AdministrationNoOrgDoctorsGet(pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDoctorsApi
     */
    public apiV1AdministrationNoOrgDoctorsIdDelete(id: string, options?: AxiosRequestConfig) {
        return GlobalDoctorsApiFp(this.configuration).apiV1AdministrationNoOrgDoctorsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDoctorsApi
     */
    public apiV1AdministrationNoOrgDoctorsIdGet(id: string, options?: AxiosRequestConfig) {
        return GlobalDoctorsApiFp(this.configuration).apiV1AdministrationNoOrgDoctorsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {DoctorUpdateRequestDto} [doctorUpdateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDoctorsApi
     */
    public apiV1AdministrationNoOrgDoctorsIdPost(id: string, doctorUpdateRequestDto?: DoctorUpdateRequestDto, options?: AxiosRequestConfig) {
        return GlobalDoctorsApiFp(this.configuration).apiV1AdministrationNoOrgDoctorsIdPost(id, doctorUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} rawPhoneNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDoctorsApi
     */
    public apiV1AdministrationOrganizationOrganizationIdDoctorsAvailableRawPhoneNumberGet(organizationId: string, rawPhoneNumber: string, options?: AxiosRequestConfig) {
        return GlobalDoctorsApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdDoctorsAvailableRawPhoneNumberGet(organizationId, rawPhoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDoctorsApi
     */
    public apiV1AdministrationOrganizationOrganizationIdDoctorsGet(organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return GlobalDoctorsApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdDoctorsGet(organizationId, pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDoctorsApi
     */
    public apiV1AdministrationOrganizationOrganizationIdDoctorsIdAttachPost(organizationId: string, id: string, options?: AxiosRequestConfig) {
        return GlobalDoctorsApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdDoctorsIdAttachPost(organizationId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDoctorsApi
     */
    public apiV1AdministrationOrganizationOrganizationIdDoctorsIdDelete(organizationId: string, id: string, options?: AxiosRequestConfig) {
        return GlobalDoctorsApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdDoctorsIdDelete(organizationId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDoctorsApi
     */
    public apiV1AdministrationOrganizationOrganizationIdDoctorsIdDetachPost(organizationId: string, id: string, options?: AxiosRequestConfig) {
        return GlobalDoctorsApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdDoctorsIdDetachPost(organizationId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDoctorsApi
     */
    public apiV1AdministrationOrganizationOrganizationIdDoctorsIdGet(organizationId: string, id: string, options?: AxiosRequestConfig) {
        return GlobalDoctorsApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdDoctorsIdGet(organizationId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {DoctorRequestDto} [doctorRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalDoctorsApi
     */
    public apiV1AdministrationOrganizationOrganizationIdDoctorsPost(organizationId: string, doctorRequestDto?: DoctorRequestDto, options?: AxiosRequestConfig) {
        return GlobalDoctorsApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdDoctorsPost(organizationId, doctorRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalFamilyMembersApi - axios parameter creator
 * @export
 */
export const GlobalFamilyMembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgFamilyMembersGet: async (pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/noOrg/familyMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgFamilyMembersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgFamilyMembersIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/familyMembers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgFamilyMembersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgFamilyMembersIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/familyMembers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FamilyMemberRequestDto} [familyMemberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgFamilyMembersIdPost: async (id: string, familyMemberRequestDto?: FamilyMemberRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgFamilyMembersIdPost', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/familyMembers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(familyMemberRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalFamilyMembersApi - functional programming interface
 * @export
 */
export const GlobalFamilyMembersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalFamilyMembersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgFamilyMembersGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FamilyMemberResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgFamilyMembersGet(pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgFamilyMembersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FamilyMemberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgFamilyMembersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgFamilyMembersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FamilyMemberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgFamilyMembersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {FamilyMemberRequestDto} [familyMemberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgFamilyMembersIdPost(id: string, familyMemberRequestDto?: FamilyMemberRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FamilyMemberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgFamilyMembersIdPost(id, familyMemberRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalFamilyMembersApi - factory interface
 * @export
 */
export const GlobalFamilyMembersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalFamilyMembersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgFamilyMembersGet(pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<FamilyMemberResponseDtoPagedList> {
            return localVarFp.apiV1AdministrationNoOrgFamilyMembersGet(pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgFamilyMembersIdDelete(id: string, options?: any): AxiosPromise<FamilyMemberResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgFamilyMembersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgFamilyMembersIdGet(id: string, options?: any): AxiosPromise<FamilyMemberResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgFamilyMembersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {FamilyMemberRequestDto} [familyMemberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgFamilyMembersIdPost(id: string, familyMemberRequestDto?: FamilyMemberRequestDto, options?: any): AxiosPromise<FamilyMemberResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgFamilyMembersIdPost(id, familyMemberRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalFamilyMembersApi - object-oriented interface
 * @export
 * @class GlobalFamilyMembersApi
 * @extends {BaseAPI}
 */
export class GlobalFamilyMembersApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalFamilyMembersApi
     */
    public apiV1AdministrationNoOrgFamilyMembersGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return GlobalFamilyMembersApiFp(this.configuration).apiV1AdministrationNoOrgFamilyMembersGet(pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalFamilyMembersApi
     */
    public apiV1AdministrationNoOrgFamilyMembersIdDelete(id: string, options?: AxiosRequestConfig) {
        return GlobalFamilyMembersApiFp(this.configuration).apiV1AdministrationNoOrgFamilyMembersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalFamilyMembersApi
     */
    public apiV1AdministrationNoOrgFamilyMembersIdGet(id: string, options?: AxiosRequestConfig) {
        return GlobalFamilyMembersApiFp(this.configuration).apiV1AdministrationNoOrgFamilyMembersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {FamilyMemberRequestDto} [familyMemberRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalFamilyMembersApi
     */
    public apiV1AdministrationNoOrgFamilyMembersIdPost(id: string, familyMemberRequestDto?: FamilyMemberRequestDto, options?: AxiosRequestConfig) {
        return GlobalFamilyMembersApiFp(this.configuration).apiV1AdministrationNoOrgFamilyMembersIdPost(id, familyMemberRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalImageLibraryApi - axios parameter creator
 * @export
 */
export const GlobalImageLibraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationImageLibraryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/imageLibrary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationImageLibraryIdContentGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationImageLibraryIdContentGet', 'id', id)
            const localVarPath = `/api/v1/administration/imageLibrary/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationImageLibraryIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationImageLibraryIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/imageLibrary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationImageLibraryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationImageLibraryIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/imageLibrary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationImageLibraryPost: async (file: File, tags?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1AdministrationImageLibraryPost', 'file', file)
            const localVarPath = `/api/v1/administration/imageLibrary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalImageLibraryApi - functional programming interface
 * @export
 */
export const GlobalImageLibraryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalImageLibraryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationImageLibraryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageFileResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationImageLibraryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationImageLibraryIdContentGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationImageLibraryIdContentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationImageLibraryIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationImageLibraryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationImageLibraryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageFileResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationImageLibraryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationImageLibraryPost(file: File, tags?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageFileResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationImageLibraryPost(file, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalImageLibraryApi - factory interface
 * @export
 */
export const GlobalImageLibraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalImageLibraryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationImageLibraryGet(options?: any): AxiosPromise<Array<ImageFileResponseDto>> {
            return localVarFp.apiV1AdministrationImageLibraryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationImageLibraryIdContentGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationImageLibraryIdContentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationImageLibraryIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationImageLibraryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationImageLibraryIdGet(id: string, options?: any): AxiosPromise<ImageFileResponseDto> {
            return localVarFp.apiV1AdministrationImageLibraryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationImageLibraryPost(file: File, tags?: string, options?: any): AxiosPromise<ImageFileResponseDto> {
            return localVarFp.apiV1AdministrationImageLibraryPost(file, tags, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalImageLibraryApi - object-oriented interface
 * @export
 * @class GlobalImageLibraryApi
 * @extends {BaseAPI}
 */
export class GlobalImageLibraryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalImageLibraryApi
     */
    public apiV1AdministrationImageLibraryGet(options?: AxiosRequestConfig) {
        return GlobalImageLibraryApiFp(this.configuration).apiV1AdministrationImageLibraryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalImageLibraryApi
     */
    public apiV1AdministrationImageLibraryIdContentGet(id: string, options?: AxiosRequestConfig) {
        return GlobalImageLibraryApiFp(this.configuration).apiV1AdministrationImageLibraryIdContentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalImageLibraryApi
     */
    public apiV1AdministrationImageLibraryIdDelete(id: string, options?: AxiosRequestConfig) {
        return GlobalImageLibraryApiFp(this.configuration).apiV1AdministrationImageLibraryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalImageLibraryApi
     */
    public apiV1AdministrationImageLibraryIdGet(id: string, options?: AxiosRequestConfig) {
        return GlobalImageLibraryApiFp(this.configuration).apiV1AdministrationImageLibraryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {string} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalImageLibraryApi
     */
    public apiV1AdministrationImageLibraryPost(file: File, tags?: string, options?: AxiosRequestConfig) {
        return GlobalImageLibraryApiFp(this.configuration).apiV1AdministrationImageLibraryPost(file, tags, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalPatientApi - axios parameter creator
 * @export
 */
export const GlobalPatientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgPatientsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgPatientsIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgPatientsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgPatientsIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgPatientsIdPost: async (id: string, patientRequestDto?: PatientRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationNoOrgPatientsIdPost', 'id', id)
            const localVarPath = `/api/v1/administration/noOrg/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrganizationPatientsGet: async (pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/noOrganization/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsAvailableRawPhoneNumberGet: async (organizationId: string, rawPhoneNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsAvailableRawPhoneNumberGet', 'organizationId', organizationId)
            // verify required parameter 'rawPhoneNumber' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsAvailableRawPhoneNumberGet', 'rawPhoneNumber', rawPhoneNumber)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/patients/available/{rawPhoneNumber}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"rawPhoneNumber"}}`, encodeURIComponent(String(rawPhoneNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsByActivationCodeGet: async (organizationId: string, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsByActivationCodeGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/patients/byActivationCode`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsGet: async (organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/patients`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsIdAttachPost: async (id: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsIdAttachPost', 'id', id)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsIdAttachPost', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/patients/{id}/attach`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsIdDelete: async (id: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsIdDelete', 'id', id)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsIdDelete', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsIdDetachPost: async (id: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsIdDetachPost', 'id', id)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsIdDetachPost', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/patients/{id}/detach`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsIdGet: async (id: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsIdGet', 'id', id)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsIdGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsPost: async (organizationId: string, patientRequestDto?: PatientRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiV1AdministrationOrganizationOrganizationIdPatientsPost', 'organizationId', organizationId)
            const localVarPath = `/api/v1/administration/organization/{organizationId}/patients`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalPatientApi - functional programming interface
 * @export
 */
export const GlobalPatientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalPatientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgPatientsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgPatientsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgPatientsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgPatientsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrgPatientsIdPost(id: string, patientRequestDto?: PatientRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrgPatientsIdPost(id, patientRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationNoOrganizationPatientsGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationNoOrganizationPatientsGet(pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdPatientsAvailableRawPhoneNumberGet(organizationId: string, rawPhoneNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdPatientsAvailableRawPhoneNumberGet(organizationId, rawPhoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdPatientsByActivationCodeGet(organizationId: string, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdPatientsByActivationCodeGet(organizationId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdPatientsGet(organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdPatientsGet(organizationId, pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdPatientsIdAttachPost(id: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdPatientsIdAttachPost(id, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdPatientsIdDelete(id: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdPatientsIdDelete(id, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdPatientsIdDetachPost(id: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdPatientsIdDetachPost(id, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdPatientsIdGet(id: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdPatientsIdGet(id, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationOrganizationOrganizationIdPatientsPost(organizationId: string, patientRequestDto?: PatientRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationOrganizationOrganizationIdPatientsPost(organizationId, patientRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalPatientApi - factory interface
 * @export
 */
export const GlobalPatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalPatientApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgPatientsIdDelete(id: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgPatientsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgPatientsIdGet(id: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgPatientsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrgPatientsIdPost(id: string, patientRequestDto?: PatientRequestDto, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1AdministrationNoOrgPatientsIdPost(id, patientRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationNoOrganizationPatientsGet(pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<PatientResponseDtoPagedList> {
            return localVarFp.apiV1AdministrationNoOrganizationPatientsGet(pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsAvailableRawPhoneNumberGet(organizationId: string, rawPhoneNumber: string, options?: any): AxiosPromise<PatientAvailability> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdPatientsAvailableRawPhoneNumberGet(organizationId, rawPhoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsByActivationCodeGet(organizationId: string, code?: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdPatientsByActivationCodeGet(organizationId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsGet(organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<PatientResponseDtoPagedList> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdPatientsGet(organizationId, pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsIdAttachPost(id: string, organizationId: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdPatientsIdAttachPost(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsIdDelete(id: string, organizationId: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdPatientsIdDelete(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsIdDetachPost(id: string, organizationId: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdPatientsIdDetachPost(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsIdGet(id: string, organizationId: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdPatientsIdGet(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationOrganizationOrganizationIdPatientsPost(organizationId: string, patientRequestDto?: PatientRequestDto, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1AdministrationOrganizationOrganizationIdPatientsPost(organizationId, patientRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalPatientApi - object-oriented interface
 * @export
 * @class GlobalPatientApi
 * @extends {BaseAPI}
 */
export class GlobalPatientApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationNoOrgPatientsIdDelete(id: string, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationNoOrgPatientsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationNoOrgPatientsIdGet(id: string, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationNoOrgPatientsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PatientRequestDto} [patientRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationNoOrgPatientsIdPost(id: string, patientRequestDto?: PatientRequestDto, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationNoOrgPatientsIdPost(id, patientRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationNoOrganizationPatientsGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationNoOrganizationPatientsGet(pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} rawPhoneNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationOrganizationOrganizationIdPatientsAvailableRawPhoneNumberGet(organizationId: string, rawPhoneNumber: string, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdPatientsAvailableRawPhoneNumberGet(organizationId, rawPhoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationOrganizationOrganizationIdPatientsByActivationCodeGet(organizationId: string, code?: string, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdPatientsByActivationCodeGet(organizationId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationOrganizationOrganizationIdPatientsGet(organizationId: string, pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdPatientsGet(organizationId, pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationOrganizationOrganizationIdPatientsIdAttachPost(id: string, organizationId: string, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdPatientsIdAttachPost(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationOrganizationOrganizationIdPatientsIdDelete(id: string, organizationId: string, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdPatientsIdDelete(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationOrganizationOrganizationIdPatientsIdDetachPost(id: string, organizationId: string, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdPatientsIdDetachPost(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationOrganizationOrganizationIdPatientsIdGet(id: string, organizationId: string, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdPatientsIdGet(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {PatientRequestDto} [patientRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPatientApi
     */
    public apiV1AdministrationOrganizationOrganizationIdPatientsPost(organizationId: string, patientRequestDto?: PatientRequestDto, options?: AxiosRequestConfig) {
        return GlobalPatientApiFp(this.configuration).apiV1AdministrationOrganizationOrganizationIdPatientsPost(organizationId, patientRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalTreatmentTemplateApi - axios parameter creator
 * @export
 */
export const GlobalTreatmentTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/treatmentTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationTreatmentTemplateIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/treatmentTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationTreatmentTemplateIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/treatmentTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateIdImageGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationTreatmentTemplateIdImageGet', 'id', id)
            const localVarPath = `/api/v1/administration/treatmentTemplate/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TreatmentTemplateRequestDto} [treatmentTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateIdPost: async (id: string, treatmentTemplateRequestDto?: TreatmentTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationTreatmentTemplateIdPost', 'id', id)
            const localVarPath = `/api/v1/administration/treatmentTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(treatmentTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateImagePost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/treatmentTemplate/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TreatmentTemplateRequestDto} [treatmentTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplatePut: async (treatmentTemplateRequestDto?: TreatmentTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/treatmentTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(treatmentTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalTreatmentTemplateApi - functional programming interface
 * @export
 */
export const GlobalTreatmentTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalTreatmentTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationTreatmentTemplateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TreatmentTemplateResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationTreatmentTemplateGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationTreatmentTemplateIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationTreatmentTemplateIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationTreatmentTemplateIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationTreatmentTemplateIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationTreatmentTemplateIdImageGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationTreatmentTemplateIdImageGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {TreatmentTemplateRequestDto} [treatmentTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationTreatmentTemplateIdPost(id: string, treatmentTemplateRequestDto?: TreatmentTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationTreatmentTemplateIdPost(id, treatmentTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationTreatmentTemplateImagePost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageFileResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationTreatmentTemplateImagePost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TreatmentTemplateRequestDto} [treatmentTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationTreatmentTemplatePut(treatmentTemplateRequestDto?: TreatmentTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationTreatmentTemplatePut(treatmentTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalTreatmentTemplateApi - factory interface
 * @export
 */
export const GlobalTreatmentTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalTreatmentTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateGet(options?: any): AxiosPromise<Array<TreatmentTemplateResponseDto>> {
            return localVarFp.apiV1AdministrationTreatmentTemplateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationTreatmentTemplateIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateIdGet(id: string, options?: any): AxiosPromise<TreatmentTemplateResponseDto> {
            return localVarFp.apiV1AdministrationTreatmentTemplateIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateIdImageGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationTreatmentTemplateIdImageGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {TreatmentTemplateRequestDto} [treatmentTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateIdPost(id: string, treatmentTemplateRequestDto?: TreatmentTemplateRequestDto, options?: any): AxiosPromise<TreatmentTemplateResponseDto> {
            return localVarFp.apiV1AdministrationTreatmentTemplateIdPost(id, treatmentTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplateImagePost(file?: File, options?: any): AxiosPromise<ImageFileResponseDto> {
            return localVarFp.apiV1AdministrationTreatmentTemplateImagePost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TreatmentTemplateRequestDto} [treatmentTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationTreatmentTemplatePut(treatmentTemplateRequestDto?: TreatmentTemplateRequestDto, options?: any): AxiosPromise<TreatmentTemplateResponseDto> {
            return localVarFp.apiV1AdministrationTreatmentTemplatePut(treatmentTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalTreatmentTemplateApi - object-oriented interface
 * @export
 * @class GlobalTreatmentTemplateApi
 * @extends {BaseAPI}
 */
export class GlobalTreatmentTemplateApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalTreatmentTemplateApi
     */
    public apiV1AdministrationTreatmentTemplateGet(options?: AxiosRequestConfig) {
        return GlobalTreatmentTemplateApiFp(this.configuration).apiV1AdministrationTreatmentTemplateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalTreatmentTemplateApi
     */
    public apiV1AdministrationTreatmentTemplateIdDelete(id: string, options?: AxiosRequestConfig) {
        return GlobalTreatmentTemplateApiFp(this.configuration).apiV1AdministrationTreatmentTemplateIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalTreatmentTemplateApi
     */
    public apiV1AdministrationTreatmentTemplateIdGet(id: string, options?: AxiosRequestConfig) {
        return GlobalTreatmentTemplateApiFp(this.configuration).apiV1AdministrationTreatmentTemplateIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalTreatmentTemplateApi
     */
    public apiV1AdministrationTreatmentTemplateIdImageGet(id: string, options?: AxiosRequestConfig) {
        return GlobalTreatmentTemplateApiFp(this.configuration).apiV1AdministrationTreatmentTemplateIdImageGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {TreatmentTemplateRequestDto} [treatmentTemplateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalTreatmentTemplateApi
     */
    public apiV1AdministrationTreatmentTemplateIdPost(id: string, treatmentTemplateRequestDto?: TreatmentTemplateRequestDto, options?: AxiosRequestConfig) {
        return GlobalTreatmentTemplateApiFp(this.configuration).apiV1AdministrationTreatmentTemplateIdPost(id, treatmentTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalTreatmentTemplateApi
     */
    public apiV1AdministrationTreatmentTemplateImagePost(file?: File, options?: AxiosRequestConfig) {
        return GlobalTreatmentTemplateApiFp(this.configuration).apiV1AdministrationTreatmentTemplateImagePost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TreatmentTemplateRequestDto} [treatmentTemplateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalTreatmentTemplateApi
     */
    public apiV1AdministrationTreatmentTemplatePut(treatmentTemplateRequestDto?: TreatmentTemplateRequestDto, options?: AxiosRequestConfig) {
        return GlobalTreatmentTemplateApiFp(this.configuration).apiV1AdministrationTreatmentTemplatePut(treatmentTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImageLibraryApi - axios parameter creator
 * @export
 */
export const ImageLibraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibraryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ImageLibrary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibraryIdContentGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ImageLibraryIdContentGet', 'id', id)
            const localVarPath = `/api/v1/ImageLibrary/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibraryIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ImageLibraryIdDelete', 'id', id)
            const localVarPath = `/api/v1/ImageLibrary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibraryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ImageLibraryIdGet', 'id', id)
            const localVarPath = `/api/v1/ImageLibrary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibraryPost: async (file: File, tags?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1ImageLibraryPost', 'file', file)
            const localVarPath = `/api/v1/ImageLibrary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibrarySharedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ImageLibrary/shared`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageLibraryApi - functional programming interface
 * @export
 */
export const ImageLibraryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageLibraryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageLibraryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageFileResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageLibraryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageLibraryIdContentGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageLibraryIdContentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageLibraryIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageLibraryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageLibraryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageFileResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageLibraryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageLibraryPost(file: File, tags?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageFileResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageLibraryPost(file, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ImageLibrarySharedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageFileResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ImageLibrarySharedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageLibraryApi - factory interface
 * @export
 */
export const ImageLibraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageLibraryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibraryGet(options?: any): AxiosPromise<Array<ImageFileResponseDto>> {
            return localVarFp.apiV1ImageLibraryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibraryIdContentGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ImageLibraryIdContentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibraryIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ImageLibraryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibraryIdGet(id: string, options?: any): AxiosPromise<ImageFileResponseDto> {
            return localVarFp.apiV1ImageLibraryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibraryPost(file: File, tags?: string, options?: any): AxiosPromise<ImageFileResponseDto> {
            return localVarFp.apiV1ImageLibraryPost(file, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ImageLibrarySharedGet(options?: any): AxiosPromise<Array<ImageFileResponseDto>> {
            return localVarFp.apiV1ImageLibrarySharedGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageLibraryApi - object-oriented interface
 * @export
 * @class ImageLibraryApi
 * @extends {BaseAPI}
 */
export class ImageLibraryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageLibraryApi
     */
    public apiV1ImageLibraryGet(options?: AxiosRequestConfig) {
        return ImageLibraryApiFp(this.configuration).apiV1ImageLibraryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageLibraryApi
     */
    public apiV1ImageLibraryIdContentGet(id: string, options?: AxiosRequestConfig) {
        return ImageLibraryApiFp(this.configuration).apiV1ImageLibraryIdContentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageLibraryApi
     */
    public apiV1ImageLibraryIdDelete(id: string, options?: AxiosRequestConfig) {
        return ImageLibraryApiFp(this.configuration).apiV1ImageLibraryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageLibraryApi
     */
    public apiV1ImageLibraryIdGet(id: string, options?: AxiosRequestConfig) {
        return ImageLibraryApiFp(this.configuration).apiV1ImageLibraryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {string} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageLibraryApi
     */
    public apiV1ImageLibraryPost(file: File, tags?: string, options?: AxiosRequestConfig) {
        return ImageLibraryApiFp(this.configuration).apiV1ImageLibraryPost(file, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageLibraryApi
     */
    public apiV1ImageLibrarySharedGet(options?: AxiosRequestConfig) {
        return ImageLibraryApiFp(this.configuration).apiV1ImageLibrarySharedGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InfoApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InfoGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1InfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoGet(options?: any): AxiosPromise<void> {
            return localVarFp.infoGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public apiV1InfoGet(options?: AxiosRequestConfig) {
        return InfoApiFp(this.configuration).apiV1InfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public infoGet(options?: AxiosRequestConfig) {
        return InfoApiFp(this.configuration).infoGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationPatientPatientIdGet: async (patientId: string, dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1LocationPatientPatientIdGet', 'patientId', patientId)
            const localVarPath = `/api/v1/Location/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LocationHistoryUpdateDto} [locationHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationPost: async (locationHistoryUpdateDto?: LocationHistoryUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationHistoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationPatientPatientIdGet(patientId: string, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationPatientPatientIdGet(patientId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LocationHistoryUpdateDto} [locationHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationPost(locationHistoryUpdateDto?: LocationHistoryUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationPost(locationHistoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationPatientPatientIdGet(patientId: string, dateFrom?: string, dateTo?: string, options?: any): AxiosPromise<Array<LocationHistoryDto>> {
            return localVarFp.apiV1LocationPatientPatientIdGet(patientId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationHistoryUpdateDto} [locationHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationPost(locationHistoryUpdateDto?: LocationHistoryUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1LocationPost(locationHistoryUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public apiV1LocationPatientPatientIdGet(patientId: string, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).apiV1LocationPatientPatientIdGet(patientId, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationHistoryUpdateDto} [locationHistoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public apiV1LocationPost(locationHistoryUpdateDto?: LocationHistoryUpdateDto, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).apiV1LocationPost(locationHistoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MaintenanceDeleteAuthenticatedUserPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Maintenance/DeleteAuthenticatedUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MaintenanceDeleteUserAccountByPhonePost: async (phoneNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Maintenance/DeleteUserAccountByPhone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {string} startDateTimeUtc 
         * @param {number} intervalInDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MaintenanceGeneratePatientHappinessResultPost: async (phoneNumber: string, startDateTimeUtc: string, intervalInDays: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('apiV1MaintenanceGeneratePatientHappinessResultPost', 'phoneNumber', phoneNumber)
            // verify required parameter 'startDateTimeUtc' is not null or undefined
            assertParamExists('apiV1MaintenanceGeneratePatientHappinessResultPost', 'startDateTimeUtc', startDateTimeUtc)
            // verify required parameter 'intervalInDays' is not null or undefined
            assertParamExists('apiV1MaintenanceGeneratePatientHappinessResultPost', 'intervalInDays', intervalInDays)
            const localVarPath = `/api/v1/Maintenance/GeneratePatientHappinessResult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (startDateTimeUtc !== undefined) {
                localVarQueryParameter['startDateTimeUtc'] = (startDateTimeUtc as any instanceof Date) ?
                    (startDateTimeUtc as any).toISOString() :
                    startDateTimeUtc;
            }

            if (intervalInDays !== undefined) {
                localVarQueryParameter['intervalInDays'] = intervalInDays;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {string} startDateTimeUtc 
         * @param {number} intervalInDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MaintenanceGeneratePatientMonitoringResultPost: async (phoneNumber: string, startDateTimeUtc: string, intervalInDays: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('apiV1MaintenanceGeneratePatientMonitoringResultPost', 'phoneNumber', phoneNumber)
            // verify required parameter 'startDateTimeUtc' is not null or undefined
            assertParamExists('apiV1MaintenanceGeneratePatientMonitoringResultPost', 'startDateTimeUtc', startDateTimeUtc)
            // verify required parameter 'intervalInDays' is not null or undefined
            assertParamExists('apiV1MaintenanceGeneratePatientMonitoringResultPost', 'intervalInDays', intervalInDays)
            const localVarPath = `/api/v1/Maintenance/GeneratePatientMonitoringResult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (startDateTimeUtc !== undefined) {
                localVarQueryParameter['startDateTimeUtc'] = (startDateTimeUtc as any instanceof Date) ?
                    (startDateTimeUtc as any).toISOString() :
                    startDateTimeUtc;
            }

            if (intervalInDays !== undefined) {
                localVarQueryParameter['intervalInDays'] = intervalInDays;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MaintenanceDeleteAuthenticatedUserPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MaintenanceDeleteAuthenticatedUserPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MaintenanceDeleteUserAccountByPhonePost(phoneNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MaintenanceDeleteUserAccountByPhonePost(phoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {string} startDateTimeUtc 
         * @param {number} intervalInDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MaintenanceGeneratePatientHappinessResultPost(phoneNumber: string, startDateTimeUtc: string, intervalInDays: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MaintenanceGeneratePatientHappinessResultPost(phoneNumber, startDateTimeUtc, intervalInDays, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {string} startDateTimeUtc 
         * @param {number} intervalInDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MaintenanceGeneratePatientMonitoringResultPost(phoneNumber: string, startDateTimeUtc: string, intervalInDays: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MaintenanceGeneratePatientMonitoringResultPost(phoneNumber, startDateTimeUtc, intervalInDays, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MaintenanceDeleteAuthenticatedUserPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MaintenanceDeleteAuthenticatedUserPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MaintenanceDeleteUserAccountByPhonePost(phoneNumber?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MaintenanceDeleteUserAccountByPhonePost(phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {string} startDateTimeUtc 
         * @param {number} intervalInDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MaintenanceGeneratePatientHappinessResultPost(phoneNumber: string, startDateTimeUtc: string, intervalInDays: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MaintenanceGeneratePatientHappinessResultPost(phoneNumber, startDateTimeUtc, intervalInDays, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {string} startDateTimeUtc 
         * @param {number} intervalInDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MaintenanceGeneratePatientMonitoringResultPost(phoneNumber: string, startDateTimeUtc: string, intervalInDays: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MaintenanceGeneratePatientMonitoringResultPost(phoneNumber, startDateTimeUtc, intervalInDays, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public apiV1MaintenanceDeleteAuthenticatedUserPost(options?: AxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).apiV1MaintenanceDeleteAuthenticatedUserPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [phoneNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public apiV1MaintenanceDeleteUserAccountByPhonePost(phoneNumber?: string, options?: AxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).apiV1MaintenanceDeleteUserAccountByPhonePost(phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} phoneNumber 
     * @param {string} startDateTimeUtc 
     * @param {number} intervalInDays 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public apiV1MaintenanceGeneratePatientHappinessResultPost(phoneNumber: string, startDateTimeUtc: string, intervalInDays: number, options?: AxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).apiV1MaintenanceGeneratePatientHappinessResultPost(phoneNumber, startDateTimeUtc, intervalInDays, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} phoneNumber 
     * @param {string} startDateTimeUtc 
     * @param {number} intervalInDays 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public apiV1MaintenanceGeneratePatientMonitoringResultPost(phoneNumber: string, startDateTimeUtc: string, intervalInDays: number, options?: AxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).apiV1MaintenanceGeneratePatientMonitoringResultPost(phoneNumber, startDateTimeUtc, intervalInDays, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeasurementTypeApi - axios parameter creator
 * @export
 */
export const MeasurementTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/measurement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationMeasurementIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/measurement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationMeasurementIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/measurement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MeasurementTypeRequestDto} [measurementTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementIdPost: async (id: string, measurementTypeRequestDto?: MeasurementTypeRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationMeasurementIdPost', 'id', id)
            const localVarPath = `/api/v1/administration/measurement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(measurementTypeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet: async (measurementTypeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementTypeId' is not null or undefined
            assertParamExists('apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet', 'measurementTypeId', measurementTypeId)
            const localVarPath = `/api/v1/administration/measurement/{measurementTypeId}/valueTypes`
                .replace(`{${"measurementTypeId"}}`, encodeURIComponent(String(measurementTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdDelete: async (measurementTypeId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementTypeId' is not null or undefined
            assertParamExists('apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdDelete', 'measurementTypeId', measurementTypeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdDelete', 'id', id)
            const localVarPath = `/api/v1/administration/measurement/{measurementTypeId}/valueTypes/{id}`
                .replace(`{${"measurementTypeId"}}`, encodeURIComponent(String(measurementTypeId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdGet: async (measurementTypeId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementTypeId' is not null or undefined
            assertParamExists('apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdGet', 'measurementTypeId', measurementTypeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdGet', 'id', id)
            const localVarPath = `/api/v1/administration/measurement/{measurementTypeId}/valueTypes/{id}`
                .replace(`{${"measurementTypeId"}}`, encodeURIComponent(String(measurementTypeId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {string} id 
         * @param {MeasurementValueTypeRequestDto} [measurementValueTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdPost: async (measurementTypeId: string, id: string, measurementValueTypeRequestDto?: MeasurementValueTypeRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementTypeId' is not null or undefined
            assertParamExists('apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdPost', 'measurementTypeId', measurementTypeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdPost', 'id', id)
            const localVarPath = `/api/v1/administration/measurement/{measurementTypeId}/valueTypes/{id}`
                .replace(`{${"measurementTypeId"}}`, encodeURIComponent(String(measurementTypeId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(measurementValueTypeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {MeasurementValueTypeRequestDto} [measurementValueTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementMeasurementTypeIdValueTypesPut: async (measurementTypeId: string, measurementValueTypeRequestDto?: MeasurementValueTypeRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementTypeId' is not null or undefined
            assertParamExists('apiV1AdministrationMeasurementMeasurementTypeIdValueTypesPut', 'measurementTypeId', measurementTypeId)
            const localVarPath = `/api/v1/administration/measurement/{measurementTypeId}/valueTypes`
                .replace(`{${"measurementTypeId"}}`, encodeURIComponent(String(measurementTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(measurementValueTypeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MeasurementTypeRequestDto} [measurementTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementPut: async (measurementTypeRequestDto?: MeasurementTypeRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/measurement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(measurementTypeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasurementTypeApi - functional programming interface
 * @export
 */
export const MeasurementTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasurementTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationMeasurementGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeasurementTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationMeasurementGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationMeasurementIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationMeasurementIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationMeasurementIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeasurementTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationMeasurementIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MeasurementTypeRequestDto} [measurementTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationMeasurementIdPost(id: string, measurementTypeRequestDto?: MeasurementTypeRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeasurementTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationMeasurementIdPost(id, measurementTypeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet(measurementTypeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeasurementValueTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet(measurementTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdDelete(measurementTypeId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdDelete(measurementTypeId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdGet(measurementTypeId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeasurementValueTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdGet(measurementTypeId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {string} id 
         * @param {MeasurementValueTypeRequestDto} [measurementValueTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdPost(measurementTypeId: string, id: string, measurementValueTypeRequestDto?: MeasurementValueTypeRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeasurementValueTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdPost(measurementTypeId, id, measurementValueTypeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {MeasurementValueTypeRequestDto} [measurementValueTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationMeasurementMeasurementTypeIdValueTypesPut(measurementTypeId: string, measurementValueTypeRequestDto?: MeasurementValueTypeRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeasurementValueTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesPut(measurementTypeId, measurementValueTypeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MeasurementTypeRequestDto} [measurementTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdministrationMeasurementPut(measurementTypeRequestDto?: MeasurementTypeRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeasurementTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdministrationMeasurementPut(measurementTypeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeasurementTypeApi - factory interface
 * @export
 */
export const MeasurementTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasurementTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementGet(options?: any): AxiosPromise<Array<MeasurementTypeDto>> {
            return localVarFp.apiV1AdministrationMeasurementGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationMeasurementIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementIdGet(id: string, options?: any): AxiosPromise<MeasurementTypeDto> {
            return localVarFp.apiV1AdministrationMeasurementIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MeasurementTypeRequestDto} [measurementTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementIdPost(id: string, measurementTypeRequestDto?: MeasurementTypeRequestDto, options?: any): AxiosPromise<MeasurementTypeDto> {
            return localVarFp.apiV1AdministrationMeasurementIdPost(id, measurementTypeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet(measurementTypeId: string, options?: any): AxiosPromise<Array<MeasurementValueTypeDto>> {
            return localVarFp.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet(measurementTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdDelete(measurementTypeId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdDelete(measurementTypeId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdGet(measurementTypeId: string, id: string, options?: any): AxiosPromise<MeasurementValueTypeDto> {
            return localVarFp.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdGet(measurementTypeId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {string} id 
         * @param {MeasurementValueTypeRequestDto} [measurementValueTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdPost(measurementTypeId: string, id: string, measurementValueTypeRequestDto?: MeasurementValueTypeRequestDto, options?: any): AxiosPromise<MeasurementValueTypeDto> {
            return localVarFp.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdPost(measurementTypeId, id, measurementValueTypeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} measurementTypeId 
         * @param {MeasurementValueTypeRequestDto} [measurementValueTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementMeasurementTypeIdValueTypesPut(measurementTypeId: string, measurementValueTypeRequestDto?: MeasurementValueTypeRequestDto, options?: any): AxiosPromise<MeasurementValueTypeDto> {
            return localVarFp.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesPut(measurementTypeId, measurementValueTypeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MeasurementTypeRequestDto} [measurementTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdministrationMeasurementPut(measurementTypeRequestDto?: MeasurementTypeRequestDto, options?: any): AxiosPromise<MeasurementTypeDto> {
            return localVarFp.apiV1AdministrationMeasurementPut(measurementTypeRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasurementTypeApi - object-oriented interface
 * @export
 * @class MeasurementTypeApi
 * @extends {BaseAPI}
 */
export class MeasurementTypeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementTypeApi
     */
    public apiV1AdministrationMeasurementGet(options?: AxiosRequestConfig) {
        return MeasurementTypeApiFp(this.configuration).apiV1AdministrationMeasurementGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementTypeApi
     */
    public apiV1AdministrationMeasurementIdDelete(id: string, options?: AxiosRequestConfig) {
        return MeasurementTypeApiFp(this.configuration).apiV1AdministrationMeasurementIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementTypeApi
     */
    public apiV1AdministrationMeasurementIdGet(id: string, options?: AxiosRequestConfig) {
        return MeasurementTypeApiFp(this.configuration).apiV1AdministrationMeasurementIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MeasurementTypeRequestDto} [measurementTypeRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementTypeApi
     */
    public apiV1AdministrationMeasurementIdPost(id: string, measurementTypeRequestDto?: MeasurementTypeRequestDto, options?: AxiosRequestConfig) {
        return MeasurementTypeApiFp(this.configuration).apiV1AdministrationMeasurementIdPost(id, measurementTypeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} measurementTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementTypeApi
     */
    public apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet(measurementTypeId: string, options?: AxiosRequestConfig) {
        return MeasurementTypeApiFp(this.configuration).apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet(measurementTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} measurementTypeId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementTypeApi
     */
    public apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdDelete(measurementTypeId: string, id: string, options?: AxiosRequestConfig) {
        return MeasurementTypeApiFp(this.configuration).apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdDelete(measurementTypeId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} measurementTypeId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementTypeApi
     */
    public apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdGet(measurementTypeId: string, id: string, options?: AxiosRequestConfig) {
        return MeasurementTypeApiFp(this.configuration).apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdGet(measurementTypeId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} measurementTypeId 
     * @param {string} id 
     * @param {MeasurementValueTypeRequestDto} [measurementValueTypeRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementTypeApi
     */
    public apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdPost(measurementTypeId: string, id: string, measurementValueTypeRequestDto?: MeasurementValueTypeRequestDto, options?: AxiosRequestConfig) {
        return MeasurementTypeApiFp(this.configuration).apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdPost(measurementTypeId, id, measurementValueTypeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} measurementTypeId 
     * @param {MeasurementValueTypeRequestDto} [measurementValueTypeRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementTypeApi
     */
    public apiV1AdministrationMeasurementMeasurementTypeIdValueTypesPut(measurementTypeId: string, measurementValueTypeRequestDto?: MeasurementValueTypeRequestDto, options?: AxiosRequestConfig) {
        return MeasurementTypeApiFp(this.configuration).apiV1AdministrationMeasurementMeasurementTypeIdValueTypesPut(measurementTypeId, measurementValueTypeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MeasurementTypeRequestDto} [measurementTypeRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementTypeApi
     */
    public apiV1AdministrationMeasurementPut(measurementTypeRequestDto?: MeasurementTypeRequestDto, options?: AxiosRequestConfig) {
        return MeasurementTypeApiFp(this.configuration).apiV1AdministrationMeasurementPut(measurementTypeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeasurementTypesApi - axios parameter creator
 * @export
 */
export const MeasurementTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeasurementTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/MeasurementTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasurementTypesApi - functional programming interface
 * @export
 */
export const MeasurementTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasurementTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeasurementTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeasurementTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeasurementTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeasurementTypesApi - factory interface
 * @export
 */
export const MeasurementTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasurementTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeasurementTypesGet(options?: any): AxiosPromise<Array<MeasurementTypeDto>> {
            return localVarFp.apiV1MeasurementTypesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasurementTypesApi - object-oriented interface
 * @export
 * @class MeasurementTypesApi
 * @extends {BaseAPI}
 */
export class MeasurementTypesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementTypesApi
     */
    public apiV1MeasurementTypesGet(options?: AxiosRequestConfig) {
        return MeasurementTypesApiFp(this.configuration).apiV1MeasurementTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationAuthorizeApi - axios parameter creator
 * @export
 */
export const OrganizationAuthorizeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationConfirmAccessPost: async (userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/confirmAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccessSubmitOtpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationConfirmNewAccountPost: async (userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/confirmNewAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccessSubmitOtpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [simulate] 
         * @param {UserAccessRequest} [userAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationRequestAccessPost: async (simulate?: boolean, userAccessRequest?: UserAccessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/requestAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (simulate !== undefined) {
                localVarQueryParameter['simulate'] = simulate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationAuthorizeApi - functional programming interface
 * @export
 */
export const OrganizationAuthorizeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationAuthorizeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationConfirmAccessPost(userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationConfirmAccessPost(userAccessSubmitOtpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationConfirmNewAccountPost(userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationConfirmNewAccountPost(userAccessSubmitOtpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [simulate] 
         * @param {UserAccessRequest} [userAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationRequestAccessPost(simulate?: boolean, userAccessRequest?: UserAccessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationRequestAccessPost(simulate, userAccessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationAuthorizeApi - factory interface
 * @export
 */
export const OrganizationAuthorizeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationAuthorizeApiFp(configuration)
    return {
        /**
         * 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationConfirmAccessPost(userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationConfirmAccessPost(userAccessSubmitOtpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationConfirmNewAccountPost(userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationConfirmNewAccountPost(userAccessSubmitOtpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [simulate] 
         * @param {UserAccessRequest} [userAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationRequestAccessPost(simulate?: boolean, userAccessRequest?: UserAccessRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationRequestAccessPost(simulate, userAccessRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationAuthorizeApi - object-oriented interface
 * @export
 * @class OrganizationAuthorizeApi
 * @extends {BaseAPI}
 */
export class OrganizationAuthorizeApi extends BaseAPI {
    /**
     * 
     * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAuthorizeApi
     */
    public apiV1OrganizationsMyAdministrationConfirmAccessPost(userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: AxiosRequestConfig) {
        return OrganizationAuthorizeApiFp(this.configuration).apiV1OrganizationsMyAdministrationConfirmAccessPost(userAccessSubmitOtpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessSubmitOtpRequest} [userAccessSubmitOtpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAuthorizeApi
     */
    public apiV1OrganizationsMyAdministrationConfirmNewAccountPost(userAccessSubmitOtpRequest?: UserAccessSubmitOtpRequest, options?: AxiosRequestConfig) {
        return OrganizationAuthorizeApiFp(this.configuration).apiV1OrganizationsMyAdministrationConfirmNewAccountPost(userAccessSubmitOtpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [simulate] 
     * @param {UserAccessRequest} [userAccessRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAuthorizeApi
     */
    public apiV1OrganizationsMyAdministrationRequestAccessPost(simulate?: boolean, userAccessRequest?: UserAccessRequest, options?: AxiosRequestConfig) {
        return OrganizationAuthorizeApiFp(this.configuration).apiV1OrganizationsMyAdministrationRequestAccessPost(simulate, userAccessRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationCaretakersApi - axios parameter creator
 * @export
 */
export const OrganizationCaretakersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersGet: async (pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdAttachPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdAttachPost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers/{id}/attach`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDelete', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDetachPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDetachPost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers/{id}/detach`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAssignPost: async (id: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAssignPost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers/{id}/patients/assign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAvailableGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAvailableGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers/{id}/patients/available`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers/{id}/patients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdAssignPost: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdAssignPost', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdAssignPost', 'patientId', patientId)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers/{id}/patients/{patientId}/assign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdRemovePost: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdRemovePost', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdRemovePost', 'patientId', patientId)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers/{id}/patients/{patientId}/remove`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsRemovePost: async (id: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsRemovePost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers/{id}/patients/remove`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersPost: async (caretakerCreateRequestDto?: CaretakerCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/caretakers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caretakerCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgCaretakersGet: async (pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/noOrg/caretakers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgCaretakersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationNoOrgCaretakersIdGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/noOrg/caretakers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationCaretakersApi - functional programming interface
 * @export
 */
export const OrganizationCaretakersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationCaretakersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersGet(pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersIdAttachPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdAttachPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDetachPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDetachPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAssignPost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAssignPost(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAvailableGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAvailableGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdAssignPost(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdAssignPost(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdRemovePost(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdRemovePost(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsRemovePost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsRemovePost(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgCaretakersPost(caretakerCreateRequestDto?: CaretakerCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgCaretakersPost(caretakerCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationNoOrgCaretakersGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationNoOrgCaretakersGet(pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationNoOrgCaretakersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaretakerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationNoOrgCaretakersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationCaretakersApi - factory interface
 * @export
 */
export const OrganizationCaretakersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationCaretakersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersGet(pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<CaretakerResponseDtoPagedList> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersGet(pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdAttachPost(id: string, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdAttachPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDetachPost(id: string, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDetachPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdGet(id: string, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAssignPost(id: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAssignPost(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAvailableGet(id: string, options?: any): AxiosPromise<Array<PatientResponseDto>> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAvailableGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet(id: string, options?: any): AxiosPromise<Array<PatientResponseDto>> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdAssignPost(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdAssignPost(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdRemovePost(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdRemovePost(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsRemovePost(id: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsRemovePost(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgCaretakersPost(caretakerCreateRequestDto?: CaretakerCreateRequestDto, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgCaretakersPost(caretakerCreateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgCaretakersGet(pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<CaretakerResponseDtoPagedList> {
            return localVarFp.apiV1OrganizationsMyAdministrationNoOrgCaretakersGet(pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgCaretakersIdGet(id: string, options?: any): AxiosPromise<CaretakerResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationNoOrgCaretakersIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationCaretakersApi - object-oriented interface
 * @export
 * @class OrganizationCaretakersApi
 * @extends {BaseAPI}
 */
export class OrganizationCaretakersApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersGet(pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersIdAttachPost(id: string, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersIdAttachPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDelete(id: string, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDetachPost(id: string, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersIdDetachPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersIdGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAssignPost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAssignPost(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAvailableGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsAvailableGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdAssignPost(id: string, patientId: string, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdAssignPost(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdRemovePost(id: string, patientId: string, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsPatientIdRemovePost(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsRemovePost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersIdPatientsRemovePost(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaretakerCreateRequestDto} [caretakerCreateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgCaretakersPost(caretakerCreateRequestDto?: CaretakerCreateRequestDto, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgCaretakersPost(caretakerCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationNoOrgCaretakersGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationNoOrgCaretakersGet(pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationCaretakersApi
     */
    public apiV1OrganizationsMyAdministrationNoOrgCaretakersIdGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationCaretakersApiFp(this.configuration).apiV1OrganizationsMyAdministrationNoOrgCaretakersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationDoctorsApi - axios parameter creator
 * @export
 */
export const OrganizationDoctorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsGet: async (pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdAttachPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdAttachPost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/attach`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAssignPost: async (id: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAssignPost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/caretakers/assign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAvailableGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAvailableGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/caretakers/available`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} caretakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdAssignPost: async (id: string, caretakerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdAssignPost', 'id', id)
            // verify required parameter 'caretakerId' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdAssignPost', 'caretakerId', caretakerId)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/caretakers/{caretakerId}/assign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"caretakerId"}}`, encodeURIComponent(String(caretakerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} caretakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdRemovePost: async (id: string, caretakerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdRemovePost', 'id', id)
            // verify required parameter 'caretakerId' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdRemovePost', 'caretakerId', caretakerId)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/caretakers/{caretakerId}/remove`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"caretakerId"}}`, encodeURIComponent(String(caretakerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/caretakers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersRemovePost: async (id: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersRemovePost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/caretakers/remove`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDelete', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDetachPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDetachPost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/detach`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdAssignPost: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdAssignPost', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdAssignPost', 'patientId', patientId)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/patient/{patientId}/assign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdRemovePost: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdRemovePost', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdRemovePost', 'patientId', patientId)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/patient/{patientId}/remove`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAssignPost: async (id: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAssignPost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/patients/assign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAvailableGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAvailableGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/patients/available`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/patients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsRemovePost: async (id: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsRemovePost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors/{id}/patients/remove`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DoctorRequestDto} [doctorRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsPost: async (doctorRequestDto?: DoctorRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/doctors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgDoctorsGet: async (pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/noOrg/doctors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgDoctorsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationNoOrgDoctorsIdGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/noOrg/doctors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationDoctorsApi - functional programming interface
 * @export
 */
export const OrganizationDoctorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationDoctorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsGet(pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdAttachPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdAttachPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAssignPost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAssignPost(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAvailableGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaretakerResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAvailableGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} caretakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdAssignPost(id: string, caretakerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdAssignPost(id, caretakerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} caretakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdRemovePost(id: string, caretakerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdRemovePost(id, caretakerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaretakerResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersRemovePost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersRemovePost(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDetachPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDetachPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdAssignPost(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdAssignPost(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdRemovePost(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdRemovePost(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAssignPost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAssignPost(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAvailableGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAvailableGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsRemovePost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsRemovePost(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DoctorRequestDto} [doctorRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgDoctorsPost(doctorRequestDto?: DoctorRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgDoctorsPost(doctorRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationNoOrgDoctorsGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationNoOrgDoctorsGet(pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationNoOrgDoctorsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationNoOrgDoctorsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationDoctorsApi - factory interface
 * @export
 */
export const OrganizationDoctorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationDoctorsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsGet(pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<DoctorResponseDtoPagedList> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsGet(pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdAttachPost(id: string, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdAttachPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAssignPost(id: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAssignPost(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAvailableGet(id: string, options?: any): AxiosPromise<Array<CaretakerResponseDto>> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAvailableGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} caretakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdAssignPost(id: string, caretakerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdAssignPost(id, caretakerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} caretakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdRemovePost(id: string, caretakerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdRemovePost(id, caretakerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet(id: string, options?: any): AxiosPromise<Array<CaretakerResponseDto>> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersRemovePost(id: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersRemovePost(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDetachPost(id: string, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDetachPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdGet(id: string, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdAssignPost(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdAssignPost(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdRemovePost(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdRemovePost(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAssignPost(id: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAssignPost(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAvailableGet(id: string, options?: any): AxiosPromise<Array<PatientResponseDto>> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAvailableGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet(id: string, options?: any): AxiosPromise<Array<PatientResponseDto>> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsRemovePost(id: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsRemovePost(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DoctorRequestDto} [doctorRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgDoctorsPost(doctorRequestDto?: DoctorRequestDto, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgDoctorsPost(doctorRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgDoctorsGet(pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<DoctorResponseDtoPagedList> {
            return localVarFp.apiV1OrganizationsMyAdministrationNoOrgDoctorsGet(pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgDoctorsIdGet(id: string, options?: any): AxiosPromise<DoctorResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationNoOrgDoctorsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationDoctorsApi - object-oriented interface
 * @export
 * @class OrganizationDoctorsApi
 * @extends {BaseAPI}
 */
export class OrganizationDoctorsApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsGet(pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdAttachPost(id: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdAttachPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAssignPost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAssignPost(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAvailableGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersAvailableGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} caretakerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdAssignPost(id: string, caretakerId: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdAssignPost(id, caretakerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} caretakerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdRemovePost(id: string, caretakerId: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersCaretakerIdRemovePost(id, caretakerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersRemovePost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdCaretakersRemovePost(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDelete(id: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDetachPost(id: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdDetachPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdAssignPost(id: string, patientId: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdAssignPost(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdRemovePost(id: string, patientId: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientPatientIdRemovePost(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAssignPost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAssignPost(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAvailableGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsAvailableGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsRemovePost(id: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsIdPatientsRemovePost(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DoctorRequestDto} [doctorRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgDoctorsPost(doctorRequestDto?: DoctorRequestDto, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgDoctorsPost(doctorRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationNoOrgDoctorsGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationNoOrgDoctorsGet(pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationDoctorsApi
     */
    public apiV1OrganizationsMyAdministrationNoOrgDoctorsIdGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationDoctorsApiFp(this.configuration).apiV1OrganizationsMyAdministrationNoOrgDoctorsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationPatientsApi - axios parameter creator
 * @export
 */
export const OrganizationPatientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsAvailableRawPhoneNumberGet: async (rawPhoneNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rawPhoneNumber' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgPatientsAvailableRawPhoneNumberGet', 'rawPhoneNumber', rawPhoneNumber)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/patients/available/{rawPhoneNumber}`
                .replace(`{${"rawPhoneNumber"}}`, encodeURIComponent(String(rawPhoneNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsByActivationCodeGet: async (code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/patients/byActivationCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {string} [guid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsGet: async (pageNumber?: number, pageSize?: number, q?: string, guid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (guid !== undefined) {
                localVarQueryParameter['guid'] = guid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsIdAttachPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgPatientsIdAttachPost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/patients/{id}/attach`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgPatientsIdDelete', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsIdDetachPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgPatientsIdDetachPost', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/patients/{id}/detach`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationMyOrgPatientsIdGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsPost: async (patientRequestDto?: PatientRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatientUpdateRequestDto} [patientUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsPut: async (patientUpdateRequestDto?: PatientUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/myOrg/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgPatientsGet: async (pageNumber?: number, pageSize?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/my/administration/noOrg/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgPatientsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1OrganizationsMyAdministrationNoOrgPatientsIdGet', 'id', id)
            const localVarPath = `/api/v1/organizations/my/administration/noOrg/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationPatientsApi - functional programming interface
 * @export
 */
export const OrganizationPatientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationPatientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgPatientsAvailableRawPhoneNumberGet(rawPhoneNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgPatientsAvailableRawPhoneNumberGet(rawPhoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgPatientsByActivationCodeGet(code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgPatientsByActivationCodeGet(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {string} [guid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgPatientsGet(pageNumber?: number, pageSize?: number, q?: string, guid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgPatientsGet(pageNumber, pageSize, q, guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgPatientsIdAttachPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgPatientsIdAttachPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgPatientsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgPatientsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgPatientsIdDetachPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgPatientsIdDetachPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgPatientsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgPatientsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgPatientsPost(patientRequestDto?: PatientRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgPatientsPost(patientRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatientUpdateRequestDto} [patientUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationMyOrgPatientsPut(patientUpdateRequestDto?: PatientUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationMyOrgPatientsPut(patientUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationNoOrgPatientsGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDtoPagedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationNoOrgPatientsGet(pageNumber, pageSize, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationsMyAdministrationNoOrgPatientsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationsMyAdministrationNoOrgPatientsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationPatientsApi - factory interface
 * @export
 */
export const OrganizationPatientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationPatientsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} rawPhoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsAvailableRawPhoneNumberGet(rawPhoneNumber: string, options?: any): AxiosPromise<PatientAvailability> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgPatientsAvailableRawPhoneNumberGet(rawPhoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsByActivationCodeGet(code?: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgPatientsByActivationCodeGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {string} [guid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsGet(pageNumber?: number, pageSize?: number, q?: string, guid?: string, options?: any): AxiosPromise<PatientResponseDtoPagedList> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgPatientsGet(pageNumber, pageSize, q, guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsIdAttachPost(id: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgPatientsIdAttachPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsIdDelete(id: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgPatientsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsIdDetachPost(id: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgPatientsIdDetachPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsIdGet(id: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgPatientsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsPost(patientRequestDto?: PatientRequestDto, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgPatientsPost(patientRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatientUpdateRequestDto} [patientUpdateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationMyOrgPatientsPut(patientUpdateRequestDto?: PatientUpdateRequestDto, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationMyOrgPatientsPut(patientUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgPatientsGet(pageNumber?: number, pageSize?: number, q?: string, options?: any): AxiosPromise<PatientResponseDtoPagedList> {
            return localVarFp.apiV1OrganizationsMyAdministrationNoOrgPatientsGet(pageNumber, pageSize, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationsMyAdministrationNoOrgPatientsIdGet(id: string, options?: any): AxiosPromise<PatientResponseDto> {
            return localVarFp.apiV1OrganizationsMyAdministrationNoOrgPatientsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationPatientsApi - object-oriented interface
 * @export
 * @class OrganizationPatientsApi
 * @extends {BaseAPI}
 */
export class OrganizationPatientsApi extends BaseAPI {
    /**
     * 
     * @param {string} rawPhoneNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationPatientsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgPatientsAvailableRawPhoneNumberGet(rawPhoneNumber: string, options?: AxiosRequestConfig) {
        return OrganizationPatientsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgPatientsAvailableRawPhoneNumberGet(rawPhoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationPatientsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgPatientsByActivationCodeGet(code?: string, options?: AxiosRequestConfig) {
        return OrganizationPatientsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgPatientsByActivationCodeGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {string} [guid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationPatientsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgPatientsGet(pageNumber?: number, pageSize?: number, q?: string, guid?: string, options?: AxiosRequestConfig) {
        return OrganizationPatientsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgPatientsGet(pageNumber, pageSize, q, guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationPatientsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgPatientsIdAttachPost(id: string, options?: AxiosRequestConfig) {
        return OrganizationPatientsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgPatientsIdAttachPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationPatientsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgPatientsIdDelete(id: string, options?: AxiosRequestConfig) {
        return OrganizationPatientsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgPatientsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationPatientsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgPatientsIdDetachPost(id: string, options?: AxiosRequestConfig) {
        return OrganizationPatientsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgPatientsIdDetachPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationPatientsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgPatientsIdGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationPatientsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgPatientsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatientRequestDto} [patientRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationPatientsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgPatientsPost(patientRequestDto?: PatientRequestDto, options?: AxiosRequestConfig) {
        return OrganizationPatientsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgPatientsPost(patientRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatientUpdateRequestDto} [patientUpdateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationPatientsApi
     */
    public apiV1OrganizationsMyAdministrationMyOrgPatientsPut(patientUpdateRequestDto?: PatientUpdateRequestDto, options?: AxiosRequestConfig) {
        return OrganizationPatientsApiFp(this.configuration).apiV1OrganizationsMyAdministrationMyOrgPatientsPut(patientUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationPatientsApi
     */
    public apiV1OrganizationsMyAdministrationNoOrgPatientsGet(pageNumber?: number, pageSize?: number, q?: string, options?: AxiosRequestConfig) {
        return OrganizationPatientsApiFp(this.configuration).apiV1OrganizationsMyAdministrationNoOrgPatientsGet(pageNumber, pageSize, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationPatientsApi
     */
    public apiV1OrganizationsMyAdministrationNoOrgPatientsIdGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationPatientsApiFp(this.configuration).apiV1OrganizationsMyAdministrationNoOrgPatientsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OrganizationFilter} [organizationFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationMyCaretakersPost: async (organizationFilter?: OrganizationFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organization/my/caretakers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganizationFilter} [organizationFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationMyPatientsPost: async (organizationFilter?: OrganizationFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organization/my/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OrganizationFilter} [organizationFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationMyCaretakersPost(organizationFilter?: OrganizationFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaretakerResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationMyCaretakersPost(organizationFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrganizationFilter} [organizationFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OrganizationMyPatientsPost(organizationFilter?: OrganizationFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OrganizationMyPatientsPost(organizationFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @param {OrganizationFilter} [organizationFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationMyCaretakersPost(organizationFilter?: OrganizationFilter, options?: any): AxiosPromise<Array<CaretakerResponseDto>> {
            return localVarFp.apiV1OrganizationMyCaretakersPost(organizationFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganizationFilter} [organizationFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OrganizationMyPatientsPost(organizationFilter?: OrganizationFilter, options?: any): AxiosPromise<Array<PatientResponseDto>> {
            return localVarFp.apiV1OrganizationMyPatientsPost(organizationFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * 
     * @param {OrganizationFilter} [organizationFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public apiV1OrganizationMyCaretakersPost(organizationFilter?: OrganizationFilter, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).apiV1OrganizationMyCaretakersPost(organizationFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganizationFilter} [organizationFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public apiV1OrganizationMyPatientsPost(organizationFilter?: OrganizationFilter, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).apiV1OrganizationMyPatientsPost(organizationFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientDashboardApi - axios parameter creator
 * @export
 */
export const PatientDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdDashboardGet: async (patientId: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdDashboardGet', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/dashboard`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientDashboardApi - functional programming interface
 * @export
 */
export const PatientDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdDashboardGet(patientId: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDashboardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdDashboardGet(patientId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientDashboardApi - factory interface
 * @export
 */
export const PatientDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientDashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdDashboardGet(patientId: string, date?: string, options?: any): AxiosPromise<PatientDashboardDto> {
            return localVarFp.apiV1PatientPatientIdDashboardGet(patientId, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientDashboardApi - object-oriented interface
 * @export
 * @class PatientDashboardApi
 * @extends {BaseAPI}
 */
export class PatientDashboardApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientDashboardApi
     */
    public apiV1PatientPatientIdDashboardGet(patientId: string, date?: string, options?: AxiosRequestConfig) {
        return PatientDashboardApiFp(this.configuration).apiV1PatientPatientIdDashboardGet(patientId, date, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientMedicationsApi - axios parameter creator
 * @export
 */
export const PatientMedicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsGet: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsGet', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/medications`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdDelete: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdDelete', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdDelete', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/medications/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdGet: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdGet', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdGet', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/medications/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdImageDelete: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdImageDelete', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdImageDelete', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/medications/{id}/image`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdImageGet: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdImageGet', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdImageGet', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/medications/{id}/image`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdImagePost: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdImagePost', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdImagePost', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/medications/{id}/image`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {MedicationRequestDto} [medicationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdPut: async (patientId: string, id: string, medicationRequestDto?: MedicationRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdPut', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsIdPut', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/medications/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [name] 
         * @param {string} [instructions] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsPost: async (patientId: string, name?: string, instructions?: string, mimeType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdMedicationsPost', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/medications`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (instructions !== undefined) {
                localVarQueryParameter['instructions'] = instructions;
            }

            if (mimeType !== undefined) {
                localVarQueryParameter['mimeType'] = mimeType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientMedicationsApi - functional programming interface
 * @export
 */
export const PatientMedicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientMedicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdMedicationsGet(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedicationResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdMedicationsGet(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdMedicationsIdDelete(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdMedicationsIdDelete(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdMedicationsIdGet(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdMedicationsIdGet(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdMedicationsIdImageDelete(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdMedicationsIdImageDelete(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdMedicationsIdImageGet(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdMedicationsIdImageGet(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdMedicationsIdImagePost(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdMedicationsIdImagePost(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {MedicationRequestDto} [medicationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdMedicationsIdPut(patientId: string, id: string, medicationRequestDto?: MedicationRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdMedicationsIdPut(patientId, id, medicationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [name] 
         * @param {string} [instructions] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdMedicationsPost(patientId: string, name?: string, instructions?: string, mimeType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdMedicationsPost(patientId, name, instructions, mimeType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientMedicationsApi - factory interface
 * @export
 */
export const PatientMedicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientMedicationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsGet(patientId: string, options?: any): AxiosPromise<Array<MedicationResponseDto>> {
            return localVarFp.apiV1PatientPatientIdMedicationsGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdDelete(patientId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientPatientIdMedicationsIdDelete(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdGet(patientId: string, id: string, options?: any): AxiosPromise<MedicationResponseDto> {
            return localVarFp.apiV1PatientPatientIdMedicationsIdGet(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdImageDelete(patientId: string, id: string, options?: any): AxiosPromise<MedicationResponseDto> {
            return localVarFp.apiV1PatientPatientIdMedicationsIdImageDelete(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdImageGet(patientId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientPatientIdMedicationsIdImageGet(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdImagePost(patientId: string, id: string, options?: any): AxiosPromise<MedicationResponseDto> {
            return localVarFp.apiV1PatientPatientIdMedicationsIdImagePost(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {MedicationRequestDto} [medicationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsIdPut(patientId: string, id: string, medicationRequestDto?: MedicationRequestDto, options?: any): AxiosPromise<MedicationResponseDto> {
            return localVarFp.apiV1PatientPatientIdMedicationsIdPut(patientId, id, medicationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [name] 
         * @param {string} [instructions] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdMedicationsPost(patientId: string, name?: string, instructions?: string, mimeType?: string, options?: any): AxiosPromise<MedicationResponseDto> {
            return localVarFp.apiV1PatientPatientIdMedicationsPost(patientId, name, instructions, mimeType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientMedicationsApi - object-oriented interface
 * @export
 * @class PatientMedicationsApi
 * @extends {BaseAPI}
 */
export class PatientMedicationsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientMedicationsApi
     */
    public apiV1PatientPatientIdMedicationsGet(patientId: string, options?: AxiosRequestConfig) {
        return PatientMedicationsApiFp(this.configuration).apiV1PatientPatientIdMedicationsGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientMedicationsApi
     */
    public apiV1PatientPatientIdMedicationsIdDelete(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientMedicationsApiFp(this.configuration).apiV1PatientPatientIdMedicationsIdDelete(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientMedicationsApi
     */
    public apiV1PatientPatientIdMedicationsIdGet(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientMedicationsApiFp(this.configuration).apiV1PatientPatientIdMedicationsIdGet(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientMedicationsApi
     */
    public apiV1PatientPatientIdMedicationsIdImageDelete(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientMedicationsApiFp(this.configuration).apiV1PatientPatientIdMedicationsIdImageDelete(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientMedicationsApi
     */
    public apiV1PatientPatientIdMedicationsIdImageGet(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientMedicationsApiFp(this.configuration).apiV1PatientPatientIdMedicationsIdImageGet(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientMedicationsApi
     */
    public apiV1PatientPatientIdMedicationsIdImagePost(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientMedicationsApiFp(this.configuration).apiV1PatientPatientIdMedicationsIdImagePost(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {MedicationRequestDto} [medicationRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientMedicationsApi
     */
    public apiV1PatientPatientIdMedicationsIdPut(patientId: string, id: string, medicationRequestDto?: MedicationRequestDto, options?: AxiosRequestConfig) {
        return PatientMedicationsApiFp(this.configuration).apiV1PatientPatientIdMedicationsIdPut(patientId, id, medicationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} [name] 
     * @param {string} [instructions] 
     * @param {string} [mimeType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientMedicationsApi
     */
    public apiV1PatientPatientIdMedicationsPost(patientId: string, name?: string, instructions?: string, mimeType?: string, options?: AxiosRequestConfig) {
        return PatientMedicationsApiFp(this.configuration).apiV1PatientPatientIdMedicationsPost(patientId, name, instructions, mimeType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientNotesApi - axios parameter creator
 * @export
 */
export const PatientNotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesGet: async (patientId: string, start?: string, end?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientNotesGet', 'patientId', patientId)
            const localVarPath = `/api/v1/PatientNotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} ownerId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesOwnerOwnerIdGet: async (patientId: string, ownerId: string, start?: string, end?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientNotesOwnerOwnerIdGet', 'patientId', patientId)
            // verify required parameter 'ownerId' is not null or undefined
            assertParamExists('apiV1PatientNotesOwnerOwnerIdGet', 'ownerId', ownerId)
            const localVarPath = `/api/v1/PatientNotes/owner/{ownerId}`
                .replace(`{${"ownerId"}}`, encodeURIComponent(String(ownerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdDelete: async (patientNoteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientNoteId' is not null or undefined
            assertParamExists('apiV1PatientNotesPatientNoteIdDelete', 'patientNoteId', patientNoteId)
            const localVarPath = `/api/v1/PatientNotes/{patientNoteId}`
                .replace(`{${"patientNoteId"}}`, encodeURIComponent(String(patientNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdImageImageIdDelete: async (patientNoteId: string, imageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientNoteId' is not null or undefined
            assertParamExists('apiV1PatientNotesPatientNoteIdImageImageIdDelete', 'patientNoteId', patientNoteId)
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('apiV1PatientNotesPatientNoteIdImageImageIdDelete', 'imageId', imageId)
            const localVarPath = `/api/v1/PatientNotes/{patientNoteId}/image/{imageId}`
                .replace(`{${"patientNoteId"}}`, encodeURIComponent(String(patientNoteId)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdImageImageIdGet: async (patientNoteId: string, imageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientNoteId' is not null or undefined
            assertParamExists('apiV1PatientNotesPatientNoteIdImageImageIdGet', 'patientNoteId', patientNoteId)
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('apiV1PatientNotesPatientNoteIdImageImageIdGet', 'imageId', imageId)
            const localVarPath = `/api/v1/PatientNotes/{patientNoteId}/image/{imageId}`
                .replace(`{${"patientNoteId"}}`, encodeURIComponent(String(patientNoteId)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdImagePost: async (patientNoteId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientNoteId' is not null or undefined
            assertParamExists('apiV1PatientNotesPatientNoteIdImagePost', 'patientNoteId', patientNoteId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1PatientNotesPatientNoteIdImagePost', 'file', file)
            const localVarPath = `/api/v1/PatientNotes/{patientNoteId}/image`
                .replace(`{${"patientNoteId"}}`, encodeURIComponent(String(patientNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {PatientNoteRequestDto} [patientNoteRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdPost: async (patientNoteId: string, patientNoteRequestDto?: PatientNoteRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientNoteId' is not null or undefined
            assertParamExists('apiV1PatientNotesPatientNoteIdPost', 'patientNoteId', patientNoteId)
            const localVarPath = `/api/v1/PatientNotes/{patientNoteId}`
                .replace(`{${"patientNoteId"}}`, encodeURIComponent(String(patientNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientNoteRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {string} ownerId 
         * @param {UserType} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdSetViewedGet: async (patientNoteId: string, ownerId: string, ownerType: UserType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientNoteId' is not null or undefined
            assertParamExists('apiV1PatientNotesPatientNoteIdSetViewedGet', 'patientNoteId', patientNoteId)
            // verify required parameter 'ownerId' is not null or undefined
            assertParamExists('apiV1PatientNotesPatientNoteIdSetViewedGet', 'ownerId', ownerId)
            // verify required parameter 'ownerType' is not null or undefined
            assertParamExists('apiV1PatientNotesPatientNoteIdSetViewedGet', 'ownerType', ownerType)
            const localVarPath = `/api/v1/PatientNotes/{patientNoteId}/setViewed`
                .replace(`{${"patientNoteId"}}`, encodeURIComponent(String(patientNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['ownerType'] = ownerType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatientNoteRequestDto} [patientNoteRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPut: async (patientNoteRequestDto?: PatientNoteRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PatientNotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientNoteRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} ownerId 
         * @param {UserType} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesSetViewedAllGet: async (patientId: string, ownerId: string, ownerType: UserType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientNotesSetViewedAllGet', 'patientId', patientId)
            // verify required parameter 'ownerId' is not null or undefined
            assertParamExists('apiV1PatientNotesSetViewedAllGet', 'ownerId', ownerId)
            // verify required parameter 'ownerType' is not null or undefined
            assertParamExists('apiV1PatientNotesSetViewedAllGet', 'ownerType', ownerType)
            const localVarPath = `/api/v1/PatientNotes/setViewedAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['ownerType'] = ownerType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} ownerId 
         * @param {UserType} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesUnViewedGet: async (patientId: string, ownerId: string, ownerType: UserType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientNotesUnViewedGet', 'patientId', patientId)
            // verify required parameter 'ownerId' is not null or undefined
            assertParamExists('apiV1PatientNotesUnViewedGet', 'ownerId', ownerId)
            // verify required parameter 'ownerType' is not null or undefined
            assertParamExists('apiV1PatientNotesUnViewedGet', 'ownerType', ownerType)
            const localVarPath = `/api/v1/PatientNotes/unViewed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['ownerType'] = ownerType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientNotesApi - functional programming interface
 * @export
 */
export const PatientNotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientNotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientNotesGet(patientId: string, start?: string, end?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientNoteResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientNotesGet(patientId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} ownerId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientNotesOwnerOwnerIdGet(patientId: string, ownerId: string, start?: string, end?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientNoteGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientNotesOwnerOwnerIdGet(patientId, ownerId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientNotesPatientNoteIdDelete(patientNoteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientNotesPatientNoteIdDelete(patientNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientNotesPatientNoteIdImageImageIdDelete(patientNoteId: string, imageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientNotesPatientNoteIdImageImageIdDelete(patientNoteId, imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientNotesPatientNoteIdImageImageIdGet(patientNoteId: string, imageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientNotesPatientNoteIdImageImageIdGet(patientNoteId, imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientNotesPatientNoteIdImagePost(patientNoteId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientNotesPatientNoteIdImagePost(patientNoteId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {PatientNoteRequestDto} [patientNoteRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientNotesPatientNoteIdPost(patientNoteId: string, patientNoteRequestDto?: PatientNoteRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNoteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientNotesPatientNoteIdPost(patientNoteId, patientNoteRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {string} ownerId 
         * @param {UserType} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientNotesPatientNoteIdSetViewedGet(patientNoteId: string, ownerId: string, ownerType: UserType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientNotesPatientNoteIdSetViewedGet(patientNoteId, ownerId, ownerType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatientNoteRequestDto} [patientNoteRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientNotesPut(patientNoteRequestDto?: PatientNoteRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNoteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientNotesPut(patientNoteRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} ownerId 
         * @param {UserType} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientNotesSetViewedAllGet(patientId: string, ownerId: string, ownerType: UserType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientNotesSetViewedAllGet(patientId, ownerId, ownerType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} ownerId 
         * @param {UserType} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientNotesUnViewedGet(patientId: string, ownerId: string, ownerType: UserType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientNotesUnViewedGet(patientId, ownerId, ownerType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientNotesApi - factory interface
 * @export
 */
export const PatientNotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientNotesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesGet(patientId: string, start?: string, end?: string, options?: any): AxiosPromise<Array<PatientNoteResponseDto>> {
            return localVarFp.apiV1PatientNotesGet(patientId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} ownerId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesOwnerOwnerIdGet(patientId: string, ownerId: string, start?: string, end?: string, options?: any): AxiosPromise<Array<PatientNoteGroup>> {
            return localVarFp.apiV1PatientNotesOwnerOwnerIdGet(patientId, ownerId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdDelete(patientNoteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientNotesPatientNoteIdDelete(patientNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdImageImageIdDelete(patientNoteId: string, imageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientNotesPatientNoteIdImageImageIdDelete(patientNoteId, imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdImageImageIdGet(patientNoteId: string, imageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientNotesPatientNoteIdImageImageIdGet(patientNoteId, imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdImagePost(patientNoteId: string, file: File, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1PatientNotesPatientNoteIdImagePost(patientNoteId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {PatientNoteRequestDto} [patientNoteRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdPost(patientNoteId: string, patientNoteRequestDto?: PatientNoteRequestDto, options?: any): AxiosPromise<PatientNoteResponseDto> {
            return localVarFp.apiV1PatientNotesPatientNoteIdPost(patientNoteId, patientNoteRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientNoteId 
         * @param {string} ownerId 
         * @param {UserType} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPatientNoteIdSetViewedGet(patientNoteId: string, ownerId: string, ownerType: UserType, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientNotesPatientNoteIdSetViewedGet(patientNoteId, ownerId, ownerType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatientNoteRequestDto} [patientNoteRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesPut(patientNoteRequestDto?: PatientNoteRequestDto, options?: any): AxiosPromise<PatientNoteResponseDto> {
            return localVarFp.apiV1PatientNotesPut(patientNoteRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} ownerId 
         * @param {UserType} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesSetViewedAllGet(patientId: string, ownerId: string, ownerType: UserType, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientNotesSetViewedAllGet(patientId, ownerId, ownerType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} ownerId 
         * @param {UserType} ownerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientNotesUnViewedGet(patientId: string, ownerId: string, ownerType: UserType, options?: any): AxiosPromise<number> {
            return localVarFp.apiV1PatientNotesUnViewedGet(patientId, ownerId, ownerType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientNotesApi - object-oriented interface
 * @export
 * @class PatientNotesApi
 * @extends {BaseAPI}
 */
export class PatientNotesApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientNotesApi
     */
    public apiV1PatientNotesGet(patientId: string, start?: string, end?: string, options?: AxiosRequestConfig) {
        return PatientNotesApiFp(this.configuration).apiV1PatientNotesGet(patientId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} ownerId 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientNotesApi
     */
    public apiV1PatientNotesOwnerOwnerIdGet(patientId: string, ownerId: string, start?: string, end?: string, options?: AxiosRequestConfig) {
        return PatientNotesApiFp(this.configuration).apiV1PatientNotesOwnerOwnerIdGet(patientId, ownerId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientNotesApi
     */
    public apiV1PatientNotesPatientNoteIdDelete(patientNoteId: string, options?: AxiosRequestConfig) {
        return PatientNotesApiFp(this.configuration).apiV1PatientNotesPatientNoteIdDelete(patientNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientNoteId 
     * @param {string} imageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientNotesApi
     */
    public apiV1PatientNotesPatientNoteIdImageImageIdDelete(patientNoteId: string, imageId: string, options?: AxiosRequestConfig) {
        return PatientNotesApiFp(this.configuration).apiV1PatientNotesPatientNoteIdImageImageIdDelete(patientNoteId, imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientNoteId 
     * @param {string} imageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientNotesApi
     */
    public apiV1PatientNotesPatientNoteIdImageImageIdGet(patientNoteId: string, imageId: string, options?: AxiosRequestConfig) {
        return PatientNotesApiFp(this.configuration).apiV1PatientNotesPatientNoteIdImageImageIdGet(patientNoteId, imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientNoteId 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientNotesApi
     */
    public apiV1PatientNotesPatientNoteIdImagePost(patientNoteId: string, file: File, options?: AxiosRequestConfig) {
        return PatientNotesApiFp(this.configuration).apiV1PatientNotesPatientNoteIdImagePost(patientNoteId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientNoteId 
     * @param {PatientNoteRequestDto} [patientNoteRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientNotesApi
     */
    public apiV1PatientNotesPatientNoteIdPost(patientNoteId: string, patientNoteRequestDto?: PatientNoteRequestDto, options?: AxiosRequestConfig) {
        return PatientNotesApiFp(this.configuration).apiV1PatientNotesPatientNoteIdPost(patientNoteId, patientNoteRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientNoteId 
     * @param {string} ownerId 
     * @param {UserType} ownerType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientNotesApi
     */
    public apiV1PatientNotesPatientNoteIdSetViewedGet(patientNoteId: string, ownerId: string, ownerType: UserType, options?: AxiosRequestConfig) {
        return PatientNotesApiFp(this.configuration).apiV1PatientNotesPatientNoteIdSetViewedGet(patientNoteId, ownerId, ownerType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatientNoteRequestDto} [patientNoteRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientNotesApi
     */
    public apiV1PatientNotesPut(patientNoteRequestDto?: PatientNoteRequestDto, options?: AxiosRequestConfig) {
        return PatientNotesApiFp(this.configuration).apiV1PatientNotesPut(patientNoteRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} ownerId 
     * @param {UserType} ownerType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientNotesApi
     */
    public apiV1PatientNotesSetViewedAllGet(patientId: string, ownerId: string, ownerType: UserType, options?: AxiosRequestConfig) {
        return PatientNotesApiFp(this.configuration).apiV1PatientNotesSetViewedAllGet(patientId, ownerId, ownerType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} ownerId 
     * @param {UserType} ownerType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientNotesApi
     */
    public apiV1PatientNotesUnViewedGet(patientId: string, ownerId: string, ownerType: UserType, options?: AxiosRequestConfig) {
        return PatientNotesApiFp(this.configuration).apiV1PatientNotesUnViewedGet(patientId, ownerId, ownerType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientOwnMedicationsApi - axios parameter creator
 * @export
 */
export const PatientOwnMedicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeMedicationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MeMedicationsIdGet', 'id', id)
            const localVarPath = `/api/v1/me/medications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeMedicationsIdImageGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MeMedicationsIdImageGet', 'id', id)
            const localVarPath = `/api/v1/me/medications/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientOwnMedicationsApi - functional programming interface
 * @export
 */
export const PatientOwnMedicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientOwnMedicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeMedicationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeMedicationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeMedicationsIdImageGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeMedicationsIdImageGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientOwnMedicationsApi - factory interface
 * @export
 */
export const PatientOwnMedicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientOwnMedicationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeMedicationsIdGet(id: string, options?: any): AxiosPromise<MedicationResponseDto> {
            return localVarFp.apiV1MeMedicationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeMedicationsIdImageGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MeMedicationsIdImageGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientOwnMedicationsApi - object-oriented interface
 * @export
 * @class PatientOwnMedicationsApi
 * @extends {BaseAPI}
 */
export class PatientOwnMedicationsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientOwnMedicationsApi
     */
    public apiV1MeMedicationsIdGet(id: string, options?: AxiosRequestConfig) {
        return PatientOwnMedicationsApiFp(this.configuration).apiV1MeMedicationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientOwnMedicationsApi
     */
    public apiV1MeMedicationsIdImageGet(id: string, options?: AxiosRequestConfig) {
        return PatientOwnMedicationsApiFp(this.configuration).apiV1MeMedicationsIdImageGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientOwnTreatmentsApi - axios parameter creator
 * @export
 */
export const PatientOwnTreatmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeTreatmentsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MeTreatmentsIdGet', 'id', id)
            const localVarPath = `/api/v1/me/treatments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeTreatmentsIdImageGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MeTreatmentsIdImageGet', 'id', id)
            const localVarPath = `/api/v1/me/treatments/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientOwnTreatmentsApi - functional programming interface
 * @export
 */
export const PatientOwnTreatmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientOwnTreatmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeTreatmentsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeTreatmentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeTreatmentsIdImageGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeTreatmentsIdImageGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientOwnTreatmentsApi - factory interface
 * @export
 */
export const PatientOwnTreatmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientOwnTreatmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeTreatmentsIdGet(id: string, options?: any): AxiosPromise<TreatmentResponseDto> {
            return localVarFp.apiV1MeTreatmentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeTreatmentsIdImageGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MeTreatmentsIdImageGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientOwnTreatmentsApi - object-oriented interface
 * @export
 * @class PatientOwnTreatmentsApi
 * @extends {BaseAPI}
 */
export class PatientOwnTreatmentsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientOwnTreatmentsApi
     */
    public apiV1MeTreatmentsIdGet(id: string, options?: AxiosRequestConfig) {
        return PatientOwnTreatmentsApiFp(this.configuration).apiV1MeTreatmentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientOwnTreatmentsApi
     */
    public apiV1MeTreatmentsIdImageGet(id: string, options?: AxiosRequestConfig) {
        return PatientOwnTreatmentsApiFp(this.configuration).apiV1MeTreatmentsIdImageGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientScheduleResultApi - axios parameter creator
 * @export
 */
export const PatientScheduleResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ScheduleItemType} [scheduleItemType] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientMeScheduleGet: async (scheduleItemType?: ScheduleItemType, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/patient/me/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (scheduleItemType !== undefined) {
                localVarQueryParameter['scheduleItemType'] = scheduleItemType;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientMeScheduleResultIdAudioGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientMeScheduleResultIdAudioGet', 'id', id)
            const localVarPath = `/api/v1/patient/me/scheduleResult/{id}/audio`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientMeScheduleResultIdImageGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientMeScheduleResultIdImageGet', 'id', id)
            const localVarPath = `/api/v1/patient/me/scheduleResult/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatientScheduleItemResultRequestDto} [patientScheduleItemResultRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientMeScheduleResultIdPost: async (id: string, patientScheduleItemResultRequestDto?: PatientScheduleItemResultRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientMeScheduleResultIdPost', 'id', id)
            const localVarPath = `/api/v1/patient/me/scheduleResult/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientScheduleItemResultRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {DateRange} dateRange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdAnalyticsHappinessGet: async (patientId: string, dateRange: DateRange, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdAnalyticsHappinessGet', 'patientId', patientId)
            // verify required parameter 'dateRange' is not null or undefined
            assertParamExists('apiV1PatientPatientIdAnalyticsHappinessGet', 'dateRange', dateRange)
            const localVarPath = `/api/v1/patient/{patientId}/analytics/happiness`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (dateRange !== undefined) {
                localVarQueryParameter['dateRange'] = dateRange;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} measurementTypeId 
         * @param {DateRange} dateRange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdAnalyticsMeasurementGet: async (patientId: string, measurementTypeId: string, dateRange: DateRange, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdAnalyticsMeasurementGet', 'patientId', patientId)
            // verify required parameter 'measurementTypeId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdAnalyticsMeasurementGet', 'measurementTypeId', measurementTypeId)
            // verify required parameter 'dateRange' is not null or undefined
            assertParamExists('apiV1PatientPatientIdAnalyticsMeasurementGet', 'dateRange', dateRange)
            const localVarPath = `/api/v1/patient/{patientId}/analytics/measurement`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (measurementTypeId !== undefined) {
                localVarQueryParameter['measurementTypeId'] = measurementTypeId;
            }

            if (dateRange !== undefined) {
                localVarQueryParameter['dateRange'] = dateRange;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {ScheduleItemType} [scheduleItemType] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleGet: async (patientId: string, scheduleItemType?: ScheduleItemType, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleGet', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/schedule`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (scheduleItemType !== undefined) {
                localVarQueryParameter['scheduleItemType'] = scheduleItemType;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleItemIdAudioGet: async (patientId: string, itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleItemIdAudioGet', 'patientId', patientId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleItemIdAudioGet', 'itemId', itemId)
            const localVarPath = `/api/v1/patient/{patientId}/schedule/{itemId}/audio`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleItemIdAudioResponseGet: async (patientId: string, itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleItemIdAudioResponseGet', 'patientId', patientId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleItemIdAudioResponseGet', 'itemId', itemId)
            const localVarPath = `/api/v1/patient/{patientId}/schedule/{itemId}/audioResponse`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleItemIdGet: async (patientId: string, itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleItemIdGet', 'patientId', patientId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleItemIdGet', 'itemId', itemId)
            const localVarPath = `/api/v1/patient/{patientId}/schedule/{itemId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleItemIdImageGet: async (patientId: string, itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleItemIdImageGet', 'patientId', patientId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleItemIdImageGet', 'itemId', itemId)
            const localVarPath = `/api/v1/patient/{patientId}/schedule/{itemId}/image`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {PatientScheduleItemResultRequestDto} [patientScheduleItemResultRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleResultIdPost: async (patientId: string, id: string, patientScheduleItemResultRequestDto?: PatientScheduleItemResultRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleResultIdPost', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleResultIdPost', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleResult/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientScheduleItemResultRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientScheduleResultApi - functional programming interface
 * @export
 */
export const PatientScheduleResultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientScheduleResultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ScheduleItemType} [scheduleItemType] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientMeScheduleGet(scheduleItemType?: ScheduleItemType, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientScheduleItemResultResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientMeScheduleGet(scheduleItemType, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientMeScheduleResultIdAudioGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientMeScheduleResultIdAudioGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientMeScheduleResultIdImageGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientMeScheduleResultIdImageGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PatientScheduleItemResultRequestDto} [patientScheduleItemResultRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientMeScheduleResultIdPost(id: string, patientScheduleItemResultRequestDto?: PatientScheduleItemResultRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientScheduleItemResultResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientMeScheduleResultIdPost(id, patientScheduleItemResultRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {DateRange} dateRange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdAnalyticsHappinessGet(patientId: string, dateRange: DateRange, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HappinessAnalyticsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdAnalyticsHappinessGet(patientId, dateRange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} measurementTypeId 
         * @param {DateRange} dateRange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdAnalyticsMeasurementGet(patientId: string, measurementTypeId: string, dateRange: DateRange, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeasurementAnalyticResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdAnalyticsMeasurementGet(patientId, measurementTypeId, dateRange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {ScheduleItemType} [scheduleItemType] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleGet(patientId: string, scheduleItemType?: ScheduleItemType, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientScheduleItemResultResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleGet(patientId, scheduleItemType, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleItemIdAudioGet(patientId: string, itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleItemIdAudioGet(patientId, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleItemIdAudioResponseGet(patientId: string, itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleItemIdAudioResponseGet(patientId, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleItemIdGet(patientId: string, itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientScheduleItemResultFullResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleItemIdGet(patientId, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleItemIdImageGet(patientId: string, itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleItemIdImageGet(patientId, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {PatientScheduleItemResultRequestDto} [patientScheduleItemResultRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleResultIdPost(patientId: string, id: string, patientScheduleItemResultRequestDto?: PatientScheduleItemResultRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientScheduleItemResultResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleResultIdPost(patientId, id, patientScheduleItemResultRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientScheduleResultApi - factory interface
 * @export
 */
export const PatientScheduleResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientScheduleResultApiFp(configuration)
    return {
        /**
         * 
         * @param {ScheduleItemType} [scheduleItemType] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientMeScheduleGet(scheduleItemType?: ScheduleItemType, date?: string, options?: any): AxiosPromise<Array<PatientScheduleItemResultResponseDto>> {
            return localVarFp.apiV1PatientMeScheduleGet(scheduleItemType, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientMeScheduleResultIdAudioGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientMeScheduleResultIdAudioGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientMeScheduleResultIdImageGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientMeScheduleResultIdImageGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PatientScheduleItemResultRequestDto} [patientScheduleItemResultRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientMeScheduleResultIdPost(id: string, patientScheduleItemResultRequestDto?: PatientScheduleItemResultRequestDto, options?: any): AxiosPromise<PatientScheduleItemResultResponseDto> {
            return localVarFp.apiV1PatientMeScheduleResultIdPost(id, patientScheduleItemResultRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {DateRange} dateRange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdAnalyticsHappinessGet(patientId: string, dateRange: DateRange, options?: any): AxiosPromise<Array<HappinessAnalyticsResponse>> {
            return localVarFp.apiV1PatientPatientIdAnalyticsHappinessGet(patientId, dateRange, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} measurementTypeId 
         * @param {DateRange} dateRange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdAnalyticsMeasurementGet(patientId: string, measurementTypeId: string, dateRange: DateRange, options?: any): AxiosPromise<Array<MeasurementAnalyticResponse>> {
            return localVarFp.apiV1PatientPatientIdAnalyticsMeasurementGet(patientId, measurementTypeId, dateRange, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {ScheduleItemType} [scheduleItemType] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleGet(patientId: string, scheduleItemType?: ScheduleItemType, date?: string, options?: any): AxiosPromise<Array<PatientScheduleItemResultResponseDto>> {
            return localVarFp.apiV1PatientPatientIdScheduleGet(patientId, scheduleItemType, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleItemIdAudioGet(patientId: string, itemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientPatientIdScheduleItemIdAudioGet(patientId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleItemIdAudioResponseGet(patientId: string, itemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientPatientIdScheduleItemIdAudioResponseGet(patientId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleItemIdGet(patientId: string, itemId: string, options?: any): AxiosPromise<PatientScheduleItemResultFullResponseDto> {
            return localVarFp.apiV1PatientPatientIdScheduleItemIdGet(patientId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleItemIdImageGet(patientId: string, itemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientPatientIdScheduleItemIdImageGet(patientId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {PatientScheduleItemResultRequestDto} [patientScheduleItemResultRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleResultIdPost(patientId: string, id: string, patientScheduleItemResultRequestDto?: PatientScheduleItemResultRequestDto, options?: any): AxiosPromise<PatientScheduleItemResultResponseDto> {
            return localVarFp.apiV1PatientPatientIdScheduleResultIdPost(patientId, id, patientScheduleItemResultRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientScheduleResultApi - object-oriented interface
 * @export
 * @class PatientScheduleResultApi
 * @extends {BaseAPI}
 */
export class PatientScheduleResultApi extends BaseAPI {
    /**
     * 
     * @param {ScheduleItemType} [scheduleItemType] 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientMeScheduleGet(scheduleItemType?: ScheduleItemType, date?: string, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientMeScheduleGet(scheduleItemType, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientMeScheduleResultIdAudioGet(id: string, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientMeScheduleResultIdAudioGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientMeScheduleResultIdImageGet(id: string, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientMeScheduleResultIdImageGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PatientScheduleItemResultRequestDto} [patientScheduleItemResultRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientMeScheduleResultIdPost(id: string, patientScheduleItemResultRequestDto?: PatientScheduleItemResultRequestDto, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientMeScheduleResultIdPost(id, patientScheduleItemResultRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {DateRange} dateRange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientPatientIdAnalyticsHappinessGet(patientId: string, dateRange: DateRange, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientPatientIdAnalyticsHappinessGet(patientId, dateRange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} measurementTypeId 
     * @param {DateRange} dateRange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientPatientIdAnalyticsMeasurementGet(patientId: string, measurementTypeId: string, dateRange: DateRange, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientPatientIdAnalyticsMeasurementGet(patientId, measurementTypeId, dateRange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {ScheduleItemType} [scheduleItemType] 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientPatientIdScheduleGet(patientId: string, scheduleItemType?: ScheduleItemType, date?: string, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientPatientIdScheduleGet(patientId, scheduleItemType, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientPatientIdScheduleItemIdAudioGet(patientId: string, itemId: string, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientPatientIdScheduleItemIdAudioGet(patientId, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientPatientIdScheduleItemIdAudioResponseGet(patientId: string, itemId: string, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientPatientIdScheduleItemIdAudioResponseGet(patientId, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientPatientIdScheduleItemIdGet(patientId: string, itemId: string, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientPatientIdScheduleItemIdGet(patientId, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientPatientIdScheduleItemIdImageGet(patientId: string, itemId: string, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientPatientIdScheduleItemIdImageGet(patientId, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {PatientScheduleItemResultRequestDto} [patientScheduleItemResultRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleResultApi
     */
    public apiV1PatientPatientIdScheduleResultIdPost(patientId: string, id: string, patientScheduleItemResultRequestDto?: PatientScheduleItemResultRequestDto, options?: AxiosRequestConfig) {
        return PatientScheduleResultApiFp(this.configuration).apiV1PatientPatientIdScheduleResultIdPost(patientId, id, patientScheduleItemResultRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientScheduleTemplateApi - axios parameter creator
 * @export
 */
export const PatientScheduleTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {ScheduleItemType} [scheduleItemType] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesEffectiveGet: async (patientId: string, scheduleItemType?: ScheduleItemType, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesEffectiveGet', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleTemplates/effective`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (scheduleItemType !== undefined) {
                localVarQueryParameter['scheduleItemType'] = scheduleItemType;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesGet: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesGet', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleTemplates`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdAudioDurationGet: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdAudioDurationGet', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdAudioDurationGet', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleTemplates/{id}/audio/duration`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdAudioGet: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdAudioGet', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdAudioGet', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleTemplates/{id}/audio`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {File} file 
         * @param {string} [duration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdAudioPost: async (id: string, patientId: string, file: File, duration?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdAudioPost', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdAudioPost', 'patientId', patientId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdAudioPost', 'file', file)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleTemplates/{id}/audio`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdDelete: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdDelete', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdDelete', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleTemplates/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdGet: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdGet', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdGet', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleTemplates/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdImageGet: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdImageGet', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdImageGet', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleTemplates/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdImagePost: async (id: string, patientId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdImagePost', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdImagePost', 'patientId', patientId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdImagePost', 'file', file)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleTemplates/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {PatientScheduleItemTemplateRequestDto} [patientScheduleItemTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdPut: async (patientId: string, id: string, patientScheduleItemTemplateRequestDto?: PatientScheduleItemTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdPut', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesIdPut', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleTemplates/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientScheduleItemTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {PatientScheduleItemTemplateRequestDto} [patientScheduleItemTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesPost: async (patientId: string, patientScheduleItemTemplateRequestDto?: PatientScheduleItemTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdScheduleTemplatesPost', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/scheduleTemplates`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientScheduleItemTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientScheduleTemplateApi - functional programming interface
 * @export
 */
export const PatientScheduleTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientScheduleTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {ScheduleItemType} [scheduleItemType] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleTemplatesEffectiveGet(patientId: string, scheduleItemType?: ScheduleItemType, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientScheduleItemTemplateResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleTemplatesEffectiveGet(patientId, scheduleItemType, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleTemplatesGet(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientScheduleItemTemplateResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleTemplatesGet(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleTemplatesIdAudioDurationGet(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleTemplatesIdAudioDurationGet(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleTemplatesIdAudioGet(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleTemplatesIdAudioGet(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {File} file 
         * @param {string} [duration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleTemplatesIdAudioPost(id: string, patientId: string, file: File, duration?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleTemplatesIdAudioPost(id, patientId, file, duration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleTemplatesIdDelete(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleTemplatesIdDelete(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleTemplatesIdGet(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientScheduleItemTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleTemplatesIdGet(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleTemplatesIdImageGet(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleTemplatesIdImageGet(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleTemplatesIdImagePost(id: string, patientId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleTemplatesIdImagePost(id, patientId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {PatientScheduleItemTemplateRequestDto} [patientScheduleItemTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleTemplatesIdPut(patientId: string, id: string, patientScheduleItemTemplateRequestDto?: PatientScheduleItemTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientScheduleItemTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleTemplatesIdPut(patientId, id, patientScheduleItemTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {PatientScheduleItemTemplateRequestDto} [patientScheduleItemTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdScheduleTemplatesPost(patientId: string, patientScheduleItemTemplateRequestDto?: PatientScheduleItemTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientScheduleItemTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdScheduleTemplatesPost(patientId, patientScheduleItemTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientScheduleTemplateApi - factory interface
 * @export
 */
export const PatientScheduleTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientScheduleTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {ScheduleItemType} [scheduleItemType] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesEffectiveGet(patientId: string, scheduleItemType?: ScheduleItemType, date?: string, options?: any): AxiosPromise<Array<PatientScheduleItemTemplateResponseDto>> {
            return localVarFp.apiV1PatientPatientIdScheduleTemplatesEffectiveGet(patientId, scheduleItemType, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesGet(patientId: string, options?: any): AxiosPromise<Array<PatientScheduleItemTemplateResponseDto>> {
            return localVarFp.apiV1PatientPatientIdScheduleTemplatesGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdAudioDurationGet(id: string, patientId: string, options?: any): AxiosPromise<MediaFile> {
            return localVarFp.apiV1PatientPatientIdScheduleTemplatesIdAudioDurationGet(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdAudioGet(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientPatientIdScheduleTemplatesIdAudioGet(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {File} file 
         * @param {string} [duration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdAudioPost(id: string, patientId: string, file: File, duration?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1PatientPatientIdScheduleTemplatesIdAudioPost(id, patientId, file, duration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdDelete(patientId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientPatientIdScheduleTemplatesIdDelete(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdGet(patientId: string, id: string, options?: any): AxiosPromise<PatientScheduleItemTemplateResponseDto> {
            return localVarFp.apiV1PatientPatientIdScheduleTemplatesIdGet(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdImageGet(id: string, patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientPatientIdScheduleTemplatesIdImageGet(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} patientId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdImagePost(id: string, patientId: string, file: File, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1PatientPatientIdScheduleTemplatesIdImagePost(id, patientId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {PatientScheduleItemTemplateRequestDto} [patientScheduleItemTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesIdPut(patientId: string, id: string, patientScheduleItemTemplateRequestDto?: PatientScheduleItemTemplateRequestDto, options?: any): AxiosPromise<PatientScheduleItemTemplateResponseDto> {
            return localVarFp.apiV1PatientPatientIdScheduleTemplatesIdPut(patientId, id, patientScheduleItemTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {PatientScheduleItemTemplateRequestDto} [patientScheduleItemTemplateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdScheduleTemplatesPost(patientId: string, patientScheduleItemTemplateRequestDto?: PatientScheduleItemTemplateRequestDto, options?: any): AxiosPromise<PatientScheduleItemTemplateResponseDto> {
            return localVarFp.apiV1PatientPatientIdScheduleTemplatesPost(patientId, patientScheduleItemTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientScheduleTemplateApi - object-oriented interface
 * @export
 * @class PatientScheduleTemplateApi
 * @extends {BaseAPI}
 */
export class PatientScheduleTemplateApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {ScheduleItemType} [scheduleItemType] 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleTemplateApi
     */
    public apiV1PatientPatientIdScheduleTemplatesEffectiveGet(patientId: string, scheduleItemType?: ScheduleItemType, date?: string, options?: AxiosRequestConfig) {
        return PatientScheduleTemplateApiFp(this.configuration).apiV1PatientPatientIdScheduleTemplatesEffectiveGet(patientId, scheduleItemType, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleTemplateApi
     */
    public apiV1PatientPatientIdScheduleTemplatesGet(patientId: string, options?: AxiosRequestConfig) {
        return PatientScheduleTemplateApiFp(this.configuration).apiV1PatientPatientIdScheduleTemplatesGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleTemplateApi
     */
    public apiV1PatientPatientIdScheduleTemplatesIdAudioDurationGet(id: string, patientId: string, options?: AxiosRequestConfig) {
        return PatientScheduleTemplateApiFp(this.configuration).apiV1PatientPatientIdScheduleTemplatesIdAudioDurationGet(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleTemplateApi
     */
    public apiV1PatientPatientIdScheduleTemplatesIdAudioGet(id: string, patientId: string, options?: AxiosRequestConfig) {
        return PatientScheduleTemplateApiFp(this.configuration).apiV1PatientPatientIdScheduleTemplatesIdAudioGet(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {File} file 
     * @param {string} [duration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleTemplateApi
     */
    public apiV1PatientPatientIdScheduleTemplatesIdAudioPost(id: string, patientId: string, file: File, duration?: string, options?: AxiosRequestConfig) {
        return PatientScheduleTemplateApiFp(this.configuration).apiV1PatientPatientIdScheduleTemplatesIdAudioPost(id, patientId, file, duration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleTemplateApi
     */
    public apiV1PatientPatientIdScheduleTemplatesIdDelete(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientScheduleTemplateApiFp(this.configuration).apiV1PatientPatientIdScheduleTemplatesIdDelete(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleTemplateApi
     */
    public apiV1PatientPatientIdScheduleTemplatesIdGet(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientScheduleTemplateApiFp(this.configuration).apiV1PatientPatientIdScheduleTemplatesIdGet(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleTemplateApi
     */
    public apiV1PatientPatientIdScheduleTemplatesIdImageGet(id: string, patientId: string, options?: AxiosRequestConfig) {
        return PatientScheduleTemplateApiFp(this.configuration).apiV1PatientPatientIdScheduleTemplatesIdImageGet(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} patientId 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleTemplateApi
     */
    public apiV1PatientPatientIdScheduleTemplatesIdImagePost(id: string, patientId: string, file: File, options?: AxiosRequestConfig) {
        return PatientScheduleTemplateApiFp(this.configuration).apiV1PatientPatientIdScheduleTemplatesIdImagePost(id, patientId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {PatientScheduleItemTemplateRequestDto} [patientScheduleItemTemplateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleTemplateApi
     */
    public apiV1PatientPatientIdScheduleTemplatesIdPut(patientId: string, id: string, patientScheduleItemTemplateRequestDto?: PatientScheduleItemTemplateRequestDto, options?: AxiosRequestConfig) {
        return PatientScheduleTemplateApiFp(this.configuration).apiV1PatientPatientIdScheduleTemplatesIdPut(patientId, id, patientScheduleItemTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {PatientScheduleItemTemplateRequestDto} [patientScheduleItemTemplateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientScheduleTemplateApi
     */
    public apiV1PatientPatientIdScheduleTemplatesPost(patientId: string, patientScheduleItemTemplateRequestDto?: PatientScheduleItemTemplateRequestDto, options?: AxiosRequestConfig) {
        return PatientScheduleTemplateApiFp(this.configuration).apiV1PatientPatientIdScheduleTemplatesPost(patientId, patientScheduleItemTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientTreatmentsApi - axios parameter creator
 * @export
 */
export const PatientTreatmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsGet: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsGet', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/treatments`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdDelete: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdDelete', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdDelete', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/treatments/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdGet: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdGet', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdGet', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/treatments/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdImageDelete: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdImageDelete', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdImageDelete', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/treatments/{id}/image`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdImageGet: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdImageGet', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdImageGet', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/treatments/{id}/image`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdImagePost: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdImagePost', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdImagePost', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/treatments/{id}/image`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {TreatmentRequestDto} [treatmentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdPut: async (patientId: string, id: string, treatmentRequestDto?: TreatmentRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdPut', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsIdPut', 'id', id)
            const localVarPath = `/api/v1/patient/{patientId}/treatments/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(treatmentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [name] 
         * @param {string} [instructions] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsPost: async (patientId: string, name?: string, instructions?: string, mimeType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1PatientPatientIdTreatmentsPost', 'patientId', patientId)
            const localVarPath = `/api/v1/patient/{patientId}/treatments`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (instructions !== undefined) {
                localVarQueryParameter['instructions'] = instructions;
            }

            if (mimeType !== undefined) {
                localVarQueryParameter['mimeType'] = mimeType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientTreatmentsApi - functional programming interface
 * @export
 */
export const PatientTreatmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientTreatmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdTreatmentsGet(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TreatmentResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdTreatmentsGet(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdTreatmentsIdDelete(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdTreatmentsIdDelete(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdTreatmentsIdGet(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdTreatmentsIdGet(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdTreatmentsIdImageDelete(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdTreatmentsIdImageDelete(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdTreatmentsIdImageGet(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdTreatmentsIdImageGet(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdTreatmentsIdImagePost(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdTreatmentsIdImagePost(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {TreatmentRequestDto} [treatmentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdTreatmentsIdPut(patientId: string, id: string, treatmentRequestDto?: TreatmentRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdTreatmentsIdPut(patientId, id, treatmentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [name] 
         * @param {string} [instructions] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PatientPatientIdTreatmentsPost(patientId: string, name?: string, instructions?: string, mimeType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PatientPatientIdTreatmentsPost(patientId, name, instructions, mimeType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientTreatmentsApi - factory interface
 * @export
 */
export const PatientTreatmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientTreatmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsGet(patientId: string, options?: any): AxiosPromise<Array<TreatmentResponseDto>> {
            return localVarFp.apiV1PatientPatientIdTreatmentsGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdDelete(patientId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientPatientIdTreatmentsIdDelete(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdGet(patientId: string, id: string, options?: any): AxiosPromise<TreatmentResponseDto> {
            return localVarFp.apiV1PatientPatientIdTreatmentsIdGet(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdImageDelete(patientId: string, id: string, options?: any): AxiosPromise<TreatmentResponseDto> {
            return localVarFp.apiV1PatientPatientIdTreatmentsIdImageDelete(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdImageGet(patientId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PatientPatientIdTreatmentsIdImageGet(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdImagePost(patientId: string, id: string, options?: any): AxiosPromise<TreatmentResponseDto> {
            return localVarFp.apiV1PatientPatientIdTreatmentsIdImagePost(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {TreatmentRequestDto} [treatmentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsIdPut(patientId: string, id: string, treatmentRequestDto?: TreatmentRequestDto, options?: any): AxiosPromise<TreatmentResponseDto> {
            return localVarFp.apiV1PatientPatientIdTreatmentsIdPut(patientId, id, treatmentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [name] 
         * @param {string} [instructions] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PatientPatientIdTreatmentsPost(patientId: string, name?: string, instructions?: string, mimeType?: string, options?: any): AxiosPromise<TreatmentResponseDto> {
            return localVarFp.apiV1PatientPatientIdTreatmentsPost(patientId, name, instructions, mimeType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientTreatmentsApi - object-oriented interface
 * @export
 * @class PatientTreatmentsApi
 * @extends {BaseAPI}
 */
export class PatientTreatmentsApi extends BaseAPI {
    /**
     * 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientTreatmentsApi
     */
    public apiV1PatientPatientIdTreatmentsGet(patientId: string, options?: AxiosRequestConfig) {
        return PatientTreatmentsApiFp(this.configuration).apiV1PatientPatientIdTreatmentsGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientTreatmentsApi
     */
    public apiV1PatientPatientIdTreatmentsIdDelete(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientTreatmentsApiFp(this.configuration).apiV1PatientPatientIdTreatmentsIdDelete(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientTreatmentsApi
     */
    public apiV1PatientPatientIdTreatmentsIdGet(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientTreatmentsApiFp(this.configuration).apiV1PatientPatientIdTreatmentsIdGet(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientTreatmentsApi
     */
    public apiV1PatientPatientIdTreatmentsIdImageDelete(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientTreatmentsApiFp(this.configuration).apiV1PatientPatientIdTreatmentsIdImageDelete(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientTreatmentsApi
     */
    public apiV1PatientPatientIdTreatmentsIdImageGet(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientTreatmentsApiFp(this.configuration).apiV1PatientPatientIdTreatmentsIdImageGet(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientTreatmentsApi
     */
    public apiV1PatientPatientIdTreatmentsIdImagePost(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientTreatmentsApiFp(this.configuration).apiV1PatientPatientIdTreatmentsIdImagePost(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {TreatmentRequestDto} [treatmentRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientTreatmentsApi
     */
    public apiV1PatientPatientIdTreatmentsIdPut(patientId: string, id: string, treatmentRequestDto?: TreatmentRequestDto, options?: AxiosRequestConfig) {
        return PatientTreatmentsApiFp(this.configuration).apiV1PatientPatientIdTreatmentsIdPut(patientId, id, treatmentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} [name] 
     * @param {string} [instructions] 
     * @param {string} [mimeType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientTreatmentsApi
     */
    public apiV1PatientPatientIdTreatmentsPost(patientId: string, name?: string, instructions?: string, mimeType?: string, options?: AxiosRequestConfig) {
        return PatientTreatmentsApiFp(this.configuration).apiV1PatientPatientIdTreatmentsPost(patientId, name, instructions, mimeType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PushMessagesApi - axios parameter creator
 * @export
 */
export const PushMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PushMessageAcknowledgement} [pushMessageAcknowledgement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushMessagesAcknowledgePost: async (pushMessageAcknowledgement?: PushMessageAcknowledgement, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/PushMessages/acknowledge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushMessageAcknowledgement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushMessagesApi - functional programming interface
 * @export
 */
export const PushMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PushMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PushMessageAcknowledgement} [pushMessageAcknowledgement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PushMessagesAcknowledgePost(pushMessageAcknowledgement?: PushMessageAcknowledgement, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PushMessagesAcknowledgePost(pushMessageAcknowledgement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PushMessagesApi - factory interface
 * @export
 */
export const PushMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PushMessagesApiFp(configuration)
    return {
        /**
         * 
         * @param {PushMessageAcknowledgement} [pushMessageAcknowledgement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushMessagesAcknowledgePost(pushMessageAcknowledgement?: PushMessageAcknowledgement, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PushMessagesAcknowledgePost(pushMessageAcknowledgement, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PushMessagesApi - object-oriented interface
 * @export
 * @class PushMessagesApi
 * @extends {BaseAPI}
 */
export class PushMessagesApi extends BaseAPI {
    /**
     * 
     * @param {PushMessageAcknowledgement} [pushMessageAcknowledgement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushMessagesApi
     */
    public apiV1PushMessagesAcknowledgePost(pushMessageAcknowledgement?: PushMessageAcknowledgement, options?: AxiosRequestConfig) {
        return PushMessagesApiFp(this.configuration).apiV1PushMessagesAcknowledgePost(pushMessageAcknowledgement, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RelatedPatientsApi - axios parameter creator
 * @export
 */
export const RelatedPatientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RelatedPatients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsIdCaretakersGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RelatedPatientsIdCaretakersGet', 'id', id)
            const localVarPath = `/api/v1/RelatedPatients/{id}/caretakers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RelatedPatientsIdDelete', 'id', id)
            const localVarPath = `/api/v1/RelatedPatients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsIdEmergencyContactContactIdPut: async (id: string, contactId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RelatedPatientsIdEmergencyContactContactIdPut', 'id', id)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('apiV1RelatedPatientsIdEmergencyContactContactIdPut', 'contactId', contactId)
            const localVarPath = `/api/v1/RelatedPatients/{id}/emergencyContact/{contactId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsIdEmergencyContactGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RelatedPatientsIdEmergencyContactGet', 'id', id)
            const localVarPath = `/api/v1/RelatedPatients/{id}/emergencyContact`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RelatedPatientsIdGet', 'id', id)
            const localVarPath = `/api/v1/RelatedPatients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPatientIdActivatePost: async (patientId: string, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1RelatedPatientsPatientIdActivatePost', 'patientId', patientId)
            const localVarPath = `/api/v1/RelatedPatients/{patientId}/activate`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPatientIdCaretakersIdDelete: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1RelatedPatientsPatientIdCaretakersIdDelete', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RelatedPatientsPatientIdCaretakersIdDelete', 'id', id)
            const localVarPath = `/api/v1/RelatedPatients/{patientId}/caretakers/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPatientIdCaretakersIdPut: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1RelatedPatientsPatientIdCaretakersIdPut', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RelatedPatientsPatientIdCaretakersIdPut', 'id', id)
            const localVarPath = `/api/v1/RelatedPatients/{patientId}/caretakers/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPatientIdDeactivatePost: async (patientId: string, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('apiV1RelatedPatientsPatientIdDeactivatePost', 'patientId', patientId)
            const localVarPath = `/api/v1/RelatedPatients/{patientId}/deactivate`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RelationType} relationType 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPost: async (relationType: RelationType, patientRequestDto?: PatientRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relationType' is not null or undefined
            assertParamExists('apiV1RelatedPatientsPost', 'relationType', relationType)
            const localVarPath = `/api/v1/RelatedPatients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (relationType !== undefined) {
                localVarQueryParameter['relationType'] = relationType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RelationType} relationType 
         * @param {PatientCreateRequestDto} [patientCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPut: async (relationType: RelationType, patientCreateRequestDto?: PatientCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relationType' is not null or undefined
            assertParamExists('apiV1RelatedPatientsPut', 'relationType', relationType)
            const localVarPath = `/api/v1/RelatedPatients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (relationType !== undefined) {
                localVarQueryParameter['relationType'] = relationType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RelatedPatientsApi - functional programming interface
 * @export
 */
export const RelatedPatientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RelatedPatientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RelatedPatientResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsIdCaretakersGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaretakerListResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsIdCaretakersGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsIdEmergencyContactContactIdPut(id: string, contactId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsIdEmergencyContactContactIdPut(id, contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsIdEmergencyContactGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsIdEmergencyContactGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelatedPatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsPatientIdActivatePost(patientId: string, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsPatientIdActivatePost(patientId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsPatientIdCaretakersIdDelete(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsPatientIdCaretakersIdDelete(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsPatientIdCaretakersIdPut(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsPatientIdCaretakersIdPut(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsPatientIdDeactivatePost(patientId: string, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsPatientIdDeactivatePost(patientId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RelationType} relationType 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsPost(relationType: RelationType, patientRequestDto?: PatientRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelatedPatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsPost(relationType, patientRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RelationType} relationType 
         * @param {PatientCreateRequestDto} [patientCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RelatedPatientsPut(relationType: RelationType, patientCreateRequestDto?: PatientCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelatedPatientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RelatedPatientsPut(relationType, patientCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RelatedPatientsApi - factory interface
 * @export
 */
export const RelatedPatientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RelatedPatientsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsGet(options?: any): AxiosPromise<Array<RelatedPatientResponseDto>> {
            return localVarFp.apiV1RelatedPatientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsIdCaretakersGet(id: string, options?: any): AxiosPromise<Array<CaretakerListResponseDto>> {
            return localVarFp.apiV1RelatedPatientsIdCaretakersGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1RelatedPatientsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsIdEmergencyContactContactIdPut(id: string, contactId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1RelatedPatientsIdEmergencyContactContactIdPut(id, contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsIdEmergencyContactGet(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1RelatedPatientsIdEmergencyContactGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsIdGet(id: string, options?: any): AxiosPromise<RelatedPatientResponseDto> {
            return localVarFp.apiV1RelatedPatientsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPatientIdActivatePost(patientId: string, code?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1RelatedPatientsPatientIdActivatePost(patientId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPatientIdCaretakersIdDelete(patientId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1RelatedPatientsPatientIdCaretakersIdDelete(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPatientIdCaretakersIdPut(patientId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1RelatedPatientsPatientIdCaretakersIdPut(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPatientIdDeactivatePost(patientId: string, code?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1RelatedPatientsPatientIdDeactivatePost(patientId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RelationType} relationType 
         * @param {PatientRequestDto} [patientRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPost(relationType: RelationType, patientRequestDto?: PatientRequestDto, options?: any): AxiosPromise<RelatedPatientResponseDto> {
            return localVarFp.apiV1RelatedPatientsPost(relationType, patientRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RelationType} relationType 
         * @param {PatientCreateRequestDto} [patientCreateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RelatedPatientsPut(relationType: RelationType, patientCreateRequestDto?: PatientCreateRequestDto, options?: any): AxiosPromise<RelatedPatientResponseDto> {
            return localVarFp.apiV1RelatedPatientsPut(relationType, patientCreateRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RelatedPatientsApi - object-oriented interface
 * @export
 * @class RelatedPatientsApi
 * @extends {BaseAPI}
 */
export class RelatedPatientsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsGet(options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsIdCaretakersGet(id: string, options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsIdCaretakersGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsIdDelete(id: string, options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} contactId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsIdEmergencyContactContactIdPut(id: string, contactId: string, options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsIdEmergencyContactContactIdPut(id, contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsIdEmergencyContactGet(id: string, options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsIdEmergencyContactGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsIdGet(id: string, options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsPatientIdActivatePost(patientId: string, code?: string, options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsPatientIdActivatePost(patientId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsPatientIdCaretakersIdDelete(patientId: string, id: string, options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsPatientIdCaretakersIdDelete(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsPatientIdCaretakersIdPut(patientId: string, id: string, options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsPatientIdCaretakersIdPut(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsPatientIdDeactivatePost(patientId: string, code?: string, options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsPatientIdDeactivatePost(patientId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RelationType} relationType 
     * @param {PatientRequestDto} [patientRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsPost(relationType: RelationType, patientRequestDto?: PatientRequestDto, options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsPost(relationType, patientRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RelationType} relationType 
     * @param {PatientCreateRequestDto} [patientCreateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelatedPatientsApi
     */
    public apiV1RelatedPatientsPut(relationType: RelationType, patientCreateRequestDto?: PatientCreateRequestDto, options?: AxiosRequestConfig) {
        return RelatedPatientsApiFp(this.configuration).apiV1RelatedPatientsPut(relationType, patientCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SignInApi - axios parameter creator
 * @export
 */
export const SignInApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SignInCheckUserAccountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/SignIn/checkUserAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SignInUserAccountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/SignIn/userAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetFcmTokenRequest} [setFcmTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SignInUserAccountSetFcmTokenPost: async (setFcmTokenRequest?: SetFcmTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/SignIn/userAccount/setFcmToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setFcmTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SignInApi - functional programming interface
 * @export
 */
export const SignInApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SignInApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SignInCheckUserAccountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SignInCheckUserAccountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SignInUserAccountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SignInUserAccountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetFcmTokenRequest} [setFcmTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SignInUserAccountSetFcmTokenPost(setFcmTokenRequest?: SetFcmTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SignInUserAccountSetFcmTokenPost(setFcmTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SignInApi - factory interface
 * @export
 */
export const SignInApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SignInApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SignInCheckUserAccountGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiV1SignInCheckUserAccountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SignInUserAccountGet(options?: any): AxiosPromise<UserAccountDto> {
            return localVarFp.apiV1SignInUserAccountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetFcmTokenRequest} [setFcmTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SignInUserAccountSetFcmTokenPost(setFcmTokenRequest?: SetFcmTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SignInUserAccountSetFcmTokenPost(setFcmTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SignInApi - object-oriented interface
 * @export
 * @class SignInApi
 * @extends {BaseAPI}
 */
export class SignInApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignInApi
     */
    public apiV1SignInCheckUserAccountGet(options?: AxiosRequestConfig) {
        return SignInApiFp(this.configuration).apiV1SignInCheckUserAccountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignInApi
     */
    public apiV1SignInUserAccountGet(options?: AxiosRequestConfig) {
        return SignInApiFp(this.configuration).apiV1SignInUserAccountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetFcmTokenRequest} [setFcmTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignInApi
     */
    public apiV1SignInUserAccountSetFcmTokenPost(setFcmTokenRequest?: SetFcmTokenRequest, options?: AxiosRequestConfig) {
        return SignInApiFp(this.configuration).apiV1SignInUserAccountSetFcmTokenPost(setFcmTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SignUpApi - axios parameter creator
 * @export
 */
export const SignUpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SendOtpRequest} [sendOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SignUpSendPhoneConfirmationOtpPost: async (sendOtpRequest?: SendOtpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/SignUp/SendPhoneConfirmationOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendOtpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubmitOtpRequest} [submitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SignUpSubmitOtpPost: async (submitOtpRequest?: SubmitOtpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/SignUp/SubmitOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitOtpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SignUpApi - functional programming interface
 * @export
 */
export const SignUpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SignUpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SendOtpRequest} [sendOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SignUpSendPhoneConfirmationOtpPost(sendOtpRequest?: SendOtpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SignUpSendPhoneConfirmationOtpPost(sendOtpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SubmitOtpRequest} [submitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SignUpSubmitOtpPost(submitOtpRequest?: SubmitOtpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SignUpSubmitOtpPost(submitOtpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SignUpApi - factory interface
 * @export
 */
export const SignUpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SignUpApiFp(configuration)
    return {
        /**
         * 
         * @param {SendOtpRequest} [sendOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SignUpSendPhoneConfirmationOtpPost(sendOtpRequest?: SendOtpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SignUpSendPhoneConfirmationOtpPost(sendOtpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubmitOtpRequest} [submitOtpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SignUpSubmitOtpPost(submitOtpRequest?: SubmitOtpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SignUpSubmitOtpPost(submitOtpRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SignUpApi - object-oriented interface
 * @export
 * @class SignUpApi
 * @extends {BaseAPI}
 */
export class SignUpApi extends BaseAPI {
    /**
     * 
     * @param {SendOtpRequest} [sendOtpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignUpApi
     */
    public apiV1SignUpSendPhoneConfirmationOtpPost(sendOtpRequest?: SendOtpRequest, options?: AxiosRequestConfig) {
        return SignUpApiFp(this.configuration).apiV1SignUpSendPhoneConfirmationOtpPost(sendOtpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubmitOtpRequest} [submitOtpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignUpApi
     */
    public apiV1SignUpSubmitOtpPost(submitOtpRequest?: SubmitOtpRequest, options?: AxiosRequestConfig) {
        return SignUpApiFp(this.configuration).apiV1SignUpSubmitOtpPost(submitOtpRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SmsTestApi - axios parameter creator
 * @export
 */
export const SmsTestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SmsTestSendsmsGet: async (phoneNumber?: string, message?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/SmsTest/sendsms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SmsTestApi - functional programming interface
 * @export
 */
export const SmsTestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SmsTestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SmsTestSendsmsGet(phoneNumber?: string, message?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SmsTestSendsmsGet(phoneNumber, message, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SmsTestApi - factory interface
 * @export
 */
export const SmsTestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SmsTestApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SmsTestSendsmsGet(phoneNumber?: string, message?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SmsTestSendsmsGet(phoneNumber, message, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SmsTestApi - object-oriented interface
 * @export
 * @class SmsTestApi
 * @extends {BaseAPI}
 */
export class SmsTestApi extends BaseAPI {
    /**
     * 
     * @param {string} [phoneNumber] 
     * @param {string} [message] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsTestApi
     */
    public apiV1SmsTestSendsmsGet(phoneNumber?: string, message?: string, options?: AxiosRequestConfig) {
        return SmsTestApiFp(this.configuration).apiV1SmsTestSendsmsGet(phoneNumber, message, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserIdInfoGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UserIdInfoGet', 'id', id)
            const localVarPath = `/api/v1/User/{id}/info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyByRoleGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/User/my/byRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyFamilyMemberGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/User/my/familyMember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyGetActivationRequestPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/User/my/getActivationRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/User/my/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyRevokeAllMyAccessTokensPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/User/my/revokeAllMyAccessTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyUserAccountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/User/my/userAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserIdInfoGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserIdInfoGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserMyByRoleGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserMyByRoleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserMyFamilyMemberGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserMyFamilyMemberGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserMyGetActivationRequestPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserMyGetActivationRequestPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserMyInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserMyInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserMyRevokeAllMyAccessTokensPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserMyRevokeAllMyAccessTokensPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserMyUserAccountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserMyUserAccountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserIdInfoGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UserIdInfoGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyByRoleGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UserMyByRoleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyFamilyMemberGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UserMyFamilyMemberGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyGetActivationRequestPost(options?: any): AxiosPromise<string> {
            return localVarFp.apiV1UserMyGetActivationRequestPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyInfoGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UserMyInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyRevokeAllMyAccessTokensPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UserMyRevokeAllMyAccessTokensPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserMyUserAccountGet(options?: any): AxiosPromise<UserAccountDto> {
            return localVarFp.apiV1UserMyUserAccountGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserIdInfoGet(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserIdInfoGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserMyByRoleGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserMyByRoleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserMyFamilyMemberGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserMyFamilyMemberGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserMyGetActivationRequestPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserMyGetActivationRequestPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserMyInfoGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserMyInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserMyRevokeAllMyAccessTokensPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserMyRevokeAllMyAccessTokensPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserMyUserAccountGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserMyUserAccountGet(options).then((request) => request(this.axios, this.basePath));
    }
}


