import {AuthProviderProps} from "react-oidc-context";
import {authority, clientId} from "../config/apiConfig";


const keycloak = {
    authority: authority,
    client_id: clientId,
    redirect_uri: window.location.origin,
    onSigninCallback: user => {
        window.history.replaceState({},document.title, window.location.pathname)
    }
} as AuthProviderProps;

export default keycloak;