import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { CaretakerCreateRequestDto } from "../api/client-axios";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { SelectPersons } from "../components/SelectPersons";
import {ScissorOutlined, DeleteOutlined, CloseOutlined, CheckOutlined} from "@ant-design/icons";
import {Select, Spin, Modal, Col, Form, Row, Space, Button, Input, DatePicker, InputNumber, Switch} from "antd";
import moment from "moment";

import {
    addPatientsToCaretaker,
    clearSaveCaretakerError,
    createOrUpdateCaretaker,
    deleteCaretaker, detachCaretaker,
    fetchCaretaker,
    fetchCaretakerPatients,
    initCaretakerState,
    removePatientsFromCaretaker, updateCaretakerFields,
    updateCaretakerPhoneNumber
} from "../features/caretaker/caretakerSlice";
import {fetchCaretakers} from "../features/caretaker/caretakersSlice";
import { fetchCaretakerAvaliablePatients } from "../features/patientsToAssign/patientsToAssignSlice";
import { fakeCaretakers } from "../mock/fake-entities";
import { Caretaker } from "../model/caretaker";
import { Person } from "../model/person";
import { Problem } from "../model/problem";
import { RouteNames } from "../routes";
import { AssignPatientsToCaretaker } from "./AssignPatientsToCaretaker";


const {Option} = Select;

export const EditCaretakerPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const caretakersStatus = useAppSelector((state: RootState) => state.caretakers.status);
    const caretaker = useAppSelector((state: RootState) => state.caretaker.caretaker);
    const status = useAppSelector((state: RootState) => state.caretaker.status);
    const error = useAppSelector((state: RootState) => state.caretaker.error);
    const caretakerPatients = useAppSelector((state: RootState) => state.caretaker.patients);

    const caretakerId = params.caretakerId;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isRemoveCaretakerWarningVisible, setIsRemoveCaretakerWarningVisible] = useState(false);
    const [isDeleteCaretakerWarningVisible, setIsDeleteCaretakerWarningVisible] = useState(false);

    const [tempCaretaker, setTempCaretaker] = useState<Caretaker>()

    const countries = useAppSelector((state: RootState) => state.country.countries);


    useEffect(() => {

        if (caretakersStatus === "idle") {
            dispatch(fetchCaretakers())
        }
        dispatch(fetchCaretaker(caretakerId!))
        if (caretakerId !== "new") {
            dispatch(fetchCaretakerPatients(caretakerId!));

        }


    }, []);

    useEffect(() => {
        if (status === "saved") {
            dispatch(fetchCaretakers());
            dispatch(initCaretakerState());
            navigate(`/${RouteNames.caretakers}`);
        }
        if (status === "auth-error" || status === "error") {
            setIsModalVisible(true);
        }
    }, [status])


    const onCancel = () => {
        navigate(`/${RouteNames.caretakers}`);
    }

    const onDelete = () => {
        setIsDeleteCaretakerWarningVisible(true);
    }

    const onRemove = () => {
        setIsRemoveCaretakerWarningVisible(true);
    }

    const onRemoveCaretaker = () => {
        if (caretaker?.id != null) {
            dispatch(detachCaretaker(caretaker.id));
        }
    }

    const onSave = () => {
        setTempCaretaker(caretaker as Caretaker);
        dispatch(createOrUpdateCaretaker(caretaker as CaretakerCreateRequestDto));
    }

    const onDeleteCaretaker = () => {
        if(caretaker?.id != null){
            dispatch(deleteCaretaker(caretaker.id));
        }

    }

    const onConfirm = (phoneNumber: string) => {
        dispatch(updateCaretakerPhoneNumber(phoneNumber));
    }

    const handleAuthOk = () => {
        setIsModalVisible(false)
        navigate("/")
    }

    const handleErrorOk = () => {
        setIsModalVisible(false);
        dispatch(clearSaveCaretakerError(tempCaretaker!));
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const reloadCaretakerPatients = () => {
        dispatch(fetchCaretakerPatients(caretakerId!));
    }

    const onPatientsRemove = (selectedPersons: Person[] | undefined) => {
        if (selectedPersons === undefined || selectedPersons.length === 0) return;
        dispatch(removePatientsFromCaretaker({ caretakerId: caretakerId!, patients: selectedPersons.map(p => p.id) }));
    }


    const onPatientsAssign = () => {
        dispatch(fetchCaretakerAvaliablePatients(caretakerId!));
        dispatch(addPatientsToCaretaker());
    }

    const changeCaretaker = (caretaker: CaretakerCreateRequestDto) => {
        dispatch(updateCaretakerFields(caretaker));
    }




    const columns: ColumnsType<Person> = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => a.firstName > b.firstName ? -1 : 1,
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.lastName > b.lastName ? -1 : 1
        },
        {
            width: '12.5%',
            title: 'Phone',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            sorter: (a, b) => a.phoneNumber > b.phoneNumber ? -1 : 1
        },
        {
            width: '12.5%',
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email > b.email ? -1 : 1
        },
    ];


    if (status === "idle" || status === "loading" || caretakersStatus === "idle") {
        return <Spin />;
    }

    if (status === "auth-error") {
        return <Modal title="Auth error" visible={isModalVisible} onOk={handleAuthOk} onCancel={handleCancel}>
            <p>"Authentication failed, please login"</p>
        </Modal>
    }

    if (status === "error") {
        const apiError = error as Problem;
        const title = apiError.title ?? "Server Error";
        const detail = apiError.detail ?? error?.toString;
        return <Modal title={title} visible={isModalVisible} onOk={handleErrorOk} onCancel={handleCancel}>
            <p>{detail}</p>
        </Modal>
    }

    if (status === "saved") {
        return (<div></div>);
    }


    if (status == "addingPatients") {
        return <AssignPatientsToCaretaker caretakerId={caretakerId!} />
    }

    const editCaretaker = <div className='edit-person-main-frame'>
        <Col span={24}>
            <Form className='person-form'
                  name="basic"
                  labelCol={{span: 6}}
                  wrapperCol={{span: 24}}
                  initialValues={{remember: true}}
                //onFinish={onFinish}
                //onFinishFailed={onFinishFailed}
                  autoComplete="off"
            >

                <Row>
                    {/* <Col className='person-title' offset={6} span={11}>Caretaker</Col> */}
                    <Col offset={6} span={18}>
                        <Form.Item>
                            <Row justify="end">
                                <Space className="mr-xxs">
                                    <Button icon={<ScissorOutlined/>} onClick={onRemove} type='primary'
                                            style={{background: 'orange', borderColor: 'orange'}}
                                            disabled={caretaker?.id === undefined}>Remove</Button>
                                    <Button icon={<DeleteOutlined/>} onClick={onDelete} type='primary' danger
                                            disabled={caretaker?.id === undefined}>Delete</Button>
                                </Space>

                                <Space>
                                    <Button icon={<CloseOutlined/>} onClick={onCancel}>Cancel</Button>
                                    <Button icon={<CheckOutlined/>} type='primary' onClick={onSave}>Save</Button>
                                </Space>
                            </Row>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Email:">
                            <Input placeholder='john.doe@email.com'
                                   disabled={caretaker?.id != undefined && caretaker.id != "new"}
                                   defaultValue={caretaker?.userAccount?.emailAddress || ""} onChange={(e) => {
                                changeCaretaker({
                                    ...caretaker,
                                    userAccount: {...caretaker?.userAccount, emailAddress: e.target.value}
                                } as CaretakerCreateRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    {caretakerId === undefined || caretakerId == "new"
                        ?
                        <Col span={24}>
                            <Form.Item label="Password:">
                                <Input.Password placeholder='password' onChange={(e) => {
                                    changeCaretaker({
                                        ...caretaker,
                                        password: e.target.value
                                    } as CaretakerCreateRequestDto)
                                }}/>
                            </Form.Item>
                        </Col>
                        : <span/>}
                    <Col span={24}>
                        <Form.Item label="Phone number:" rules={[{required: true}]}
                                  >
                            <Input placeholder='1 (344) 333-3333'
                                   defaultValue={caretaker?.userAccount?.mobileNumber ?? ""}
                                   onChange={(e) => {
                                changeCaretaker({
                                    ...caretaker,
                                    userAccount: {...caretaker?.userAccount, mobileNumber: e.target.value}
                                } as CaretakerCreateRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="First name:" rules={[{required: true, message: 'please enter First Name'}]}>
                            <Input placeholder='John' defaultValue={caretaker?.firstName || ""} onChange={(e) => {
                                changeCaretaker({...caretaker, firstName: e.target.value} as CaretakerCreateRequestDto)
                            }}/>
                        </Form.Item>

                    </Col>
                    <Col span={24}>
                        <Form.Item label="Last name:">
                            <Input placeholder='Doe' defaultValue={caretaker?.lastName || ""} onChange={(e) => {
                                changeCaretaker({...caretaker, lastName: e.target.value} as CaretakerCreateRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Gender:">
                            <Select defaultValue={caretaker?.gender} onChange={(e) => {
                                changeCaretaker({...caretaker, gender: e} as CaretakerCreateRequestDto)
                            }} style={{width: 100}}>
                                <Option value="Male">Male</Option>
                                <Option value="Female">Female</Option>
                                <Option value="Other">Other</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Birthday:">
                            <DatePicker
                                defaultValue={caretaker?.birthday != undefined ? moment(caretaker?.birthday, "YYYY-MM-DD").utc() : moment().utc()}
                                onChange={(e) => {
                                    changeCaretaker({
                                        ...caretaker,
                                        birthday: e?.format("")
                                    } as CaretakerCreateRequestDto)
                                }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Country:" initialValue={caretaker?.address?.country}>
                            <Select
                                onChange={(e) => changeCaretaker({
                                    ...caretaker,
                                    address: {...caretaker?.address, alpha3Code: e}
                                } as CaretakerCreateRequestDto)}
                                defaultValue={caretaker?.address?.alpha3Code}
                                showSearch
                                style={{width: 200}}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return (option!.children![2] as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }}
                                filterSort={(optionA, optionB) =>
                                    (optionA!.children![2] as unknown as string)
                                        .toLowerCase()
                                        .localeCompare((optionB!.children![2] as unknown as string).toLowerCase())
                                }
                            >
                                {countries.map(country => <Option key={country.alpha3Code}
                                                                  value={country.alpha3Code}><img src={country.flag!}
                                                                                                  width="20"></img> {country.name}
                                </Option>)}

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Address:">
                            <Input defaultValue={caretaker?.address?.streetAddress1 as string | undefined}
                                   placeholder='Road st 27' onChange={(e) => {
                                changeCaretaker({
                                    ...caretaker,
                                    address: {...caretaker?.address, streetAddress1: e.target.value}
                                } as CaretakerCreateRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Address:">
                            <Input defaultValue={caretaker?.address?.streetAddress2 as string | undefined}
                                   placeholder='Left side' onChange={(e) => {
                                changeCaretaker({
                                    ...caretaker,
                                    address: {...caretaker?.address, streetAddress2: e.target.value}
                                } as CaretakerCreateRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="City:">
                            <Input defaultValue={caretaker?.address?.city as string | undefined} placeholder='New York'
                                   onChange={(e) => {
                                       changeCaretaker({
                                           ...caretaker,
                                           address: {...caretaker?.address, city: e.target.value}
                                       } as CaretakerCreateRequestDto)
                                   }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="State:">
                            <Input defaultValue={caretaker?.address?.state as string | undefined} placeholder='NY'
                                   onChange={(e) => {
                                       changeCaretaker({
                                           ...caretaker,
                                           address: {...caretaker?.address, state: e.target.value}
                                       } as CaretakerCreateRequestDto)
                                   }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Postcode:">
                            <Input defaultValue={caretaker?.address?.postCode as string | undefined} placeholder='NY'
                                   onChange={(e) => {
                                       changeCaretaker({
                                           ...caretaker,
                                           address: {...caretaker?.address, postCode: e.target.value}
                                       } as CaretakerCreateRequestDto)
                                   }}/>
                        </Form.Item>
                    </Col>


                </Row>
            </Form>
        </Col>
        <Modal visible={isRemoveCaretakerWarningVisible} okText="Yes" cancelText="No"
               onCancel={() => setIsRemoveCaretakerWarningVisible(false)} onOk={onRemoveCaretaker}>Are you sure to
            remove caretaker from clinic "{caretaker?.firstName + " " + caretaker?.lastName}"</Modal>
        <Modal visible={isDeleteCaretakerWarningVisible} okText="Yes" cancelText="No"
               onCancel={() => setIsDeleteCaretakerWarningVisible(false)} onOk={onDeleteCaretaker}>Are you sure to
            complitely delete caretaker "{caretaker?.firstName + " " + caretaker?.lastName}"</Modal>
    </div>


    return (
        <div style={{ height: '100%' }}>
                    <SelectPersons columns={columns} showCaretakers={false}
                        patients={caretakerPatients?.map((e) => {
                            return {
                                id: e.id,
                                firstName: e.firstName,
                                lastName: e.lastName,
                                phoneNumber: e.userAccount?.mobileNumber,
                                email: e.userAccount?.emailAddress
                            } as Person;
                        }) ?? []}

                        onPatientsReload={reloadCaretakerPatients}

                        onPatientsRemove={onPatientsRemove}

                        onPatientsAssign={onPatientsAssign}

                                   personTitle="Cretaker"
                                   person={editCaretaker}

                    />

        </div>
    );
}