import { CloseOutlined, PlusOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Space } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Person } from "../model/person";


export const AssignPersons = (props: {
    persons: Person[],
    onPersonSearch?: (value: string) => void,
    onPersonsAssign?: (selectedPersons: Person[]) => void,
    onPersonReload?: () => void,
    onCancel?: () => void,
}) => {

    const [personSearchTerm, setPersonSearchTerm] = useState<string>("");
    const [selectedPersons, setSelectedPersons] = useState<Person[]>([]);
    const navigation = useNavigate();

    const onPersonSearch = () => {
        if (props.onPersonSearch != undefined && personSearchTerm != "") {
            props.onPersonSearch(personSearchTerm);
        }
    }

    const onSelectionPersonsChange = (selectedRowKeys: React.Key[], persons: Person[]) => {
        setSelectedPersons(persons);
    }


    const onPersonsAdd = () => {
        if (selectedPersons.length > 0 && props.onPersonsAssign != undefined) {
            props.onPersonsAssign(selectedPersons);
        }
    }

    const onCancel = () => {
        if(props.onCancel){
            props.onCancel();
        }       
    }


    const columns: ColumnsType<Person> = [
        {
            // width: '25%',
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => a.firstName > b.firstName ? -1 : 1,
            //filteredValue:  null,
            //onFilter: (value: string | number| boolean, record) => record.firstName.includes(value as string),
            //ellipsis: true
        },
        {
            //width: '25%',
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.lastName > b.lastName ? -1 : 1
        },
        {
            width: '12.5%',
            title: 'Phone',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            sorter: (a, b) => a.phoneNumber > b.phoneNumber ? -1 : 1
        },
        {
            width: '12.5%',
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email > b.email ? -1 : 1
        },
    ];

    return (<div style={{ paddingTop: "50px"}}>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space>
                <Input defaultValue={personSearchTerm} onChange={(e) => { setPersonSearchTerm(e.target.value); }} onPressEnter={onPersonSearch}></Input>
                <Button type="primary" icon={<SearchOutlined />} onClick={onPersonSearch}>Search</Button>
            </Space>

            <Space >
                <Button type='primary' danger icon={<CloseOutlined />} onClick={onCancel} >Cancel</Button>
                <Button type='primary' icon={<PlusOutlined />} onClick={onPersonsAdd} disabled={selectedPersons.length == 0}>Assign</Button>
                <Button type='text' shape="circle" icon={<ReloadOutlined />} onClick={props.onPersonReload} />
            </Space>
        </Row>
        <Col>
            <Table
                rowKey={(person: Person) => person.id}
                rowSelection={{ type: 'checkbox', onChange: onSelectionPersonsChange }}
                style={{ marginTop: '20px' }}
                columns={columns}
                dataSource={props.persons}></Table>
        </Col>
    </div>)

}
