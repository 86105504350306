
import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { OrganizationPatientsApi, PatientRequestDto, PatientResponseDto } from "../../api/client-axios/api";
import { ApiError } from "../../model/apiError";
import { Problem } from "../../model/problem";
import { initOtpState } from "../otp/otpSlice";
import {apiConfiguration} from "../server";

export interface PatientState {
    patient?: PatientResponseDto,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "saved";
    error?: string | Problem;
    code?: number;
    isNew: boolean;
}


const initialPatientState: PatientState = {
    status: "idle",
    error: "",
    isNew: false
}

export const fetchPatient = createAsyncThunk<PatientResponseDto, string, { rejectValue: ApiError }>('patient/fetchPatient', async (patientId, thunkApi) => {
    try {
        const api = new OrganizationPatientsApi(apiConfiguration());
        if (patientId === "new") {
            thunkApi.dispatch(initOtpState());
            return {} as PatientResponseDto;
        }
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgPatientsIdGet(patientId)
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const addPatient = createAsyncThunk<PatientResponseDto, PatientRequestDto, { rejectValue: ApiError }>('patient/addPatient', async (patient, thunkApi) => {
    try {
        const api = new OrganizationPatientsApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgPatientsPost(patient);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const updatePatient = createAsyncThunk<PatientResponseDto, PatientRequestDto, { rejectValue: ApiError }>('patient/updatePatient', async (patient, thunkApi) => {
    try {
        const api = new OrganizationPatientsApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgPatientsPost(patient);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const detachPatient = createAsyncThunk<PatientResponseDto, string, { rejectValue: ApiError }>('patient/detachPatient', async (patientId, thunkApi) => {
    try {
        const api = new OrganizationPatientsApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgPatientsIdDetachPost(patientId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const deletePatient = createAsyncThunk<PatientResponseDto, string, { rejectValue: ApiError }>('patient/deletePatient', async (patientId, thunkApi) => {
    try {
        const api = new OrganizationPatientsApi(apiConfiguration());
        const response = await api.apiV1OrganizationsMyAdministrationMyOrgPatientsIdDelete(patientId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const updatePatientFields = createAction("patient/updatePatientFields",(patient: PatientRequestDto) => {return { payload: patient }});

export const clearSavePatientError = createAction('patient/clearError');

export const updatePatientPhoneNumber = createAction('patient/updatePhoneNumber', (phoneNumber: string) => { return { payload: phoneNumber }; });

export const updatePatientByCode = createAction('patient/updatePatientByCode', (patient: PatientResponseDto) => {return {payload: patient}});

export const initPatientState = createAction('patient/init');

const patientSlice = createSlice({
    name: 'patient',
    initialState: initialPatientState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchPatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchPatient.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "success";
            })
            .addCase(fetchPatient.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(addPatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(addPatient.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "saved";
            })
            .addCase(addPatient.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updatePatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(updatePatient.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "saved";
            })
            .addCase(updatePatient.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(clearSavePatientError, (state, action) => {
                state.status = "success";
                state.error = "";
            })
            .addCase(detachPatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(detachPatient.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "saved";
            })
            .addCase(detachPatient.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(deletePatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(deletePatient.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "saved";
            })
            .addCase(deletePatient.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updatePatientPhoneNumber, (state, action) => {
                state.patient!.userAccount = { mobileNumber: action.payload, emailAddress: state.patient?.userAccount?.emailAddress };
            })
            .addCase(initPatientState,(state,action)=>{
                state.status = "idle";
                state.patient = {};
                state.code = undefined;
                state.error = undefined;
                state.isNew = false;
            })
            .addCase(updatePatientFields,(state,action)=>{
                state.patient ={
                    ...state.patient,
                    ...action.payload as PatientResponseDto
                };
            })
            .addCase(updatePatientByCode,(state, action)=>{
                state.patient = action.payload;
                state.isNew = true;
            });

    }
});

export default patientSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: string | PatientRequestDto; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: PatientState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;
        state.error = action.payload.message;
        state.code = action.payload.statusCode;
        if (state.code == 401) {
            state.status = "auth-error";
        } else {
            state.status = "error";
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}

