
import { Layout, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import './SideMenu.css';

export const SideMenu = (props: {
    menuItems: ItemType[],
    onMenuClick: (event: {
        key: string;
        keyPath: string[];
        domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
    }) => void
}) => {

    return (
        <Layout.Sider className='sidebar' theme='light' breakpoint='lg' collapsedWidth={0} trigger={null}>
            <Menu style={{ marginTop: '10px' }} mode='inline' items={props.menuItems} onClick={props.onMenuClick}>

            </Menu>
        </Layout.Sider>);
}
